import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Ec_icon_full } from "../shared/components/Icons";
import React from "react";
import { UserMenu } from "./UserMenu";
import { message } from "antd";
import { DomainPicker } from "./DomainPicker";
function TheoLogo() {
    const [messageApi, contextHolder] = message.useMessage();
    return (_jsxs("div", Object.assign({ onClick: () => messageApi.info(_jsx("table", { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", Object.assign({ className: "text-left" }, { children: "Version:" })), _jsx("td", Object.assign({ className: "text-right pl-4" }, { children: "0.091" }))] }), _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "text-left" }, { children: "Code Name:" })), _jsx("td", Object.assign({ className: "text-right pl-4" }, { children: "Armageddon" }))] }), _jsxs("tr", { children: [_jsx("td", Object.assign({ className: "text-left" }, { children: "Date:" })), _jsx("td", Object.assign({ className: "text-right pl-4" }, { children: "Apr 03 2024" }))] })] }) })) }, { children: [_jsx("span", Object.assign({ className: "text-2xl" }, { children: _jsx(Ec_icon_full, {}) })), _jsx("span", Object.assign({ className: "text-2xl font-bold" }, { children: "Theo" })), contextHolder] })));
}
export function TheoHeader() {
    return (_jsxs("div", Object.assign({ className: "flex justify-between items-center mb-2 ml-4 mr-8" }, { children: [_jsx("div", Object.assign({ className: "cursor-pointer" }, { children: _jsx(TheoLogo, {}) })), _jsx(DomainPicker, {}), _jsx(UserMenu, {})] })));
}
