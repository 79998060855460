import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import "./App.less";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, message, Result } from "antd";
import { setErrorHandler } from "./utils/http";
import { addErrorHandler, pushError, useErrorType, } from "./utils/errorStream";
import { reloadToHomePage } from "./utils/util";
import "allotment/dist/style.css";
import { Theo } from "./theo/Theo";
// set window name so other apps who pass url parameters always reach the same
// instance.
window.name = "Theo";
function App() {
    useErrorType(true);
    const { user } = useAuth0();
    const fatal = useErrorHandler();
    const client = useLDClient();
    React.useEffect(() => {
        client === null || client === void 0 ? void 0 : client.identify({
            key: user === null || user === void 0 ? void 0 : user.email,
            email: user === null || user === void 0 ? void 0 : user.email,
            name: user === null || user === void 0 ? void 0 : user.name,
            custom: {
                org_id: user === null || user === void 0 ? void 0 : user.org_id,
            },
        });
    }, [user]);
    if (fatal) {
        return (_jsx(Result, { status: "error", title: "Sorry, there was an error loading the Application.", extra: _jsx(Button, Object.assign({ type: "primary", onClick: reloadToHomePage }, { children: "Reload" }), "console") }));
    }
    else {
        return _jsx(Theo, {});
    }
}
setErrorHandler(e => {
    console.error("NETWORK ERROR", e.message, e);
    pushError({ error: e });
});
function useErrorHandler() {
    const [isFatal, setIsFatal] = React.useState(false);
    const lastError = React.useRef();
    React.useEffect(() => {
        return addErrorHandler(error => {
            var _a, _b, _c;
            if (!error)
                return;
            console.error(error);
            if (!error.isFatal) {
                if (error.error.status === 504) {
                    if (((_a = lastError.current) === null || _a === void 0 ? void 0 : _a.error.status) !== error.error.status) {
                        message.warning("Server timed out. Try again or add filters.\nYou might be asking for too many results.", 4);
                    }
                }
                else if (error.error.message !== undefined &&
                    error.error.status !== 400) {
                    const time = ((_b = lastError.current) === null || _b === void 0 ? void 0 : _b.timestamp) || 0;
                    if (error.timestamp - time > 5000) {
                        message.warning(error.error.message, 4);
                    }
                }
                else {
                    const time = ((_c = lastError.current) === null || _c === void 0 ? void 0 : _c.timestamp) || 0;
                    if (error.timestamp - time > 5000) {
                        message.error("Error fetching data. Try reloading the page", 4);
                    }
                }
            }
            else {
                setIsFatal(true);
            }
            lastError.current = error;
        });
    }, []);
    return isFatal;
}
export default App;
