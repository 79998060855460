import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useLoading } from "../shared/components/Loading";
import { EvidenceDrawer } from "./EvidenceDrawer";
import { TheoHeader } from "./TheoHeader";
import { TheoPrompt } from "./TheoPrompt";
import { AnalysisPanel } from "./AnalysisPanel";
import { Allotment } from "allotment";
import "./Theo.less";
import { useErrorType } from "../utils/errorStream";
import { TheoToolbar } from "./TheoToolbar";
import { updateTheoSession, useTheoSession } from "./TheoSession";
import { GlobalConfigProvider } from "./GlobalConfig";
import { useGlobalPopup } from "../shared/components/GlobalPopUp";
import { OntologiesPanel } from "./OntologiesPanel";
import { TheoGraphContext } from "./TheoGraphContext";
import { useUrlSync } from "./urlSync";
import { TheoGraph } from "./TheoGraph";
export function Theo() {
    return (_jsx(GlobalConfigProvider, { children: _jsx(InitializedTheo, {}) }));
}
function InitializedTheo() {
    useUrlSync();
    useErrorType(false);
    const loading = useLoading();
    const { showLeftPanel, panelSizes } = useTheoSession();
    const PopupMenu = useGlobalPopup();
    return (_jsx(TheoGraphContext, { children: _jsxs("div", Object.assign({ className: "Theo absolute w-full h-full flex flex-col items-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex-1 flex flex-col items-stretch pb-8 pt-2 text-base" }, { children: [_jsx(TheoHeader, {}), _jsxs("div", Object.assign({ className: "flex-1 flex flex-row items-stretch" }, { children: [_jsx(TheoToolbar, {}), _jsxs(Allotment, Object.assign({ className: "mr-8" }, getUpdatePanelSize("MAIN"), { children: [showLeftPanel && (_jsx(Allotment.Pane, Object.assign({ minSize: 128, preferredSize: 320 }, { children: _jsx("div", Object.assign({ className: "flex items-stretch w-full h-full pr-2" }, { children: _jsxs(Allotment, Object.assign({ className: "flex-1", vertical: true }, getUpdatePanelSize("LEFT_PANEL_DIVISION"), { children: [_jsx(Allotment.Pane, Object.assign({ minSize: 38 }, { children: _jsx(TheoPrompt, {}) })), _jsx(Allotment.Pane, Object.assign({ minSize: 40 }, { children: _jsx(AnalysisPanel, {}) })), _jsx(Allotment.Pane, Object.assign({ minSize: 40 }, { children: _jsx(OntologiesPanel, {}) }))] })) })) }))), _jsx(Allotment.Pane, Object.assign({}, {
                                            minSize: undefined,
                                            preferredSize: undefined,
                                        }, { children: _jsx(TheoGraph, {}) }))] }))] }))] })), _jsx(EvidenceDrawer, {}), loading, _jsx(PopupMenu, {})] })) }));
    function getUpdatePanelSize(key) {
        return {
            defaultSizes: panelSizes[key],
            onChange: (size) => updateTheoSession({ panelSizes: Object.assign(Object.assign({}, panelSizes), { [key]: size }) }),
        };
    }
}
