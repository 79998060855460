var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Button } from "antd";
import { useTheme } from "./theme/ThemeContext";
export function RoundButton(_a) {
    var { style } = _a, props = __rest(_a, ["style"]);
    const theme = useTheme();
    return (_jsx(Button, Object.assign({}, props, { shape: "round", size: "large", style: Object.assign({ backgroundColor: theme.colors.background }, style) })));
}
