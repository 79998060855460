export var Stage;
(function (Stage) {
    Stage["source"] = "source";
    Stage["target"] = "target";
    Stage["intermediate"] = "intermediate";
})(Stage || (Stage = {}));
export var NodeType;
(function (NodeType) {
    NodeType["quantity"] = "quantity";
    NodeType["state"] = "state";
})(NodeType || (NodeType = {}));
export var EdgeType;
(function (EdgeType) {
    EdgeType["influence_dir"] = "influence_dir";
    EdgeType["influence_inv"] = "influence_inv";
    EdgeType["trigger"] = "trigger";
    EdgeType["up_trigger"] = "up_trigger";
    EdgeType["down_trigger"] = "down_trigger";
    EdgeType["trigger_up"] = "trigger_up";
    EdgeType["trigger_down"] = "trigger_down";
    EdgeType["partOf"] = "partOf";
    EdgeType["isA"] = "isA";
})(EdgeType || (EdgeType = {}));
export var EvidenceType;
(function (EvidenceType) {
    EvidenceType["supporting"] = "supporting";
    EvidenceType["refuting"] = "refuting";
})(EvidenceType || (EvidenceType = {}));
export var CombinationType;
(function (CombinationType) {
    CombinationType["merge"] = "merge";
    CombinationType["isA"] = "isA";
    CombinationType["partOf"] = "partOf";
})(CombinationType || (CombinationType = {}));
