import { jsx as _jsx } from "react/jsx-runtime";
import { MiniMap } from "reactflow";
import React from "react";
import { getNodeColor } from "./gUtils";
import { useTheoState } from "./TheoState";
import { useTheme } from "./theme/ThemeContext";
import Chroma from "chroma-js";
export function ViewMap() {
    const theme = useTheme();
    const { changes } = useTheoState();
    const nodeColor = React.useCallback((n) => getNodeColor(n, {
        override: changes[n.id],
        darkTheme: theme.active === "dark",
    }), [changes, theme]);
    const dark = theme.active === "dark";
    return (_jsx(MiniMap, { nodeColor: nodeColor, pannable: true, zoomable: true, maskColor: Chroma(theme.colors.text_primary)
            .alpha(dark ? 0.2 : 0.2)
            .css(), style: {
            backgroundColor: dark ? theme.colors.background : "white",
        } }));
}
