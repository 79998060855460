import React from "react";
import { useQueryLoader } from "./queryLoader";
import { debouncedMessageCoordinator } from "./messageCoordinator";
import { useMemoIfEqual, usePreviousValue } from "../hooks";
import { debouncedRequestExecutor, plainRequestExecutor, } from "../requestExecutors";
export function useRemoteQuery({ loader, params, mapper = (v) => v, refreshLoader, defaultValue, debounced = true, dependencies, }) {
    const [remote, setRemote] = React.useState(() => ({
        loading: false,
        value: null,
    }));
    const loadQuery = useQueryLoader(loader, debounced ? debouncedRequestExecutor : plainRequestExecutor, debouncedMessageCoordinator, dependencies);
    const previousParams = usePreviousValue(params);
    React.useEffect(() => {
        loadQuery(({ remote: { loading, value, error }, params }) => {
            setRemote({
                loading,
                value: value === null
                    ? null
                    : params === undefined
                        ? defaultValue !== null && defaultValue !== void 0 ? defaultValue : null
                        : mapper(value, params),
                error,
            });
        }, params, previousParams);
    }, [refreshLoader, useMemoIfEqual(params)]);
    return remote;
}
