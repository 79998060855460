import React from "react";
import { getURLParams, setURLParams, useLocationChange } from "../utils/routing";
import { EMPTY_STATE, TheoState, useTheoState } from "./TheoState";
import { useIsMounting } from "../utils/hooks";
import { API } from "./api";
import { setLoading } from "../shared/components/Loading";
import { updateTheoSession } from "./TheoSession";
export function useUrlSync() {
    const { checkpoint } = useTheoState();
    const mounting = useIsMounting();
    React.useEffect(() => {
        !mounting && setURLParams(checkpoint ? { checkpoint } : {});
    }, [checkpoint]);
    useLocationChange(() => {
        const { checkpoint } = getURLParams();
        if (checkpoint) {
            setLoading("Loading Scenario..");
            API.restore_session({ checkpoint })
                .then(response => {
                updateTheoSession({ domain: response.domain });
                TheoState(Object.assign(Object.assign({}, response), { changes: Object.fromEntries(response.graph.nodes
                        .filter(n => n.forced)
                        .map(n => [n.id, n.value])) }));
            })
                .finally(() => setLoading(false));
        }
        else {
            TheoState(EMPTY_STATE);
        }
    }, true);
}
