import { Emitter } from "./Emitter";
import React from "react";
import { merge } from "./collections";
import { equals, getValue, identity } from "./util";
const DEBUG = false;
export function Beacon(config = {}) {
    const { isEqual = (a, b) => a === b, name, validate = a => a, initial } = config;
    function debug() {
        DEBUG && name && console.log(`BEACON[${name}] = `, value);
    }
    const emitter = Emitter();
    let value = typeof initial === "function" ? initial() : initial;
    let hasValue = value !== undefined;
    hasValue && debug();
    return {
        name,
        hasValue() {
            return hasValue;
        },
        value(vOrS) {
            if (arguments.length) {
                const v = vOrS === undefined ? undefined : validate(getValue(value, vOrS));
                if (!isEqual(v, value)) {
                    hasValue = true;
                    value = v;
                    debug();
                    emitter.emit(value);
                }
            }
            return value;
        },
        on(listener, extractor = identity) {
            const sub = emitter.on(listener, extractor);
            if (value !== undefined) {
                listener(extractor(value));
            }
            return sub;
        },
    };
}
export function ParamsBeacon(beacon) {
    return Object.assign(Object.assign({}, beacon), { value(update) {
            return update
                ? beacon.value(value => merge(value, update))
                : beacon.value();
        } });
}
export function useBeacon(beacon, picker = identity) {
    const [value, setValue] = React.useState(picker(beacon.value()));
    // DEBUG && beacon.name && console.log(`BEACON_STATE[${beacon.name}]`, value)
    React.useEffect(() => {
        if (beacon.hasValue())
            setValue(picker(beacon.value()));
        return beacon.on(beaconValue => setValue(current => {
            const next = picker(beaconValue);
            return equals(next, current) ? current : next;
        }));
    }, [beacon]);
    return [value, beacon.value];
}
