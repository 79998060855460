var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { confirmation } from "./dialogs";
import { textPrompt } from "../shared/components/Propmpt";
import { isEqual } from "lodash";
import I from "immutable";
export function delay(provider, millis) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(provider());
            }, millis);
        });
    });
}
export function hashCode(str) {
    let hash = 0, i, chr;
    if (str.length === 0)
        return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
export function getElementById(elementId) {
    return document.getElementById(elementId);
}
export function focusElementById(id) {
    const diagram = document.getElementById(id);
    diagram === null || diagram === void 0 ? void 0 : diagram.focus();
}
export function scrollToElement(elem, delay = 0) {
    setTimeout(() => {
        var _a;
        (_a = elem === null || elem === void 0 ? void 0 : elem()) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            behavior: "smooth",
            inline: "center",
            block: "center",
        });
    }, delay);
}
export function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
export function isNully(v) {
    return v === undefined || v === null;
}
export function getValue(original, vOrFn) {
    return typeof vOrFn === "function" ? vOrFn(original) : vOrFn;
}
export function timeout(promise, millis, timeoutMessage = "timeout") {
    return __awaiter(this, void 0, void 0, function* () {
        let timeoutID;
        const result = yield Promise.race([
            promise,
            new Promise((resolve, reject) => {
                timeoutID = setTimeout(() => reject(timeoutMessage), millis);
            }),
        ]);
        timeoutID && clearTimeout(timeoutID);
        return result;
    });
}
export function identity(v) {
    return v;
}
const EMPTY_ARR = [];
const EMPTY_OBJ = {};
export function emptyArr() {
    return EMPTY_ARR;
}
export function emptyObj() {
    return EMPTY_OBJ;
}
export function limitLength(text, len = Number.MAX_SAFE_INTEGER) {
    if (!text)
        return "";
    return text.length <= len ? text : text.slice(0, len) + "…";
}
export function cleanupHtmlTags(html) {
    return html.replaceAll("<em>", "").replaceAll("</em>", "");
}
export function resolveValue(i) {
    return typeof i === "function" ? i() : i;
}
export function keysFor(item) {
    return Object.keys(item);
}
export function promptName({ title, placeholder, exists, replace, add, value, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const name = yield textPrompt({ title, placeholder, value });
        if (!name || !name.length)
            return;
        if ((exists === null || exists === void 0 ? void 0 : exists(name)) && replace) {
            if (yield confirmation({ title: `Override ${name}?` })) {
                replace(name);
            }
        }
        else {
            add(name);
        }
    });
}
export function selection(defaultValue, ...options) {
    return options.find(o => o) || defaultValue;
}
export function toBoolean(value) {
    return typeof value === "boolean"
        ? value
        : String(value).toLowerCase() === String(true).toLowerCase();
}
export function getSize(obj) {
    return Array.isArray(obj) || typeof obj === "string"
        ? obj.length
        : !obj
            ? 0
            : Object.keys(obj).length;
}
export function reloadToHomePage() {
    const url = new URL(window.location.href);
    url.searchParams.forEach((v, k) => url.searchParams.delete(k));
    history.pushState(null, "", url.href);
    location.reload();
}
export function actuator(actuators) {
    return (v) => {
        for (const actuator of actuators) {
            if (actuator(v))
                return true;
        }
        return false;
    };
}
export function valuesAre(a, b, val1, val2) {
    if (val2 === undefined)
        val2 = val1;
    return ((equals(a, val1) && equals(b, val2)) || (equals(a, val2) && equals(b, val1)));
}
export function equals(a, b) {
    if (isEqual(a, b))
        return true;
    if (I.isSet(a) && I.isSet(b))
        return a.equals(b);
    if (I.isList(a) && I.isList(b))
        return a.equals(b);
    if (I.isMap(a) && I.isMap(b))
        return a.equals(b);
    return false;
}
export function mapSync(arr, fn) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = [];
        for (const item of arr) {
            result.push(yield fn(item));
        }
        return result;
    });
}
export function mapValuesSync(obj, fn) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = {};
        for (const k in obj) {
            result[k] = yield fn(obj[k], k);
        }
        return result;
    });
}
export function calc(fn) {
    return fn();
}
