import { groupBy } from "lodash";
import { dist, X, XY, Y } from "../../utils/drawing/geometry";
import { Pen } from "../../utils/drawing/Pen";
import { PathBuilder } from "../../utils/drawing/PathBuilder";
import { normalize, reverse } from "svg-path-reverse";
import { getClosestEdgeParams } from "../graphGeo";
import { PathWalker } from "../../utils/drawing/PathWalker";
import { getEdgePath } from "../theoGraphLayout";
export function dupEdgesInfoCalculator(nodes, nodesById, edges) {
    const duplicateIndex = Object.fromEntries(Object.values(groupBy(edges, e => getKey(e, true)))
        .filter(arr => arr.length > 1)
        .map(arr => arr.map((edge, idx) => [
        edge.id,
        {
            idx,
            count: arr.length,
            reversed: getKey(edge, false) !== getKey(edge, true),
        },
    ]))
        .flat());
    return edges.reduce((acc, edge) => {
        const sourceNode = nodesById[edge.source];
        const targetNode = nodesById[edge.target];
        const params = getClosestEdgeParams(sourceNode, targetNode);
        const dupInfo = duplicateIndex[edge.id];
        const edgePath = dupInfo
            ? getDuplicateEdgePath(Object.assign(Object.assign(Object.assign({}, params), dupInfo), { amplitude: 40 }))
            : getEdgePath(params, nodes);
        const [labelX, labelY] = PathWalker(edgePath).at(0.5);
        acc[edge.id] = Object.assign(Object.assign({}, params), { edgePath,
            labelX,
            labelY });
        return acc;
    }, {});
    function getKey(edge, sorted) {
        const arr = [edge.source, edge.target];
        return sorted ? arr.sort().join(":") : arr.join(":");
    }
    function getDuplicateEdgePath({ sourceX, sourceY, targetX, targetY, idx, count, amplitude, reversed, }) {
        const [a, b, inv] = getParams();
        const len = dist(...a, ...b) / 4;
        const amp = (amplitude * (count - 1)) / 2 - amplitude * idx;
        const sign = (reversed ? -1 : 1) * inv;
        const [c1, c2, l, c3, c4] = Pen(a)
            .pointTo(...b)
            .adv(len)
            .down()
            .rot(sign * 90)
            .adv(amp)
            .rot(sign * -90)
            .adv(len)
            .adv(len)
            .rot(sign * -90)
            .adv(amp)
            .points();
        const path = PathBuilder()
            .M(...a)
            .C(...c1, ...c2, ...l)
            .C(...c3, ...c4, ...b)
            .path();
        return (reversed ? -1 : 1) * sign > 0 ? path : reverse(normalize(path));
        function getParams() {
            const s = XY(sourceX, sourceY);
            const t = XY(targetX, targetY);
            return X(s) <= X(t) || Y(s) <= Y(t)
                ? [s, t, 1]
                : [t, s, -1];
        }
    }
}
