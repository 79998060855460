var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { EMPTY_FE_DATA } from "./TheoState";
import { GraphAnalysisCreator } from "../utils/graph/GraphAnalysis";
export function toFEGraphResponse(_a) {
    var { frontEndData: json, graph } = _a, rest = __rest(_a, ["frontEndData", "graph"]);
    return Object.assign(Object.assign({}, rest), { graph: validateGraph(graph), frontEndData: json ? JSON.parse(json) : EMPTY_FE_DATA });
}
const TGraphAnalysis = GraphAnalysisCreator({
    nodeId: n => n.id,
    sourceId: e => e.sourceId,
    targetId: e => e.targetId,
});
function validateGraph(graph) {
    // const {nodes, edges} = graph
    const ga = TGraphAnalysis(graph.nodes, graph.edges);
    if (ga.invalidEdges.length) {
        console.error("Invalid graph", ga.invalidEdges.map(e => {
            var _a, _b;
            const s = !((_a = ga.node(e.sourceId)) === null || _a === void 0 ? void 0 : _a.id) ? e.sourceId : undefined;
            const t = !((_b = ga.node(e.targetId)) === null || _b === void 0 ? void 0 : _b.id) ? e.targetId : undefined;
            return Object.assign(Object.assign({ "invalid edge id": e.id, edge: e }, (s && { "invalid sourceId": s })), (t && { "invalid targetId": t }));
        }));
        return { nodes: ga.nodes, edges: ga.edges };
    }
    return graph;
}
