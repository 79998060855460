var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../utils/classes";
import { Close, Home, Plus } from "./Icons";
import { EllipsisOutlined } from "@ant-design/icons/lib";
import { DivButton } from "./buttons/Buttons";
import { DropDownMenu } from "./DropDownMenu";
const BUTTON_CLASSES = "flex items-center py-1 px-2 space-x-2 ";
const BASE_BG = "bg-gray-300";
export function Tabs({ tabs, className, selectedId, onSelect, onRemove, onNew, suffix, }) {
    return (_jsxs("div", Object.assign({ className: classes(className, "flex min-h-[24px] divide-x text-sm font-bold weight-medium text-gray-500") }, { children: [_jsx(DivButton, Object.assign({ className: classes(BUTTON_CLASSES, "rounded-tl-lg", BASE_BG) }, { children: _jsx(Home, { className: "text-base" }) })), tabs.map(t => (_jsxs("div", Object.assign({ className: classes(selectedId === t.id ? "bg-gray-400" : BASE_BG, "flex items-center py-1 px-2 space-x-2") }, { children: [!t.cantRemove && (_jsx(DivButton, Object.assign({ className: classes("flex items-center mr-1"), onMouseDown: () => onRemove === null || onRemove === void 0 ? void 0 : onRemove(t) }, { children: _jsx(Close, {}) }))), _jsx(DivButton, Object.assign({ className: classes("flex items-center text-black"), onMouseDown: () => onSelect === null || onSelect === void 0 ? void 0 : onSelect(t.id) }, { children: t.name })), t.actions && (_jsx(DropDownMenu, Object.assign({ entries: t.actions }, { children: _jsx(DivButton, Object.assign({ className: classes("flex items-center") }, { children: _jsx(EllipsisOutlined, { className: "text-base" }) })) })))] }), t.id))), _jsx("div", Object.assign({ className: classes("flex-1 flex items-center", BASE_BG) }, { children: _jsx(DivButton, Object.assign({ className: classes(BUTTON_CLASSES), onMouseDown: () => onNew === null || onNew === void 0 ? void 0 : onNew() }, { children: _jsx(Plus, {}) })) })), suffix, _jsx(DivButton, Object.assign({ className: classes(BUTTON_CLASSES, BASE_BG, "rounded-tr-lg") }, { children: _jsx(EllipsisOutlined, { className: "text-base" }) }))] })));
}
export function TabButton(_a) {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (_jsx(DivButton, Object.assign({ className: classes(BUTTON_CLASSES, className) }, props, { children: children })));
}
