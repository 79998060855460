import { EdgeType, NodeType } from "./types";
import { updateItems } from "../utils/collections";
import { XY } from "../utils/drawing/geometry";
import { Decrease, Increase, OffSwitch, OnSwitch, } from "../shared/components/Icons";
import { MinusOutlined } from "@ant-design/icons";
import { getAncestor } from "../utils/dom";
export const NumFormatter = {
    format(num) {
        if (typeof num !== "number")
            num = Number(num);
        const rounded = Number(num.toFixed(2));
        if (rounded === 0 && num !== 0)
            num = Math.sign(num) * 0.01;
        return new Intl.NumberFormat("en", {
            maximumFractionDigits: 2,
        }).format(num);
    },
};
export const NODE_SIZE = XY(256, 40);
export const theoType = "theo";
export function createNode(data, position = {
    x: 0,
    y: 0,
}) {
    return {
        id: data.id,
        focusable: false,
        type: theoType,
        position,
        data,
    };
}
export function createEdge(data) {
    return {
        id: data.id,
        focusable: false,
        updatable: false,
        source: data.sourceId,
        target: data.targetId,
        type: theoType,
        data,
    };
}
export function isQuantity(node) {
    return nodeTypeIs(node, NodeType.quantity);
}
export function isState(node) {
    return nodeTypeIs(node, NodeType.state);
}
export function nodeTypeIs(node, type) {
    return getElementData(node).type === type;
}
export function getElementData(node) {
    return "data" in node ? node.data : node;
}
export function isInfluenceEdge(edge) {
    const { type } = getElementData(edge);
    return type in [EdgeType.influence_dir, EdgeType.influence_inv];
}
export function getNodeValue(node, override) {
    const { value } = getElementData(node);
    return override !== undefined ? override : value;
}
const NODE_COLORS = {
    light: {
        increasing: "#005AB5",
        decreasing: "#DC3220",
        neutral: "#5d5d5d",
        enabled: "#5d5d5d",
        disabled: "#a9a9a9",
    },
    dark: {
        increasing: "#1984ec",
        decreasing: "#f84230",
        neutral: "#c7c7c7",
        enabled: "#c7c7c7",
        disabled: "#494949",
    },
};
export function getGraphColors(darkTheme) {
    return NODE_COLORS[darkTheme ? "dark" : "light"];
}
export function getNodeColor(node, { override, mul = 1, darkTheme = false, }) {
    const colors = getGraphColors(darkTheme);
    const current = getNodeValue(node, override) * mul;
    return isQuantity(node)
        ? current < 0
            ? colors.decreasing
            : current > 0
                ? colors.increasing
                : colors.neutral
        : current > 0
            ? colors.enabled
            : colors.disabled;
}
export function updateNodes(flow, ...updaters) {
    flow.setNodes(updateItems(flow.getNodes(), ...updaters));
}
export function createNodePositionUpdater(nodeId, position) {
    return (node) => node.id === nodeId && { position, positionAbsolute: position };
}
export function getNodeIcon(node, override) {
    const current = getNodeValue(node, override);
    return isQuantity(node)
        ? current < 0
            ? Decrease
            : current > 0
                ? Increase
                : MinusOutlined
        : current
            ? OnSwitch
            : OffSwitch;
}
export function getEdgeTypeValue(type) {
    return [EdgeType.up_trigger, EdgeType.trigger_up].includes(type)
        ? 1
        : [EdgeType.down_trigger, EdgeType.trigger_down].includes(type)
            ? -1
            : 0;
}
export function updateNodesPayloadPosition(nodes, initial) {
    return nodes.reduce((acc, node) => {
        acc[node.id] = Object.assign(Object.assign({}, acc[node.id]), { position: node.position });
        return acc;
    }, initial);
}
export function getNodeIdAt(event) {
    const nodeElement = getAncestor(event.target, "react-flow__node");
    return (nodeElement === null || nodeElement === void 0 ? void 0 : nodeElement.getAttribute("data-id")) || undefined;
}
export function getNodeAt(flow, e) {
    const id = getNodeIdAt(e);
    return id ? flow.getNode(id) : undefined;
}
export function getPossibleEdgeTypes(source, target) {
    const sType = getElementData(source).type;
    const tType = getElementData(target).type;
    if (sType === NodeType.quantity) {
        if (tType === NodeType.quantity) {
            return [EdgeType.influence_dir, EdgeType.influence_inv];
        }
        else {
            return [EdgeType.up_trigger, EdgeType.down_trigger];
        }
    }
    else {
        if (tType === NodeType.quantity) {
            return [EdgeType.trigger_up, EdgeType.trigger_down];
        }
        else {
            return [EdgeType.trigger];
        }
    }
}
