import React from "react";
import { getCollisions } from "./collisions";
import { classes } from "../utils/classes";
import { Mode, useDiagramMode } from "./DiagramMode";
import { createNodePositionUpdater, updateNodes } from "./gUtils";
import { getBounds } from "./graphGeo";
export function useNodeMergeDragging(flow, onMerge, canMerge) {
    const collisionsRef = React.useRef();
    const sourceRef = React.useRef();
    const targetRef = React.useRef();
    const cancelDragRef = React.useRef();
    const mode = useDiagramMode();
    return {
        onNodeDragStart: React.useCallback((_, source, nodes) => {
            sourceRef.current = nodes.length === 1 ? source : undefined;
            if (!sourceRef.current)
                return;
            cancelDragRef.current = (e) => {
                if (e.key === "Escape" && sourceRef.current) {
                    mode(Mode.normal);
                    updateNodes(flow, clearClasses, createNodePositionUpdater(sourceRef.current.id, sourceRef.current.positionAbsolute));
                    sourceRef.current = undefined;
                    document.removeEventListener("keydown", cancelDragRef.current);
                }
            };
            mode(Mode.dragging);
            document.addEventListener("keydown", cancelDragRef.current);
            collisionsRef.current = getCollisions(flow.getNodes());
            updateNodes(flow, n => ({
                className: classes.set(n.className, n.id !== source.id && !canMerge(source, n), "inactive"),
            }));
        }, [mode]),
        onNodeDrag: React.useCallback((_, source) => {
            if (!sourceRef.current)
                return;
            const target = targetRef.current;
            const mergeId = collisionsRef
                .current.getCollisions(getBounds(source))
                .find(id => canMerge(source, flow.getNode(id)));
            if (mergeId && source.id !== mergeId) {
                if ((target === null || target === void 0 ? void 0 : target.id) !== mergeId) {
                    const merge = flow.getNode(mergeId);
                    setTarget(merge);
                    updateNodes(flow, n => ({
                        className: classes.set(n.className, mergeId === n.id || n.id === source.id, "merging"),
                    }));
                }
            }
            else if (target) {
                setTarget(undefined);
                updateNodes(flow, n => ({
                    className: classes.remove(n.className, "merging"),
                }));
            }
        }, [mode]),
        onNodeDragStop: React.useCallback((e, node) => {
            mode(Mode.normal);
            if (!sourceRef.current)
                return;
            const target = targetRef.current;
            updateNodes(flow, clearClasses);
            if (target) {
                setTarget(undefined);
                onMerge(e, node, target, sourceRef.current.positionAbsolute);
            }
            collisionsRef.current = undefined;
            sourceRef.current = undefined;
            document.removeEventListener("keydown", cancelDragRef.current);
        }, [mode]),
    };
    function setTarget(node) {
        targetRef.current = node;
        mode(node ? Mode.merging : Mode.dragging);
    }
    function clearClasses(n) {
        return {
            className: classes.remove(n.className, "merging", "inactive"),
        };
    }
}
