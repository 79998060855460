var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useFormDialog } from "./refactored/FormDialog";
import { updateTheoSession, useTheoSession } from "./TheoSession";
import { pick } from "lodash";
import { useActualGenerationMode, useActualModel, useGlobalConfig, } from "./GlobalConfig";
import { useTheme } from "./theme/ThemeContext";
import { ThemeNames } from "./theme/themeUtils";
import { Slider } from "antd";
const configKeys = [
    "model",
    "theme",
    "user_theories",
    "creativity",
    "generation_mode",
    "showNodeScores",
];
export function useTheoConfig() {
    const { active: theme, setTheme } = useTheme();
    const { models, generation_modes } = useGlobalConfig();
    const session = useTheoSession();
    const model = useActualModel(session.model);
    const generation_mode = useActualGenerationMode(session.generation_mode);
    const config = Object.assign(Object.assign({}, pick(session, configKeys)), { model, generation_mode, theme });
    return useFormDialog({
        title: "Configuration",
        initialValues: config,
        fields: [
            {
                key: "generation_mode",
                label: "Generation Mode",
                widget: "select",
                options: generation_modes,
            },
            {
                key: "model",
                label: "Model",
                widget: "select",
                options: models,
            },
            {
                key: "user_theories",
                label: "Use Saved Models",
                widget: "switch",
            },
            {
                key: "showNodeScores",
                label: "Show Node Scores",
                widget: "switch",
            },
            { key: "creativity", label: "Creativity", widget: Creativity },
            {
                key: "theme",
                label: "Theme",
                widget: "radio-group",
                options: ThemeNames,
            },
        ],
        guruModeFields: new Set(["model"]),
        onNewValues(_a) {
            var { theme } = _a, config = __rest(_a, ["theme"]);
            updateTheoSession(config);
            setTheme(theme);
        },
    });
}
function Creativity({ value, onChange, }) {
    return (_jsx(Slider, { value: value, onChange: onChange, marks: {
            0: (_jsx("div", Object.assign({ className: "m-4 text-sm", style: { marginLeft: "62px" } }, { children: "Conservative" }))),
            1: (_jsx("div", Object.assign({ className: "m-4 text-sm", style: { marginRight: "42px" } }, { children: "Creative" }))),
        }, min: 0, max: 1, step: 0.1 }));
}
