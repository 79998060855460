import assert from "assert";
export function Segment(off, len) {
    return [off, len];
}
Segment.start = (s) => s[0];
Segment.len = (s) => s[1];
Segment.end = (s) => Segment.start(s) + Segment.len(s);
Segment.align = (alignment, segments) => {
    assert(0 <= alignment && alignment <= 1, "alignment must be between 0 and 1");
    const max = Math.max(...segments.map(Segment.len));
    return segments.map(s => Segment((max - Segment.len(s)) * alignment, Segment.len(s)));
};
Segment.sort = (segments) => segments.sort((a, b) => Segment.start(a) - Segment.start(b));
