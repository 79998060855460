var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DropDownMenu } from "../shared/components/DropDownMenu";
import React from "react";
import { Down } from "../shared/components/Icons";
import "./SelectionPicker.less";
import { TabButton } from "../shared/components/Tabs";
import { Tooltip } from "../shared/components/Tooltip";
import { limitLength } from "../utils/util";
import { getCreationActions, useCollectionActions, } from "../shared/actions/collectionManagementActions";
import { updateDirectory, useGlobalConfig } from "./GlobalConfig";
import { EMPTY_STATE, TheoState, useTheoState } from "./TheoState";
import { updateTheoSession } from "./TheoSession";
import { API } from "./api";
import { setLoading } from "../shared/components/Loading";
import { useCurrentDomain } from "./DomainPicker";
import { partition } from "lodash";
import { useRemoteQuery } from "../utils/query/remoteQuery";
import { textComparator } from "../utils/comparators";
import { Menu } from "../shared/actions/Menu";
import { LoadingOutlined } from "@ant-design/icons";
import { PublicToggle } from "./PublicToggle";
export function SessionPicker() {
    var _a;
    const state = useTheoState();
    const domain = useCurrentDomain();
    const isUnnamed = !state.session_name;
    const session = state.session;
    const selected = React.useCallback((item) => session === item.session, [session]);
    const { directory } = useGlobalConfig();
    const [publicItems, userItems] = React.useMemo(() => partition(directory.filter(s => s.name && s.domain === domain), s => s.public), [directory, domain]);
    const params = {
        name: item => item.name,
        canRemove: ownedByUser,
        emptyMessage: "No sessions",
        selected,
        onSelect({ session, name }) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    setLoading(`Loading ${name}...`);
                    const response = yield API.restore_session({ session });
                    updateTheoSession({ domain: response.domain });
                    TheoState(Object.assign(Object.assign({}, response), { changes: Object.fromEntries(response.graph.nodes
                            .filter(n => n.forced)
                            .map(n => [n.id, n.value])) }));
                }
                finally {
                    setLoading(false);
                }
            });
        },
        onRemove({ session: id }) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    setLoading(true);
                    if (session === id) {
                        newSession();
                    }
                    yield deleteSession(id);
                    yield updateDirectory();
                }
                finally {
                    setLoading(false);
                }
            });
        },
    };
    const creationActions = getCreationActions({
        canSaveCurrent: !!state.session,
        newCaption: "New Session",
        saveCaption: `Save ${isUnnamed ? "" : "Copy "}As`,
        onCopy(name) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    setLoading(`Saving ${name}...`);
                    yield copySession(name, false);
                    yield updateDirectory();
                }
                finally {
                    setLoading(false);
                }
            });
        },
        onReplace(name) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    setLoading(`Saving ${name}...`);
                    const entry = directory.find(s => s.name === name);
                    yield copySession(name, false);
                    yield deleteSession(entry.session);
                    yield updateDirectory();
                }
                finally {
                    setLoading(false);
                }
            });
        },
        onNew() {
            return __awaiter(this, void 0, void 0, function* () {
                newSession();
            });
        },
    });
    const publicEntries = useCollectionActions(Object.assign(Object.assign({}, params), { items: publicItems }));
    const userEntries = useCollectionActions(Object.assign(Object.assign({}, params), { items: userItems }));
    const presetsRemote = useRemoteQuery({
        loader: API.examples,
        params: { domain },
    });
    const presets = (((_a = presetsRemote.value) === null || _a === void 0 ? void 0 : _a.examples) || [])
        .map(s => (Object.assign(Object.assign({}, s), { isReadonly: true })))
        .sort((a, b) => textComparator(a.name, b.name));
    const presetsEntries = useCollectionActions({
        items: presets,
        name: item => item.name,
        emptyMessage: "No Scenarios",
        onSelect({ scenario, target_concept }) {
            return __awaiter(this, void 0, void 0, function* () {
                TheoState(Object.assign(Object.assign({}, EMPTY_STATE), { scenario, target: target_concept }));
            });
        },
    });
    const maxHeight = "50vh";
    const entries = [
        {
            caption: "My Sessions",
            subEntries: userEntries,
            maxHeight: maxHeight,
            placement: "rightTop",
        },
        ...(publicItems.length
            ? [
                {
                    caption: "Public Sessions",
                    subEntries: publicEntries,
                    maxHeight: maxHeight,
                    placement: "rightTop",
                },
            ]
            : []),
        {
            caption: "Presets",
            subEntries: presetsEntries,
            maxHeight: maxHeight,
            placement: "rightTop",
        },
        Menu.SEPARATOR,
        ...creationActions,
    ];
    return (_jsxs("div", Object.assign({ className: "flex space-between items-center space-x-1 self-stretch" }, { children: [_jsx(DropDownMenu, Object.assign({ title: "Session", hasClose: true, entries: entries }, { children: _jsx(Tooltip, Object.assign({ content: "Session Management", placement: "bottom-start" }, { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-1" }, { children: [_jsx(TabButton, Object.assign({ className: "px-0 self-start" }, { children: _jsx("div", { children: isUnnamed
                                        ? "Unnamed Session"
                                        : limitLength(state.session_name, 24) }) })), presetsRemote.loading ? (_jsx(LoadingOutlined, { className: "text-xs" })) : (_jsx(Down, { className: "text-xs" }))] })) })) })), _jsx("div", { className: "flex-1" }), _jsx(PublicToggle, { state: state, directory: directory })] })));
    function newSession() {
        updateTheoSession({ showLeftPanel: true });
        TheoState(EMPTY_STATE);
    }
    function copySession(session_name, isPublic) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield saveCurrentSession(session_name, isPublic, state, true);
            TheoState(Object.assign(Object.assign({}, response), { session_name }));
        });
    }
    function deleteSession(session) {
        return __awaiter(this, void 0, void 0, function* () {
            yield API.delete_session({ session });
        });
    }
}
export function saveCurrentSession(name, isPublic, state, save_copy) {
    return __awaiter(this, void 0, void 0, function* () {
        const { frontEndData, checkpoint } = state;
        const response = yield API.save({
            checkpoint,
            name,
            public: isPublic,
            frontEndData: JSON.stringify(frontEndData),
            save_copy,
        });
        return response;
    });
}
function ownedByUser(item) {
    return item.owned_by_user;
}
