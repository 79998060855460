var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { setLoading } from "../shared/components/Loading";
import { pushError } from "./errorStream";
export function useInterruptableFetch() {
    return function (fetch, message) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const controller = new AbortController();
                setLoading({
                    message,
                    onCancel() {
                        controller.abort();
                        setLoading(false);
                    },
                });
                return yield fetch(controller.signal);
            }
            catch (e) {
                if (e.code !== DOMException.ABORT_ERR) {
                    pushError({ error: e });
                }
                return undefined;
            }
            finally {
                setLoading(false);
            }
        });
    };
}
