var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useInterruptableFetch, } from "../utils/interruptableFetch";
import { API } from "./api";
import { TheoState, useTheoState } from "./TheoState";
import React from "react";
import { FastForwardOutlined, StepForwardOutlined } from "@ant-design/icons";
import { useTheoSession } from "./TheoSession";
import { useActualGenerationMode, useActualModel } from "./GlobalConfig";
import { useCurrentDomain } from "./DomainPicker";
import { CtrlButton } from "./CtrlButton";
export function GenerateGraphButton({ next_step_only, }) {
    const { scenario, target, stage, checkpoint } = useTheoState();
    const fetch = useInterruptableFetch();
    const noScenario = !scenario.trim();
    const noTarget = !target.trim();
    const session = useTheoSession();
    const model = useActualModel(session.model);
    const generation_mode = useActualGenerationMode(session.generation_mode);
    const domain = useCurrentDomain();
    const done = stage === "done";
    const disabled = done || noScenario || noTarget;
    return (_jsx(CtrlButton, Object.assign({ disabled: disabled, warning: noScenario || noTarget, tooltip: noScenario && noTarget
            ? "scenario and target are missing"
            : noScenario
                ? "scenario missing"
                : noTarget
                    ? "target missing"
                    : done
                        ? "analysis completed"
                        : `analyze scenario and question${next_step_only ? " (step)" : ""}`, onClick: () => __awaiter(this, void 0, void 0, function* () {
            return generateGraph(fetch, scenario, target, session.user_theories, session.creativity, domain, model, checkpoint, stage, next_step_only, generation_mode);
        }) }, { children: next_step_only ? _jsx(StepForwardOutlined, {}) : _jsx(FastForwardOutlined, {}) })));
}
function generateGraph(fetch, scenario, target, user_theories, creativity, domain, model, checkpoint, stage, oneStep, generation_mode) {
    return __awaiter(this, void 0, void 0, function* () {
        let message = stage || "Analyzing scenario and building causal map…";
        let response;
        do {
            response = yield fetch((signal) => __awaiter(this, void 0, void 0, function* () {
                const response = yield API.generate_graph(getParams(), {
                    signal,
                    noCache: true,
                });
                return signal.aborted ? undefined : response;
            }), _jsx("div", { children: message }));
            if (!response)
                return;
            checkpoint = response.checkpoint;
            message = response.stage;
            TheoState(s => {
                return Object.assign(Object.assign({}, response), { scenario,
                    target, changes: Object.fromEntries(response.graph.nodes.filter(n => n.forced).map(n => [n.id, n.value])) });
            });
        } while (response.stage !== "done" && !oneStep);
        function getParams() {
            return Object.assign(Object.assign(Object.assign(Object.assign({ scenario, targetConcept: target, domain }, (model && { model })), (checkpoint && { checkpoint })), { next_step_only: true, mergeNodes: true, get_analysis: false, user_theories,
                creativity }), (!checkpoint && { generation_mode }));
        }
    });
}
