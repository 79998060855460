import { createLocalStorageParams } from "../utils/localStorageParams";
const EMPTY_SESSION = {
    model: null,
    showLeftPanel: true,
    panelSizes: {},
    domain: "",
    user_theories: false,
    creativity: 0,
    showNodeScores: false,
    generation_mode: null,
};
export const [useTheoSession, updateTheoSession] = createLocalStorageParams("THEO_SESSION", EMPTY_SESSION);
