import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EdgeType } from "./types";
import { getElementData, getNodeValue, isState, NumFormatter } from "./gUtils";
import { limitLength } from "../utils/util";
import { Decrease, Increase, Lightning } from "../shared/components/Icons";
import React from "react";
const EDGE_TYPE_NLG = {
    [EdgeType.influence_dir]: "has a direct influence on",
    [EdgeType.influence_inv]: "has an inverse influence on",
    [EdgeType.isA]: "is a",
    [EdgeType.partOf]: "is a part of",
    [EdgeType.down_trigger]: "decrease triggers",
    [EdgeType.up_trigger]: "increase triggers",
    [EdgeType.trigger_up]: "triggers increase of",
    [EdgeType.trigger_down]: "triggers decrease of",
    [EdgeType.trigger]: "triggers",
};
const EDGE_TYPE_NLG_QUESTION = {
    [EdgeType.influence_dir]: "have a direct influence on",
    [EdgeType.influence_inv]: "have an inverse influence on",
    [EdgeType.isA]: "is a",
    [EdgeType.partOf]: "is a part of",
    [EdgeType.down_trigger]: "decrease trigger",
    [EdgeType.up_trigger]: "increase trigger",
    [EdgeType.trigger_up]: "trigger increase of",
    [EdgeType.trigger_down]: "trigger decrease of",
    [EdgeType.trigger]: "trigger",
};
export const EDGE_CAPTIONS = {
    [EdgeType.influence_dir]: "direct influence",
    [EdgeType.influence_inv]: "inverse influence",
    [EdgeType.isA]: "is a",
    [EdgeType.partOf]: "is part",
    [EdgeType.down_trigger]: "decrease triggers",
    [EdgeType.up_trigger]: "increase triggers",
    [EdgeType.trigger_up]: "triggers increase",
    [EdgeType.trigger_down]: "triggers decrease",
    [EdgeType.trigger]: "triggers",
};
const EDGE_TYPE_OPPOSITE = {
    [EdgeType.influence_dir]: EdgeType.influence_inv,
    [EdgeType.influence_inv]: EdgeType.influence_dir,
    [EdgeType.down_trigger]: EdgeType.up_trigger,
    [EdgeType.up_trigger]: EdgeType.down_trigger,
    [EdgeType.trigger_up]: EdgeType.trigger_down,
    [EdgeType.trigger_down]: EdgeType.trigger_up,
};
export const LABELS_BY_TYPE = {
    [EdgeType.influence_dir]: "dir",
    [EdgeType.influence_inv]: "inv",
    [EdgeType.isA]: "is a",
    [EdgeType.partOf]: "part of",
    [EdgeType.down_trigger]: (_jsxs("span", { children: [_jsx(Decrease, {}), _jsx(Lightning, {})] })),
    [EdgeType.up_trigger]: (_jsxs("span", { children: [_jsx(Increase, {}), _jsx(Lightning, {})] })),
    [EdgeType.trigger_up]: (_jsxs("span", { children: [_jsx(Lightning, {}), _jsx(Increase, {})] })),
    [EdgeType.trigger_down]: (_jsxs("span", { children: [_jsx(Lightning, {}), _jsx(Decrease, {})] })),
    [EdgeType.trigger]: (_jsx("span", { children: _jsx(Lightning, {}) })),
};
export const CAPTION_BY_TYPE = {
    [EdgeType.influence_dir]: "dir",
    [EdgeType.influence_inv]: "inv",
    [EdgeType.isA]: "is a",
    [EdgeType.partOf]: "part of",
    [EdgeType.down_trigger]: "↓↯",
    [EdgeType.up_trigger]: "↑↯",
    [EdgeType.trigger_up]: "↯↑",
    [EdgeType.trigger_down]: "↯↓",
    [EdgeType.trigger]: "↯",
};
export function getEdgeDescription(edge, nodeGetter, overrides, asQuestion = false) {
    var _a, _b, _c;
    const { type, sourceId, targetId } = edge;
    const source = getElementData(nodeGetter(sourceId));
    const sourceValue = getNodeValue(source, overrides[sourceId]);
    const target = getElementData(nodeGetter(targetId));
    const targetValue = getNodeValue(target, overrides[targetId]);
    const state = isState(source) || isState(target);
    const typeNlg = asQuestion ? EDGE_TYPE_NLG_QUESTION : EDGE_TYPE_NLG;
    return (_jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsxs("span", { children: [asQuestion ? "Does " : "", nodeNLG(source, state ? 0 : sourceValue), " ", _jsx("span", Object.assign({ className: "font-semibold" }, { children: typeNlg[type] })), " ", nodeNLG(target, state ? 0 : targetValue), asQuestion ? "?" : ""] }), !asQuestion && (_jsxs("div", Object.assign({ className: "flex space-x-1" }, { children: [_jsx("div", { children: "Weight:" }), _jsx("div", Object.assign({ className: "font-bold" }, { children: NumFormatter.format((_b = (_a = overrides[edge.id]) !== null && _a !== void 0 ? _a : edge.weight) !== null && _b !== void 0 ? _b : 0) })), _jsx("div", Object.assign({ className: "pl-2" }, { children: "Evidence count:" })), _jsx("div", Object.assign({ className: "font-bold" }, { children: ((_c = edge.evidenceIds) === null || _c === void 0 ? void 0 : _c.length) || 0 }))] })))] })));
}
export const nodeNLG = (node, value) => {
    const label = limitLength(node.label, 50);
    return value
        ? `${value < 0 ? "decrease of " : value > 0 ? "increase of " : ""}${label}`
        : label;
};
export function getToggleEdgeDirectionCaption(type) {
    // const opposite = EDGE_TYPE_OPPOSITE[type]
    // return (
    //   opposite && (
    //     <span>
    //       Toggle to {EDGE_CAPTIONS[opposite]} ({LABELS_BY_TYPE[opposite]})
    //     </span>
    //   )
    // )
    return null;
}
