import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ReactFlow, { ConnectionLineType, Controls, useReactFlow, } from "reactflow";
import "reactflow/dist/style.css";
import { EDGE_TYPES } from "./TheoEdge";
import "./TheoGraph.less";
import { NODE_TYPES } from "./TheoNode";
import { ViewMap } from "./ViewMap";
import { useTheoState } from "./TheoState";
import { UpdateSignsButton } from "./UpdateSignsButton";
import { Empty } from "../shared/components/Empty";
import { useCombineNodes } from "./combineNodes";
import { ignoreMouseHandling, useHidePopups, useMouseDownHandlers, } from "./MouseDownHandler";
import { useGraphToDiagram } from "./graphToDiagram";
import { useDiagramState, useSyncReactFlowAndState } from "./DiagramState";
import { useConnection } from "./connection";
import { useContextMenus } from "./contextMenus";
import { useTheme } from "./theme/ThemeContext";
import { createPathShapeExtractor, FocusAreas } from "../utils/graph/FocusAreas";
import { EdgeInfo } from "./edgeInfo/EdgeInfo";
import chroma from "chroma-js";
import { RFGraphAnalysis } from "../utils/graph/RFGraphAnalysis";
import { LayoutGraphButton } from "./layoutGraphButton";
import { FastForwardOutlined } from "@ant-design/icons";
export function TheoGraph() {
    const flow = useReactFlow();
    const theme = useTheme();
    const state = useTheoState();
    const { graph, session } = state;
    useGraphToDiagram(flow, state);
    const onMouseDownCapture = useMouseDownHandlers(false, ignoreMouseHandling, useHidePopups());
    const [dState, dUpdater] = useDiagramState();
    const rfs = useSyncReactFlowAndState(dState, dUpdater);
    const { nodes, edges } = dState;
    const focusParams = React.useMemo(() => {
        const ga = RFGraphAnalysis(nodes, edges);
        const focusEdges = state.evidenceFor
            ? [ga.edges.find(e => e.id === state.evidenceFor)]
            : ga.edges.filter(e => e.selected);
        const focusNodes = Object.values(focusEdges.reduce((acc, e) => {
            acc[e.source] = ga.node(e.source);
            acc[e.target] = ga.node(e.target);
            return acc;
        }, {})).concat(nodes.filter(n => n.selected));
        return {
            focusEdges,
            focusNodes,
        };
    }, [nodes, edges, state.evidenceFor]);
    return (_jsx("div", Object.assign({ className: "TheoGraph" }, { children: _jsxs(ReactFlow, Object.assign({}, rfs, useConnection(), useCombineNodes(flow), useContextMenus(nodes, edges), { deleteKeyCode: null, selectionKeyCode: null, multiSelectionKeyCode: "Shift", onMouseDownCapture: onMouseDownCapture, nodeTypes: NODE_TYPES, edgeTypes: EDGE_TYPES, connectionLineType: ConnectionLineType.Straight, connectionLineStyle: {
                stroke: theme.colors.text_primary,
                strokeWidth: 3,
                strokeDasharray: "5,5",
            }, minZoom: 0.3 }, { children: [_jsx(ViewMap, {}), _jsxs(Controls, Object.assign({ position: "bottom-left", showInteractive: false, className: "border border-shadow" }, { children: [_jsx(LayoutGraphButton, { horizontal: true, state: dState, dUpdater: dUpdater }), _jsx(LayoutGraphButton, { state: dState, dUpdater: dUpdater })] })), _jsx(Controls, Object.assign({ className: "flex space-x-4", position: "bottom-center", showInteractive: false, showZoom: false, showFitView: false }, { children: _jsx(UpdateSignsButton, {}) })), _jsx(FocusAreas, Object.assign({}, focusParams, { color: chroma(theme.colors.text_primary).alpha(0.1).css(), getEdgeShape: createPathShapeExtractor(10, (flow, edge) => EdgeInfo(edge.id, dState.nodes, dState.nodesById, edges).edgePath) })), !session || !(graph === null || graph === void 0 ? void 0 : graph.nodes.length) ? (_jsx(Empty, { showIcon: false, empty: _jsx("span", Object.assign({ className: "text-xl" }, { children: session ? ("No results") : (_jsxs("span", { children: ["Enter a scenario and click", " ", _jsx(FastForwardOutlined, { className: "bg-text_primary text-background rounded-md p-1" }), " ", "to analyze"] })) })) })) : null] })) })));
}
