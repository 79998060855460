import { equals } from "./util";
export const IGNORE = Symbol("IGNORE");
export function Emitter() {
    let listeners = [];
    return {
        emit(v) {
            listeners.forEach(([listener, extractor]) => extractor(v) !== IGNORE && listener(v));
        },
        on(listener, extractor = v => v) {
            listeners = [...listeners, [listener, extractor]];
            return () => {
                listeners = listeners.filter(([l]) => l !== listener);
            };
        },
    };
}
export function createExtractor(fn) {
    let last = IGNORE;
    return (v) => {
        const r = fn(v);
        if (!equals(r, last)) {
            last = r;
            return r;
        }
        return IGNORE;
    };
}
