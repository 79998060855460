var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { getPossibleEdgeTypes } from "./gUtils";
import { Select } from "antd";
import { EDGE_CAPTIONS, LABELS_BY_TYPE } from "./nlg";
import { FormDialog } from "./refactored/FormDialog";
import React from "react";
import { showDialog } from "../utils/dialog";
function EdgeTypePicker({ onValue, source, target, }) {
    const edgeTypes = getPossibleEdgeTypes(source, target);
    function Picker({ onChange, value, }) {
        return (_jsx(Select, { value: value, onSelect: onChange, options: edgeTypes.map(value => ({
                value,
                label: (_jsxs("div", { children: [EDGE_CAPTIONS[value], " (", LABELS_BY_TYPE[value], ")"] })),
            })) }));
    }
    return (_jsx(FormDialog, { title: "Edge", fields: [{ key: "type", label: "Type", widget: Picker }], initialValues: { type: edgeTypes[0] }, onValue: value => onValue((value === null || value === void 0 ? void 0 : value.type) || null) }));
}
export function pickEdgeType(source, target) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield showDialog(onValue => (_jsx(EdgeTypePicker, { onValue: onValue, source: source, target: target })));
    });
}
