import { rounder } from "../rounder";
export function PathBuilder() {
    const parts = [];
    return {
        M(x, y) {
            parts.push("M", x, y);
            return this;
        },
        m(dx, dy) {
            parts.push("m", dx, dy);
            return this;
        },
        L(x, y) {
            parts.push("L", x, y);
            return this;
        },
        l(dx, dy) {
            parts.push("l", dx, dy);
            return this;
        },
        H(x) {
            parts.push("H", x);
            return this;
        },
        h(dx) {
            parts.push("h", dx);
            return this;
        },
        V(y) {
            parts.push("V", y);
            return this;
        },
        v(dy) {
            parts.push("v", dy);
            return this;
        },
        C(cx1, cy1, cx2, cy2, x, y) {
            parts.push("C", cx1, cy1, cx2, cy2, x, y);
            return this;
        },
        c(dcx1, dcy1, dcx2, dcy2, dx, dy) {
            parts.push("C", dcx1, dcy1, dcx2, dcy2, dx, dy);
            return this;
        },
        S(x2, y2, x, y) {
            parts.push("S", x2, y2, x, y);
            return this;
        },
        s(dx2, dy2, dx, dy) {
            parts.push("s", dx2, dy2, dx, dy);
            return this;
        },
        Q(cx, cy, x, y) {
            parts.push("Q", cx, cy, x, y);
            return this;
        },
        q(dcx1, dcy1, dx, dy) {
            parts.push("q", dcx1, dcy1, dx, dy);
            return this;
        },
        T(x, y) {
            parts.push("T", x, y);
            return this;
        },
        t(dx, dy) {
            parts.push("t", dx, dy);
            return this;
        },
        A(rx, ry, xAxisRot, largeArc, sweep, x, y) {
            parts.push("A", rx, ry, xAxisRot, largeArc, sweep, x, y);
            return this;
        },
        a(rx, ry, xAxisRot, largeArc, sweep, dx, dy) {
            parts.push("a", rx, ry, xAxisRot, largeArc, sweep, dx, dy);
            return this;
        },
        Z() {
            parts.push("Z");
            return this;
        },
        z() {
            parts.push("z");
            return this;
        },
        CS(bezierPoints) {
            for (let i = 1; i < bezierPoints.length; i += 3)
                this.C(...bezierPoints[i], ...bezierPoints[i + 1], ...bezierPoints[i + 2]);
            return this;
        },
        MCS(bezierPoints) {
            return this.M(...bezierPoints[0]).CS(bezierPoints);
        },
        LCS(bezierPoints) {
            return this.L(...bezierPoints[0]).CS(bezierPoints);
        },
        addLines(points) {
            points.forEach(p => this.L(...p));
            return this;
        },
        pipe(...actions) {
            actions.forEach(a => a(this));
            return this;
        },
        path(precision = 5) {
            const round = rounder(precision);
            return parts
                .map(part => (typeof part === "number" ? round(part) : part))
                .join(" ");
        },
        length() {
            return parts.length;
        },
    };
}
