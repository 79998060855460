import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { InputNumber, Slider } from "antd";
import { NumFormatter } from "./gUtils";
export function NumberInput({ caption, value: initial, onChange, min, max, step = 0.01, disabled, }) {
    const [value, setValue] = React.useState(initial);
    function updateValue(v) {
        setValue(v);
        onChange(v);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-stretch px-2" }, { children: [caption, _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(Slider, { className: "flex-1", min: min, max: max, step: step, onChange: updateValue, value: value, disabled: disabled }), _jsx(InputNumber, { defaultValue: value, min: min, max: max, step: step, size: "small", style: { width: 64 }, value: value, onChange: v => updateValue(v || 0), formatter: v => NumFormatter.format(v !== null && v !== void 0 ? v : 0), disabled: disabled })] }))] })));
}
