var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../utils/classes";
import { Scroller } from "../components/Scroller";
import { useCounter } from "../../utils/hooks";
import { MenuEntry } from "./MenuEntry";
import { getActionIcon } from "./ActionParams";
Menu.SEPARATOR = "SEPARATOR";
export function Menu({ entries: en, onSelect, captionClassName = "max-w-[500px]", maxHeight, getHeader, getFooter, className, entryClassName, dataTestId, emptyMessage, }) {
    const entries = Array.isArray(en) ? en : en();
    const hasIcons = entries.filter(e => e && e !== "SEPARATOR" && getActionIcon(e)).length > 0;
    const [_, refreshMenu] = useCounter();
    const [top, center, bottom] = splitEntries();
    const content = (_jsx("div", Object.assign({ className: "flex flex-1 flex-col ", "data-test-id": dataTestId }, { children: center.map((entry, idx) => {
            const rowProps = {
                captionClassName,
                hasIcons,
                onSelect,
                refreshMenu,
                entryClassName,
            };
            return typeof entry === "object" && (entry === null || entry === void 0 ? void 0 : entry.renderer) ? (entry === null || entry === void 0 ? void 0 : entry.renderer(rowProps, idx)) : (_jsx(MenuEntry, { entry: entry, rowProps: rowProps }, idx));
        }) })));
    return !entries.length && !emptyMessage ? null : (_jsxs("div", Object.assign({ className: classes("Menu relative bg-white flex flex-col p-2", className !== undefined ? className : "shadow-xl rounded-xl"), style: Object.assign({ zIndex: 1000 }, (maxHeight && { maxHeight })) }, { children: [getHeader === null || getHeader === void 0 ? void 0 : getHeader(refreshMenu), !!top.length && _jsx(Menu, { className: "", entries: top, onSelect: onSelect }), emptyMessage && !entries.length ? (emptyMessage) : maxHeight ? (_jsx(Scroller, Object.assign({ noAbsolute: true }, { children: content }))) : (content), !!bottom.length && (_jsx(Menu, { className: "", entries: bottom, onSelect: onSelect })), getFooter === null || getFooter === void 0 ? void 0 : getFooter(refreshMenu)] })));
    function splitEntries() {
        let lastHeader = entries.findIndex(e => e && e !== Menu.SEPARATOR && e.lastHeader);
        if (lastHeader > -1 && entries[lastHeader + 1] === Menu.SEPARATOR) {
            lastHeader++;
        }
        let firstFooter = entries.findIndex(e => e && e !== Menu.SEPARATOR && e.firstFooter);
        if (firstFooter === -1) {
            firstFooter = entries.length;
        }
        else if (entries[firstFooter - 1] === Menu.SEPARATOR) {
            firstFooter--;
        }
        return [
            entries.slice(0, lastHeader + 1).map(removeFlags),
            entries.slice(lastHeader + 1, firstFooter),
            entries.slice(firstFooter).map(removeFlags),
        ];
        function removeFlags(e) {
            if (!e || e === Menu.SEPARATOR)
                return e;
            const { firstFooter, lastHeader } = e, rest = __rest(e, ["firstFooter", "lastHeader"]);
            return rest;
        }
    }
}
