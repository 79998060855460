var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import Tippy from "@tippyjs/react";
import "./Tooltip.less";
import "tippy.js/dist/tippy.css";
export function Tooltip(_a) {
    var { children, wrapperClassname, wrapperStyle } = _a, props = __rest(_a, ["children", "wrapperClassname", "wrapperStyle"]);
    return props.content ? (_jsx(Tippy, Object.assign({ delay: [750, 500] }, props, { children: _jsx("div", Object.assign({ className: wrapperClassname, style: wrapperStyle }, { children: children })) }))) : (_jsx(_Fragment, { children: children }));
}
