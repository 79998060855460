import { interpolation } from "./interpolation";
export function range(count) {
    return {
        forEach(callback) {
            for (let idx = 0; idx < count; idx++)
                callback(idx, idx);
        },
        map(callback) {
            const acc = [];
            for (let idx = 0; idx < count; idx++)
                acc.push(callback(idx, idx));
            return acc;
        },
        reduce(callback, acc) {
            for (let idx = 0; idx < count; idx++)
                acc = callback(acc, idx, idx);
            return acc;
        },
    };
}
export function lineal(count, from = 0, to = 1) {
    const inc = interpolation(0, from, Math.floor(count), to);
    return {
        forEach(callback) {
            range(count + 1).forEach(idx => callback(inc(idx), idx));
        },
        map(callback) {
            return range(count + 1).map(idx => callback(inc(idx), idx));
        },
        reduce(callback, acc) {
            return range(count + 1).reduce((acc, val, idx) => callback(acc, inc(val), idx), acc);
        },
    };
}
