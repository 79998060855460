import { Beacon, ParamsBeacon, useBeacon } from "../utils/Beacon";
import { keyBy } from "lodash";
const EMPTY_GRAPH_RESPONSE = {
    answer: null,
    checkpoint: "",
    domain: "",
    evidences: {},
    graph: null,
    ontologies: [],
    scenario: "",
    session: "",
    session_name: "",
    stage: "",
    target: "",
};
export const EMPTY_FE_DATA = {
    nodesPayload: {},
};
export const EMPTY_STATE = Object.assign(Object.assign({}, EMPTY_GRAPH_RESPONSE), { changes: {}, frontEndData: EMPTY_FE_DATA, nodesById: {}, edgesById: {}, evidenceFor: null, animating: false });
const stateBeacon = ParamsBeacon(Beacon({ initial: EMPTY_STATE }));
export function useTheoState(picker) {
    return useBeacon(stateBeacon, picker)[0];
}
export function TheoState(update) {
    return update
        ? stateBeacon.value(v => {
            const updateValue = typeof update === "function" ? update(v) : update;
            if (updateValue.graph) {
                updateValue.nodesById = keyBy(updateValue.graph.nodes, "id");
                updateValue.edgesById = keyBy(updateValue.graph.edges, "id");
            }
            if (updateValue.scenario || updateValue.target) {
                if (!("checkpoint" in updateValue)) {
                    updateValue.checkpoint = "";
                }
                if (!("stage" in updateValue)) {
                    updateValue.stage = "";
                }
            }
            return updateValue;
        })
        : stateBeacon.value();
}
