import { Bounds, Diagonal } from "./Bounds";
import { Segment } from "./Segment";
export function XY(v = 0, y = 0) {
    return hasXY(v) ? [v.x, v.y] : [v, y];
}
export const ORIGIN = { x: 0, y: 0 };
export function Point(x, y) {
    return { x, y };
}
const TO_DEG = 180 / Math.PI;
export const TO_RAD = Math.PI / 180;
export function X(p) {
    return p[0];
}
export function Y(p) {
    return p[1];
}
export function dist(x1, y1, x2 = 0, y2 = 0) {
    return Math.sqrt(distSq(x1, y1, x2, y2));
}
export function distSq(x1, y1, x2, y2) {
    return Math.pow((x2 - x1), 2) + Math.pow((y2 - y1), 2);
}
export function atanDeg(y, x) {
    return Math.atan2(y, x) * TO_DEG;
}
export function hasXY(v) {
    return v && typeof v === "object" && "x" in v && "y" in v;
}
export function Rect(x1, y1, x2, y2) {
    const { x, y } = Point(Math.min(x1, x2), Math.min(y1, y2));
    const { x: xMax, y: yMax } = Point(Math.max(x1, x2), Math.max(y1, y2));
    return { x, y, xMax, yMax, width: xMax - x, height: yMax - y };
}
export function calcBounds(items, diagonal) {
    const d = items.reduce((acc, v) => {
        const [x1, y1, x2, y2] = diagonal(v);
        if (acc) {
            acc = Diagonal(Math.min(Diagonal.x1(acc), x1), Math.min(Diagonal.y1(acc), y1), Math.max(Diagonal.x2(acc), x2), Math.max(Diagonal.y2(acc), y2));
        }
        else {
            acc = Diagonal(x1, y1, x2, y2);
        }
        return acc;
    }, undefined);
    return d ? Bounds.fromDiagonal(...d) : Bounds(0, 0, 0, 0);
}
export function zeroTo360(degs) {
    return (degs % 360) + (degs < 0 ? 360 : 0);
}
export function sign(v) {
    return v < 0 ? -1 : 1;
}
export function xSegnent(b) {
    return Segment(Bounds.x(b), Bounds.w(b));
}
export function ySegnent(b) {
    return Segment(Bounds.y(b), Bounds.h(b));
}
export function segmentsToBounds(xSeg, ySeg) {
    return Bounds(Segment.start(xSeg), Segment.start(ySeg), Segment.len(xSeg), Segment.len(ySeg));
}
// compute the angle between ab and bc
export function calculateAngle(a, b, c) {
    // Calculate vectors AB and BC
    const ab = XY(X(b) - X(a), Y(b) - Y(a));
    const bc = XY(X(c) - X(b), Y(c) - Y(b));
    // Calculate dot product of AB and BC
    const dotProduct = X(ab) * X(bc) + Y(ab) * Y(bc);
    // Calculate magnitudes of AB and BC
    const magnitudeAB = Math.sqrt(Math.pow(X(ab), 2) + Math.pow(Y(ab), 2));
    const magnitudeBC = Math.sqrt(Math.pow(X(bc), 2) + Math.pow(Y(bc), 2));
    // Calculate the cosine of the angle using the dot product and magnitudes
    const cosineTheta = dotProduct / (magnitudeAB * magnitudeBC);
    // Calculate the angle in radians using the arccosine function
    const angleInRadians = Math.acos(cosineTheta);
    // Convert the angle to degrees
    const angleInDegrees = (angleInRadians * 180) / Math.PI;
    return angleInDegrees;
}
