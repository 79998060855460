import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { EvidenceType } from "./types";
import { Drawer, Tabs } from "antd";
import { groupBy } from "lodash";
import { getSize } from "../utils/util";
import { TheoState, useTheoState } from "./TheoState";
import { EvidenceCard } from "./EvidenceCard";
import { useTheme } from "./theme/ThemeContext";
import { intercalate } from "../utils/collections";
import { getEdgeDescription } from "./nlg";
import { useGlobalEscape } from "../utils/hooks";
export function EvidenceDrawer() {
    var _a;
    const state = useTheoState();
    const { nodesById, graph, changes, evidenceFor } = state;
    const edge = React.useMemo(() => !evidenceFor ? undefined : graph === null || graph === void 0 ? void 0 : graph.edges.find(e => e.id === evidenceFor), [graph, evidenceFor]);
    const evidences = ((_a = edge === null || edge === void 0 ? void 0 : edge.evidenceIds) === null || _a === void 0 ? void 0 : _a.map(id => state.evidences[id])) || [];
    const theme = useTheme();
    const [active, setActive] = React.useState(EvidenceType.supporting);
    const byType = React.useMemo(() => groupBy(evidences, "type"), [evidences]);
    const forType = byType[active] || [];
    useGlobalEscape(() => TheoState({ evidenceFor: null }), !!evidenceFor);
    const evidenceList = (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ style: {
                    position: "sticky",
                    top: 0,
                    backgroundColor: theme.active === "dark" ? "#1f1f1f" : theme.colors.background,
                } }, { children: [edge && getEdgeDescription(edge, id => nodesById[id], changes, true), _jsx(Tabs, { activeKey: active, onChange: setActive, items: [
                            {
                                key: EvidenceType.supporting,
                                label: `Evidence (${getSize(byType[EvidenceType.supporting]) || "None"})`,
                            },
                            // {
                            //   key: EvidenceType.refuting,
                            //   label: `Refuting (${
                            //     getSize(byType[EvidenceType.refuting]) || "None"
                            //   })`,
                            // },
                        ] })] })), _jsx(EvidenceList, { evidences: forType })] }));
    return (_jsx(Drawer, Object.assign({ title: "Evidence", open: !!edge, onClose: () => TheoState({ evidenceFor: null }), styles: { body: { paddingTop: 0 } } }, { children: evidenceList })));
}
function EvidenceList({ evidences = [] }) {
    return (_jsx("div", Object.assign({ className: "flex flex-col space-y-2" }, { children: intercalate(evidences.map(e => _jsx(EvidenceCard, { evidence: e }, e.id)), idx => (_jsx("hr", {}, idx + "divider"))) })));
}
