import { defaultTheme, // Default theme
 } from "@ant-design/compatible";
import { theme } from "antd";
// TODO: define a theme type
export function buildAntTheme({ active, colors }) {
    return active === "dark"
        ? { algorithm: theme.darkAlgorithm }
        : Object.assign(Object.assign({}, defaultTheme), { token: Object.assign(Object.assign({}, defaultTheme.token), { colorPrimary: colors.primary, colorPrimaryHover: colors.primary, colorTextDisabled: colors.disabled_primary, colorInfo: colors.primary, fontFamily: "Inter, sans-serif", borderRadius: 4, colorTextBase: colors.text_primary }), components: {
                Input: {
                    colorBgContainer: "transparent",
                },
                Button: {
                    colorBorder: colors.disabled_primary,
                },
                Layout: {
                    colorBgHeader: colors.primary,
                },
                Drawer: {
                    colorBgElevated: colors.background,
                },
                Modal: {
                    contentBg: colors.background,
                    headerBg: colors.background,
                    footerBg: colors.background,
                    colorText: colors.text_primary,
                },
                Form: {
                    labelColor: colors.text_primary,
                },
            } });
}
