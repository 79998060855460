import PointsAtLength from "point-at-length";
import { atanDeg, distSq, sign, zeroTo360 } from "./geometry";
import { interpolation } from "../interpolation";
export function PathWalker(path) {
    const points = new PointsAtLength(path);
    const length = points.length();
    const walker = {
        length,
        path,
        at(len) {
            return walker.atLen(len * length);
        },
        atLen(len) {
            return points.at(Math.min(length, Math.max(0, len < 0 ? length + len : len)));
        },
        tan(len, dir, dx = 0.001) {
            const p1 = walker.at(Math.max(0, len - dx * 0.5));
            const p2 = walker.at(Math.min(1, len + dx * 0.5));
            return atanDeg(sign(dir) * (p2[1] - p1[1]), sign(dir) * (p2[0] - p1[0]));
        },
        normal(len, dir, dx = 0.001) {
            const tan = walker.tan(len, dir, dx);
            return zeroTo360(tan + 90);
        },
        iterate(visitor, steps, from = 0, to = 1) {
            if (steps <= 0)
                return;
            const dir = sign(to - from);
            const stepLen = length / steps;
            const inc = interpolation(0, from, steps, to);
            for (let step = 0; step <= steps; step++) {
                const len = inc(step);
                visitor({
                    len,
                    point: walker.at(len),
                    dir,
                    stepLen,
                    walker: walker,
                    step,
                });
            }
        },
        closest(point) {
            let precision = 8;
            let xy = [Infinity, Infinity];
            let len = Infinity;
            let dist = Infinity;
            // linear scan for coarse approximation
            for (let scan, scanLen = 0, scanDist; scanLen <= length; scanLen += precision) {
                if ((scanDist = distSq(...(scan = walker.atLen(scanLen)), ...point)) <
                    dist) {
                    xy = scan;
                    len = scanLen;
                    dist = scanDist;
                }
            }
            // binary search for precise estimate
            precision /= 2;
            while (precision > 0.5) {
                let before, after, beforeLen, afterLen, beforeDist, afterDist;
                if ((beforeLen = len - precision) >= 0 &&
                    (beforeDist = distSq(...(before = walker.atLen(beforeLen)), ...point)) < dist) {
                    xy = before;
                    len = beforeLen;
                    dist = beforeDist;
                }
                else if ((afterLen = len + precision) <= length &&
                    (afterDist = distSq(...(after = walker.atLen(afterLen)), ...point)) <
                        dist) {
                    xy = after;
                    len = afterLen;
                    dist = afterDist;
                }
                else {
                    precision /= 2;
                }
            }
            return { xy, dist: Math.sqrt(dist), len };
        },
    };
    return walker;
}
