import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
const DiagramModeContext = React.createContext(undefined);
export function ModeProvider({ children, defaultMode, }) {
    const mode = useCreateModes(defaultMode);
    return (_jsx(DiagramModeContext.Provider, Object.assign({ value: mode }, { children: children })));
}
export function useCreateModes(defaultMode) {
    const [stack, setStack] = React.useState([defaultMode]);
    return React.useMemo(() => {
        function instance(mode) {
            const value = stack.at(-1) || defaultMode;
            mode && setStack([mode]);
            return value;
        }
        instance.is = (mode, ...modes) => [mode, ...modes].includes(instance());
        instance.push = (mode) => setStack([...stack, mode]);
        instance.pop = () => setStack(stack.slice(0, -1));
        return instance;
    }, [stack]);
}
export function useModes() {
    return React.useContext(DiagramModeContext);
}
