var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { showPopupMenu } from "../shared/actions/PopupMenu";
import { Menu } from "../shared/actions/Menu";
import { NodeType, Stage } from "./types";
import { API } from "./api";
import { TheoState, useTheoState } from "./TheoState";
import { setLoading } from "../shared/components/Loading";
import { textPrompt } from "../shared/components/Propmpt";
import { getToggleEdgeDirectionCaption } from "./nlg";
import { isQuantity } from "./gUtils";
import { NumberInput } from "./NumberInput";
import { capitalize } from "../utils/nlg";
import { uniq } from "lodash";
import { useTheoSession } from "./TheoSession";
export function useContextMenus(nodes, edges) {
    const { showNodeScores } = useTheoSession();
    const { checkpoint, session, changes, graph, stage } = useTheoState();
    const canModifyGraph = stage === "done";
    const selection = React.useMemo(() => ({
        nodes: nodes
            .filter(i => i.selected && i.data.stage === Stage.intermediate)
            .map(i => i.id),
        edges: edges.filter(i => i.selected).map(i => i.id),
    }), [nodes]);
    const { frontEndData: { nodesPayload }, } = useTheoState();
    return {
        onPaneContextMenu: React.useCallback((e) => {
            e.preventDefault();
            e.stopPropagation();
            const selectedIds = [...selection.nodes, ...selection.edges];
            session &&
                showPopupMenu({
                    top: e.clientY,
                    left: e.clientX,
                    entries: [
                        {
                            caption: "Add Quantity Node",
                            action() {
                                return __awaiter(this, void 0, void 0, function* () {
                                    yield addNode(NodeType.quantity);
                                });
                            },
                            disabled: !canModifyGraph,
                        },
                        {
                            caption: "Add State Node",
                            action() {
                                return __awaiter(this, void 0, void 0, function* () {
                                    yield addNode(NodeType.state);
                                });
                            },
                            disabled: !canModifyGraph,
                        },
                        {
                            caption: "Remove Selected Nodes and Edges",
                            disabled: !canModifyGraph || selectedIds.length < 1,
                            action() {
                                return __awaiter(this, void 0, void 0, function* () {
                                    yield remove(selection.edges);
                                });
                            },
                        },
                    ],
                });
            function addNode(type) {
                return __awaiter(this, void 0, void 0, function* () {
                    const labels = new Set(graph === null || graph === void 0 ? void 0 : graph.nodes.map(n => n.label.toLowerCase()));
                    const label = yield textPrompt({
                        title: type === NodeType.quantity ? "Quantity Node" : "State Node",
                        placeholder: "Enter a label for the node",
                        isValid(label) {
                            return labels.has(label.toLowerCase())
                                ? "Label already exists"
                                : null;
                        },
                    });
                    if (!label)
                        return;
                    try {
                        setLoading("Adding node…");
                        TheoState(yield API.add_node({
                            checkpoint,
                            label,
                            type,
                        }));
                    }
                    finally {
                        setLoading(false);
                    }
                });
            }
        }, [checkpoint, session, nodes, edges, selection, canModifyGraph]),
        onNodeContextMenu: React.useCallback((e, node) => {
            var _a;
            e.preventDefault();
            e.stopPropagation();
            showPopupMenu({
                top: e.clientY,
                left: e.clientX,
                entries: [
                    ...(isQuantity(node) && showNodeScores
                        ? [
                            {
                                caption: `${((_a = nodesPayload[node.id]) === null || _a === void 0 ? void 0 : _a.showOperator) ? "Hide" : "Show"} Operator`,
                                action() {
                                    TheoState(s => {
                                        var _a;
                                        return (Object.assign(Object.assign({}, s), { frontEndData: Object.assign(Object.assign({}, s.frontEndData), { nodesPayload: Object.assign(Object.assign({}, nodesPayload), { [node.id]: {
                                                        position: node.position,
                                                        showOperator: !((_a = nodesPayload[node.id]) === null || _a === void 0 ? void 0 : _a.showOperator),
                                                    } }) }) }));
                                    });
                                },
                            },
                            Menu.SEPARATOR,
                        ]
                        : []),
                    createRemoveElemntAction("nodes", node.id, node.data.stage === Stage.intermediate),
                ],
            });
        }, [checkpoint, nodesPayload, canModifyGraph, selection, showNodeScores]),
        onEdgeContextMenu: React.useCallback((e, edge) => {
            var _a, _b;
            e.preventDefault();
            e.stopPropagation();
            let edgeWeight = undefined;
            const toggleCaption = getToggleEdgeDirectionCaption(edge.data.type);
            const evidenceCount = (_b = (_a = edge.data) === null || _a === void 0 ? void 0 : _a.evidenceIds) === null || _b === void 0 ? void 0 : _b.length;
            showPopupMenu({
                top: e.clientY,
                left: e.clientX,
                onVisibilityChanged: visible => {
                    if (edgeWeight && !visible) {
                        const { value } = edgeWeight;
                        TheoState(state => {
                            const _a = state.changes, _b = edge.id, _ = _a[_b], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
                            return {
                                changes: Object.assign(Object.assign({}, rest), (value !== null && { [edge.id]: value })),
                            };
                        });
                    }
                },
                entries: [
                    {
                        caption: evidenceCount
                            ? `Show evidence (${evidenceCount})`
                            : "No evidence",
                        disabled: !evidenceCount,
                        action() {
                            TheoState({ evidenceFor: edge.id });
                        },
                    },
                    Menu.SEPARATOR,
                    {
                        renderer: () => {
                            var _a;
                            return (_jsx(NumberInput, { caption: "Weight", value: changes[edge.id] || ((_a = edge.data) === null || _a === void 0 ? void 0 : _a.weight) || 0, onChange: v => (edgeWeight = { value: changes[edge.id] === v ? null : v }), disabled: !canModifyGraph, min: 0, max: 1, step: 0.01 }));
                        },
                    },
                    Menu.SEPARATOR,
                    ...(toggleCaption
                        ? [
                            {
                                caption: toggleCaption,
                                disabled: true,
                            },
                            Menu.SEPARATOR,
                        ]
                        : []),
                    createRemoveElemntAction("edges", edge.id),
                ],
            });
        }, [checkpoint, changes, canModifyGraph, selection]),
    };
    function createRemoveElemntAction(element, current, canRemoveCurrent = true) {
        const selectedElems = selection[element];
        const selectedIds = uniq([...selectedElems, current]).filter(id => canRemoveCurrent || id !== current);
        return {
            caption: !selectedElems.length
                ? "Remove"
                : `Remove Selected ${capitalize(element)}`,
            disabled: !canModifyGraph || selectedIds.length < 1,
            action() {
                return __awaiter(this, void 0, void 0, function* () {
                    yield remove(selectedIds);
                });
            },
        };
    }
    function remove(elementIds) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                setLoading("Removing edge…");
                TheoState(yield API.delete_elements({
                    elementIds,
                    checkpoint,
                }));
            }
            finally {
                setLoading(false);
            }
        });
    }
}
