var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { API } from "./api";
import { Empty } from "../shared/components/Empty";
import { Beacon, useBeacon } from "../utils/Beacon";
const globalConfig = Beacon({ initial: null });
const ConfigContext = React.createContext(null);
export function GlobalConfigProvider({ children }) {
    const value = useBeacon(globalConfig)[0];
    React.useEffect(() => {
        Promise.all([
            API.models(),
            API.get_user_directory(),
            API.domains(),
            API.generation_modes(),
        ]).then(([models, directory, domains, modes]) => {
            globalConfig.value({
                models: models.models,
                directory: directory.entries,
                domains: domains.domains,
                generation_modes: modes.generation_modes,
            });
        });
    }, []);
    return !value ? (_jsx("div", Object.assign({ className: "absolute w-full h-full flex flex-col items-stretch" }, { children: _jsx(Empty, { className: "flex-1", loading: "Initializing..." }) }))) : (_jsx(ConfigContext.Provider, Object.assign({ value: value }, { children: children })));
    function isReady(...remotes) {
        return remotes.every(r => !r.loading && r.value);
    }
}
export function useGlobalConfig() {
    const context = React.useContext(ConfigContext);
    if (!context) {
        throw new Error("useGlobalConfig must be used within a GlobalConfigProvider");
    }
    return context;
}
export function useActualModel(model) {
    const { models } = useGlobalConfig();
    return model || models[0];
}
export function useActualGenerationMode(mode) {
    const { generation_modes } = useGlobalConfig();
    return mode || generation_modes[0];
}
export function updateDirectory() {
    return __awaiter(this, void 0, void 0, function* () {
        const directory = yield API.get_user_directory();
        globalConfig.value(value => (Object.assign(Object.assign({}, value), { directory: directory.entries })));
    });
}
