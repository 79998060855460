import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { applyEdgeChanges, applyNodeChanges, useReactFlow, useViewport, } from "reactflow";
import { useEffectIfDifferent, useParams } from "../utils/hooks";
import { equals } from "../utils/util";
import { keyBy } from "lodash";
import { EdgeInfo } from "./edgeInfo/EdgeInfo";
export const EMPTY_STATE = {
    nodes: [],
    edges: [],
    viewport: { x: 0, y: 0, zoom: 1 },
    nodesById: {},
    edgesById: {},
};
const DiagramStateContext = React.createContext(null);
export function DiagramStateProvider({ children }) {
    const [state, updateState] = useParams(EMPTY_STATE);
    const value = React.useMemo(() => [
        state,
        (update) => updateState(s => {
            const up = typeof update === "function" ? update(s) : update;
            if (up.nodes && !up.nodesById)
                up.nodesById = keyBy(up.nodes, "id");
            if (up.edges && !up.edgesById)
                up.edgesById = keyBy(up.edges, "id");
            return up;
        }),
    ], [state]);
    return (_jsx(DiagramStateContext.Provider, Object.assign({ value: value }, { children: children })));
}
export function useDiagramState() {
    const state = React.useContext(DiagramStateContext);
    if (!state)
        throw new Error("useDiagramState must be used within DiagramStateProvider");
    return state;
}
export function useSyncReactFlowAndState(state, dUpdater) {
    const { nodes, edges, viewport: defaultViewport } = state;
    useSyncViewport();
    return {
        nodes,
        edges,
        defaultViewport,
        onNodesChange: React.useCallback((c) => {
            EdgeInfo.reset();
            dUpdater(s => ({
                nodes: applyNodeChanges(c, s.nodes),
            }));
        }, []),
        onEdgesChange: React.useCallback((c) => dUpdater(s => ({
            edges: applyEdgeChanges(c, s.edges),
        })), []),
    };
    function useSyncViewport() {
        const viewport = useViewport();
        useEffectIfDifferent(() => dUpdater(state => {
            return equals(state.viewport, viewport) ? state : { viewport };
        }), [], [viewport]);
        const flow = useReactFlow();
        useEffectIfDifferent(() => !equals(flow.getViewport(), defaultViewport) &&
            flow.setViewport(defaultViewport), [flow], [defaultViewport]);
    }
}
