var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { keyBy } from "lodash";
import { ActionButton } from "../shared/components/buttons/Buttons";
import { setLoading } from "../shared/components/Loading";
import { User, Users } from "../shared/components/Icons";
import { saveCurrentSession } from "./SessionPicker";
import { updateDirectory } from "./GlobalConfig";
export function PublicToggle({ state, directory, }) {
    const bySession = React.useMemo(() => keyBy(directory, "session"), [directory]);
    const currentEntry = bySession[state.session];
    const currentIsPublic = (currentEntry === null || currentEntry === void 0 ? void 0 : currentEntry.public) || false;
    const disabled = !state.session_name || !(currentEntry === null || currentEntry === void 0 ? void 0 : currentEntry.owned_by_user);
    return (_jsx(ActionButton, Object.assign({ className: "auto_hide hover:bg-brand-100", tooltip: currentIsPublic ? "Make Session Private" : "Make Session Public", disabled: disabled, onClick: () => __awaiter(this, void 0, void 0, function* () {
            try {
                setLoading(`Making ${state.session_name} ${currentIsPublic ? "private" : "public"}...`);
                yield saveCurrentSession(state.session_name, !currentIsPublic, state, false);
                yield updateDirectory();
            }
            finally {
                setLoading(false);
            }
        }) }, { children: currentIsPublic ? _jsx(Users, {}) : _jsx(User, {}) })));
}
