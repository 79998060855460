import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { ModeProvider, useModes } from "../utils/Mode";
export var Mode;
(function (Mode) {
    Mode["normal"] = "normal";
    Mode["connecting"] = "connecting";
    Mode["merging"] = "merging";
    Mode["dragging"] = "dragging";
})(Mode || (Mode = {}));
export function DiagramModeProvider({ children }) {
    return _jsx(ModeProvider, Object.assign({ defaultMode: Mode.normal }, { children: children }));
}
export function useDiagramMode() {
    return useModes();
}
