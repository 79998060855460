import { useStore, useUpdateNodeInternals } from "reactflow";
import { useEffectIfDifferent, useValueChanged } from "../utils/hooks";
import { uniqBy } from "lodash";
import { createEdge, createNode, updateNodesPayloadPosition } from "./gUtils";
import { NODE_TYPES } from "./TheoNode";
import { EdgeInfo } from "./edgeInfo/EdgeInfo";
import { useDiagramState } from "./DiagramState";
import { TheoState } from "./TheoState";
import { identity } from "../utils/util";
import { Axis } from "../utils/drawing/Axis";
import { useTheoGraphLayout } from "./theoGraphLayout";
import { useGlobalSettings } from "./GlobalSettings";
import { graphAnimator } from "./graphAnimator";
import { LAYOUT_CONFIG } from "./layoutConfig";
export function useGraphToDiagram(flow, { graph, session, frontEndData: { nodesPayload } }) {
    const { layoutAxis } = useGlobalSettings();
    const layout = useTheoGraphLayout();
    const { minZoom, maxZoom, width, height } = useStore(identity);
    const axis = layoutAxis === "H" ? Axis.X : Axis.Y;
    const config = {
        panTo: { flow, minZoom, maxZoom, width, height },
        moveDurationMs: LAYOUT_CONFIG.moveDurationMs,
        fadeOutDurationMs: LAYOUT_CONFIG.fadeOutDurationMs,
        fadeInDurationMs: LAYOUT_CONFIG.fadeInDurationMs,
    };
    const recreateGraph = useValueChanged(session);
    const [state, updater] = useDiagramState();
    const updateNodeInternals = useUpdateNodeInternals();
    useEffectIfDifferent(() => {
        EdgeInfo.reset();
        if (recreateGraph || !nodesPayload) {
            const nodes = (graph === null || graph === void 0 ? void 0 : graph.nodes.map(data => createNode(data))) || [];
            const edges = uniqBy((graph === null || graph === void 0 ? void 0 : graph.edges.map(createEdge)) || [], e => e.id);
            layout({ nodes, edges }, NODE_TYPES, axis).then(nodes => {
                updater({ nodes: nodes, edges });
                TheoState(s => ({
                    frontEndData: Object.assign(Object.assign({}, s.frontEndData), { nodesPayload: updateNodesPayloadPosition(nodes, nodesPayload) }),
                }));
                requestAnimationFrame(() => {
                    flow.fitView();
                    updateNodeInternals(nodes.map(n => n.id));
                });
            });
        }
        else {
            const update = {
                nodes: (graph === null || graph === void 0 ? void 0 : graph.nodes.map(data => { var _a; return createNode(data, (_a = nodesPayload[data.id]) === null || _a === void 0 ? void 0 : _a.position); })) || [],
                edges: (graph === null || graph === void 0 ? void 0 : graph.edges.map(data => {
                    const edge = createEdge(data);
                    return edge;
                })) || [],
            };
            layout(update, NODE_TYPES, axis).then(nodes => {
                const ne = {
                    nodes,
                    edges: update.edges,
                };
                graphAnimator(state, updater, ne, config, () => updateNodeInternals(ne.nodes.map(n => n.id)));
            });
        }
    }, [state, axis], [graph], true);
}
