import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import FormBuilder from "antd-form-builder";
import React, { useCallback } from "react";
import { Button, Form, Modal } from "antd";
import "./FormDialog.less";
import { useParams } from "../../utils/hooks";
import { equals } from "../../utils/util";
export function useFormDialog({ title, initialValues, onNewValues, fields, guruModeFields, }) {
    const [{ visible, guruMode }, update] = useParams({
        visible: false,
        values: initialValues,
        guruMode: false,
    });
    fields = !guruModeFields
        ? fields
        : fields.filter(({ key }) => guruMode || !guruModeFields.has(key));
    const showModal = (guruMode) => {
        form.setFieldsValue(initialValues);
        update({ visible: true, values: initialValues, guruMode });
    };
    const [form] = Form.useForm();
    const updateConfigProgress = useCallback(() => {
        update({ values: form.getFieldsValue() });
    }, [form, initialValues]);
    function hideModal() {
        update({ visible: false });
    }
    return [
        !visible ? null : (_jsx(Modal, Object.assign({ width: 700, title: `${title}${guruMode ? " (Guru Mode)" : ""}`, closable: false, open: visible, destroyOnClose: true, footer: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ onClick: hideModal }, { children: "Cancel" })), _jsx(Button, Object.assign({ type: "primary", onClick: () => {
                            onNewValues(form.getFieldsValue());
                            hideModal();
                        }, disabled: equals(form.getFieldsValue(), initialValues) }, { children: "Ok" }))] }) }, { children: _jsx(Form, Object.assign({ className: "Config", form: form, initialValues: initialValues, onValuesChange: updateConfigProgress }, { children: _jsx(FormBuilder, { meta: {
                        fields,
                    }, form: form }) })) }))),
        showModal,
    ];
}
export function FormDialog({ title, fields, initialValues, onValue, width = 448, }) {
    const [form] = Form.useForm();
    React.useEffect(() => {
        form.setFieldsValue(initialValues);
    }, []);
    return (_jsx(Modal, Object.assign({ width: width, title: title, closable: false, open: true, destroyOnClose: true, footer: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ onClick: () => onValue(null) }, { children: "Cancel" })), _jsx(Button, Object.assign({ type: "primary", onClick: () => onValue(form.getFieldsValue()), disabled: equals(form.getFieldsValue(), initialValues) }, { children: "Ok" }))] }) }, { children: _jsx(Form, Object.assign({ className: "Config", form: form, initialValues: initialValues }, { children: _jsx(FormBuilder, { meta: {
                    fields,
                }, form: form }) })) })));
}
