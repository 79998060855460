var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getConfig } from "./serverConfig";
import { reloadToHomePage } from "./util";
const authInfo = {
    getAuthToken() {
        return __awaiter(this, void 0, void 0, function* () {
            return null;
        });
    },
    isHeliosAuth() {
        return false;
    },
    userInfo: {
        userId: "",
        userName: "Test User",
        orgId: "",
    },
    logout() { },
};
export function setSessionInfo(tokenProvider, isHeliosToken, userInfo, logout) {
    authInfo.getAuthToken = tokenProvider;
    authInfo.isHeliosAuth = () => isHeliosToken;
    authInfo.userInfo = userInfo;
    authInfo.logout = logout;
}
export function usingHeliosAuth() {
    // “helios authentication” in the UI is a technique to allow users without auth0 accounts
    // to access the Cora UI. This is to support Userbrain testers. It works by GET-ing an API endpoint
    // that is removed from normal authentication requirements. The result of this GET is a redirect to the CORA ui
    // with the helios_authn query param set to a Helios auth token. When the UI see this,
    // it does a few things. First, it bypasses the auth0 authentication.
    // Next, it uses the Helios token in the authorization header for API requests.
    // And finally, it changes the URL used for API requests to an ingress that can validate Helios auth tokens.
    const searchParams = new URLSearchParams(window.location.search);
    const heliosAuthn = searchParams.get("helios_authn");
    if (heliosAuthn !== null) {
        setSessionInfo(() => __awaiter(this, void 0, void 0, function* () { return heliosAuthn; }), true, { userId: heliosAuthn, userName: "Test User", orgId: "test_org" }, reloadToHomePage);
        return true;
    }
    return false;
}
export function hasAuthToken() {
    return !!getConfig("AUTH_TOKEN", "");
}
export function getAuthToken() {
    return __awaiter(this, void 0, void 0, function* () {
        return getConfig("AUTH_TOKEN", "") || authInfo.getAuthToken();
    });
}
export function getCurrentUserInfo() {
    return authInfo.userInfo;
}
export function logout() {
    authInfo.logout();
}
export function getCoraServerUrl() {
    return getConfig(authInfo.isHeliosAuth() ? "HELIOS_CORA_SERVER_URL" : "CORA_SERVER_URL");
}
