import { jsx as _jsx } from "react/jsx-runtime";
import { CRCDirection, SortOrder, } from "../api/types";
import { createDependencies } from "../../utils/dependencies";
import { useMemoIfEqual, useParams } from "../../utils/hooks";
import { useValidatedCorpusIds } from "./sourceManagement";
import { pick } from "lodash";
import React from "react";
import { Beacon, ParamsBeacon, useBeacon } from "../../utils/Beacon";
import { keysFor } from "../../utils/util";
import { useEncodedQueryParamsAsState } from "./codedParamsState";
export const ENCODED_QUERY_PARAM_KEY = "dataId";
export const CONCEPTS1_OVERRIDE = {
    concepts1Override: {},
};
export const CONCEPTS1_POSSIBLE = {
    concepts1Possible: null,
};
export const CONCEPT1 = Object.assign(Object.assign({ concepts1: [] }, CONCEPTS1_OVERRIDE), CONCEPTS1_POSSIBLE);
export const CONCEPTS2_OVERRIDE = {
    concepts2Override: {},
};
export const CONCEPTS2_POSSIBLE = {
    concepts2Possible: null,
};
export const CONCEPT2 = Object.assign(Object.assign({ concepts2: [] }, CONCEPTS2_OVERRIDE), CONCEPTS2_POSSIBLE);
export const EXTRA_CONCEPTS_OVERRIDE = {
    extraConceptsOverride: {},
};
export const EXTRA_CONCEPTS_POSSIBLE = {
    extraConceptsPossible: null,
};
export const EXTRA_CONCEPTS = Object.assign(Object.assign({ extraConcepts: [] }, EXTRA_CONCEPTS_OVERRIDE), EXTRA_CONCEPTS_POSSIBLE);
export const CONTEXT_CONCEPTS_OVERRIDE = {
    contextConceptsOverride: {},
};
export const CONTEXT_CONCEPTS_POSSIBLE = {
    contextConceptsPossible: null,
};
export const CONTEXT_CONCEPTS = Object.assign(Object.assign({ contextConcepts: [] }, CONTEXT_CONCEPTS_OVERRIDE), CONTEXT_CONCEPTS_POSSIBLE);
export const RELATION = {
    relations: [],
};
export const KEYWORD_METADATA = {
    keywordMetadata: [],
};
export const RANGE_METADATA_OVERRIDE = {
    rangeMetadataOverride: [],
};
export const RANGE_METADATA = Object.assign(Object.assign({}, RANGE_METADATA_OVERRIDE), { rangeMetadata: [] });
export const BOOLEAN_METADATA = {
    booleanMetadata: [],
};
export const METADATA = Object.assign(Object.assign(Object.assign({}, KEYWORD_METADATA), RANGE_METADATA), BOOLEAN_METADATA);
export const DEFAULT_CRC_DIRECTION = CRCDirection.BOTH;
const DEFAULT_ORDER = SortOrder.DESC;
export const REFUTING_QUERY_PARAMS = {
    refuting_relations: null,
    refuting_concepts: null,
};
export const EMPTY_QUERY_PARAMS = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, CONCEPT1), RELATION), CONCEPT2), EXTRA_CONCEPTS), CONTEXT_CONCEPTS), REFUTING_QUERY_PARAMS), { crcDirection: DEFAULT_CRC_DIRECTION, argClauses: {}, required_arguments: [], text: "" });
export const DEFAULT_APERTURE = 0.5;
export const EMPTY_DM_PARAMS = {
    concepts: {},
    clauses: {},
    relations: {},
    constraints: [],
    inference_rules: [],
};
export const EMPTY_COMMON_STATE_PARAMS = Object.assign(Object.assign({}, METADATA), { corpus_ids: [], evidenceFilter: "", refuting: false, sortFieldId: "", sortOrder: DEFAULT_ORDER, aperture: DEFAULT_APERTURE, dmParams: EMPTY_DM_PARAMS, activeConstraintId: "" });
export const COMMON_STATE_PARAMS_KEYS = keysFor(EMPTY_COMMON_STATE_PARAMS);
export const EMPTY_QUERY_STATE = Object.assign(Object.assign({}, EMPTY_COMMON_STATE_PARAMS), { overrides: {}, extra: [] });
export const EMPTY_SEARCH_PARAMS = Object.assign(Object.assign(Object.assign({}, EMPTY_COMMON_STATE_PARAMS), EMPTY_QUERY_PARAMS), { queries: [] });
export const EMPTY_CRC = {
    concepts1: [],
    concepts2: [],
    relations: [],
    crcDirection: DEFAULT_CRC_DIRECTION,
    queries: [],
    text: "",
};
export const EMPTY_CRC_AND_CONTEXT = Object.assign(Object.assign({}, EMPTY_CRC), { contextConcepts: [] });
export const EMPTY_FILTERS = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, METADATA), { required_arguments: [], argClauses: {} }), CONCEPTS1_OVERRIDE), CONCEPTS2_OVERRIDE), EXTRA_CONCEPTS), EXTRA_CONCEPTS_OVERRIDE), CONTEXT_CONCEPTS_OVERRIDE);
export const CRC_KEYS = keysFor(EMPTY_CRC);
export function filterCoraStateQueryParams(update) {
    return pick(update, ...Object.keys(EMPTY_SEARCH_PARAMS));
}
const extraDependenciesBeacon = ParamsBeacon(Beacon({
    initial: {},
}));
export function registerExtraClearDependencies(key, items, dependencies) {
    extraDependenciesBeacon.value({ [key]: { items, dependencies } });
}
export function unregisterExtraClearDependencies(key) {
    extraDependenciesBeacon.value({ [key]: undefined });
}
const DEFAULT_UPDATE_CONFIG = {
    filter: true,
    pathName: undefined,
};
const CoraStateContext = React.createContext(undefined);
export function CoraStateProvider({ initial, usingEncoded, children, }) {
    const encodedMaintainer = useEncodedQueryParamsAsState(initial);
    const simpleMaintainer = useParams(initial);
    const provider = useCreateCoraState(usingEncoded ? encodedMaintainer : simpleMaintainer);
    return (_jsx(CoraStateContext.Provider, Object.assign({ value: provider }, { children: children })));
}
export function useCoraState() {
    return React.useContext(CoraStateContext);
}
export function useCreateCoraState([state, updater]) {
    const corpus_ids = useValidatedCorpusIds(state.corpus_ids);
    const coraState = useMemoIfEqual(Object.assign(Object.assign({}, state), { corpus_ids }));
    const [extraDependencies] = useBeacon(extraDependenciesBeacon);
    const updateParams = React.useCallback((update, updateConfig = DEFAULT_UPDATE_CONFIG) => {
        const { filter, pathName } = Object.assign(Object.assign({}, DEFAULT_UPDATE_CONFIG), updateConfig);
        update = filter ? filterCoraStateQueryParams(update) : update;
        function calcUpdate() {
            function getDependencies() {
                const deps = createDependencies()
                    .define("corpus_ids", [
                    ...keysFor(CONCEPT1),
                    ...keysFor(CONCEPT2),
                    ...keysFor(RELATION),
                    ...keysFor(METADATA),
                    ...keysFor(EXTRA_CONCEPTS),
                    ...keysFor(CONTEXT_CONCEPTS),
                    "evidenceFilter",
                    "required_arguments",
                    "argClauses",
                    "crcDirection",
                    "queries",
                    "sortFieldId",
                    "sortOrder",
                    "text",
                    "dmParams",
                    "activeConstraintId",
                ])
                    .define(CRC_KEYS, [
                    ...keysFor(CONCEPTS1_OVERRIDE),
                    ...keysFor(CONCEPTS2_OVERRIDE),
                    ...keysFor(CONCEPTS1_POSSIBLE),
                    ...keysFor(CONCEPTS2_POSSIBLE),
                    "required_arguments",
                    "argClauses",
                    "evidenceFilter",
                ])
                    .define(keysFor(EXTRA_CONCEPTS), keysFor(EXTRA_CONCEPTS_OVERRIDE))
                    .define(keysFor(CONTEXT_CONCEPTS), keysFor(CONTEXT_CONCEPTS_OVERRIDE))
                    .define([
                    ...keysFor(CONCEPTS1_OVERRIDE),
                    ...keysFor(CONCEPTS2_OVERRIDE),
                    "required_arguments",
                    "argClauses",
                    "rangeMetadata",
                    "keywordMetadata",
                    "booleanMetadata",
                ], [...keysFor(REFUTING_QUERY_PARAMS), "rangeMetadataOverride"])
                    .define([...keysFor(REFUTING_QUERY_PARAMS)], ["rangeMetadataOverride"]);
                Object.values(extraDependencies).forEach(v => deps.define(v.items, v.dependencies));
                return deps.dependencies(...Object.keys(update));
            }
            const keysToUpdate = getDependencies();
            return keysToUpdate.reduce((acc, k) => {
                acc[k] = k in update ? update[k] : EMPTY_SEARCH_PARAMS[k];
                return acc;
            }, {});
        }
        const enhancedUpdate = calcUpdate();
        console.log("UPDATE", enhancedUpdate);
        updater(enhancedUpdate, pathName);
    }, [updater, extraDependencies]);
    // useEffectIfDifferent(
    //   () => {
    //     console.log(">>>", coraStateToQueryState(coraState))
    //   },
    //   [],
    //   [coraState],
    //   true
    // )
    return [coraState, updateParams];
}
export function getMetadataSearchParams(coraState) {
    return pick(coraState, keysFor(METADATA));
}
