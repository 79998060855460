var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useReactFlow, useStoreApi } from "reactflow";
import { useOnGlobalEscape } from "../utils/events";
import { markAsUnavailable } from "./unavailable";
import { Mode, useDiagramMode } from "./DiagramMode";
import { getNodeAt } from "./gUtils";
import { setLoading } from "../shared/components/Loading";
import { TheoState, useTheoState } from "./TheoState";
import { API } from "./api";
import { pickEdgeType } from "./EdgeTypePicker";
export function useConnection() {
    const flow = useReactFlow();
    const store = useStoreApi();
    const sourceId = React.useRef(null);
    const mode = useDiagramMode();
    const { checkpoint } = useTheoState();
    useOnGlobalEscape(!mode.is(Mode.connecting), () => {
        store.getState().cancelConnection();
        sourceId.current = null;
    });
    return {
        isValidConnection: React.useCallback(() => false, []),
        onConnectStart: React.useCallback((_, { nodeId }) => {
            sourceId.current = nodeId;
            markAsUnavailable(flow, nodeId);
        }, []),
        onConnectEnd: React.useCallback((event) => __awaiter(this, void 0, void 0, function* () {
            const source = sourceId.current
                ? flow.getNode(sourceId.current)
                : undefined;
            const target = getNodeAt(flow, event);
            sourceId.current = null;
            mode(Mode.normal);
            if (!target || !source || target.id === source.id)
                return;
            const type = yield pickEdgeType(source, target);
            if (!type)
                return;
            try {
                setLoading("Adding edge…");
                TheoState(yield API.add_edge({
                    checkpoint,
                    sourceId: source.id,
                    targetId: target.id,
                    type,
                }));
            }
            finally {
                setLoading(false);
            }
        }), [flow.project, checkpoint]),
    };
}
