import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { rounder } from "../rounder";
import { getRectOfNodes, useNodesInitialized, useReactFlow, useViewport, } from "reactflow";
import { useMemoIfEqual } from "../hooks";
import React from "react";
import * as BubbleSets from "bubblesets-js";
import { Area, calculatePotentialOutline, createLineInfluenceArea, createRectangleInfluenceArea, Line, Rectangle, } from "bubblesets-js";
import assert from "assert";
import { PathWalker } from "../drawing/PathWalker";
import { range } from "../iterators";
import { X, XY, Y } from "../drawing/geometry";
import { PathBuilder } from "../drawing/PathBuilder";
import { getSourceIntersection } from "./graphShared";
const round = rounder();
export function toPixel({ x, y }, { x: oX, y: oY, zoom }) {
    return {
        x: x * zoom + oX,
        y: y * zoom + oY,
    };
}
export function FocusAreas({ getEdgeShape, color, focusNodes, focusEdges = [], nodesToAvoid = [], edgeToAvoid = [], }) {
    const initalized = useNodesInitialized();
    const viewport = useViewport();
    const flow = useReactFlow();
    const dependencies = useMemoIfEqual([
        ...createDependencies(focusNodes, focusEdges),
        ...createDependencies(nodesToAvoid, edgeToAvoid),
    ]);
    function createDependencies(nodes = [], edges = []) {
        return [
            nodes
                .map(n => ({
                id: n.id,
                width: n.width,
                height: n.height,
                x: n.position.x,
                y: n.position.y,
            }))
                .sort((a, b) => a.id.localeCompare(b.id)),
            edges.map(e => e.id).sort(),
        ];
    }
    const { focusPath, noFocusPath } = React.useMemo(() => {
        if (!initalized || !focusNodes.length)
            return {};
        return {
            focusPath: getBubblePaths(focusNodes, focusEdges, nodesToAvoid, edgeToAvoid),
            noFocusPath: getBubblePaths(nodesToAvoid, edgeToAvoid, [], []),
        };
        function getBubblePaths(nodes, edges, nodesToAvoid, edgeToAvoid) {
            const pointPath = getBubbleSet(nodes, edges, nodesToAvoid, edgeToAvoid);
            const cleanPath = pointPath.sample(8).simplify(0).bSplines().simplify(0);
            if (cleanPath.length < 3)
                return null;
            return (cleanPath.points
                .map((p, idx) => {
                const { x, y } = toPixel(p, viewport);
                return `${idx === 0 ? "M" : "L"} ${round(x)} ${round(y)}`;
            })
                .join(" ") + " Z ");
        }
        function getBubbleSet(nodes, edges, nodesToAvoid, edgeToAvoid) {
            const opts = Object.assign(Object.assign({}, BubbleSets.defaultOptions), { virtualEdges: false, nonMemberInfluenceFactor: -0.4, edgeInfluenceFactor: 1, morphBuffer: 130, threshold: 1 });
            const padding = Math.max(opts.edgeR1, opts.nodeR1) + opts.morphBuffer;
            const bb = getRectOfNodes(nodes);
            const nextPotentialBB = {
                x: bb.x - padding,
                y: bb.y - padding,
                width: bb.width + padding * 2,
                height: bb.height + padding * 2,
            };
            const potentialArea = Area.fromPixelRegion(nextPotentialBB, opts.pixelGroup);
            const memberShapes = nodes.map(calcNodeShape);
            return calculatePotentialOutline(potentialArea, memberShapes.map(calcRectArea), edges
                .map(edge => getEdgeShape(edge, flow).map(shape => createLineInfluenceArea(shape, potentialArea, opts.edgeR1)))
                .flat(), nodesToAvoid.map(calcNodeShape).map(calcRectArea), p => p.containsElements(memberShapes), opts);
            function calcNodeShape({ position: { x, y }, width, height }) {
                return new Rectangle(x, y, width || 1, height || 1);
            }
            function calcRectArea(shape) {
                return createRectangleInfluenceArea(shape, potentialArea, opts.nodeR1);
            }
        }
    }, [initalized, dependencies, viewport]);
    return !focusPath ? null : (_jsxs("svg", Object.assign({ className: "focus-area absolute w-full h-full -z-10" }, { children: [_jsx("path", { d: focusPath, fill: color, stroke: "none" }), !noFocusPath ? null : (_jsx("path", { d: noFocusPath, fill: "white", stroke: "none" }))] })));
}
export function createPathShapeExtractor(steps, pathGetter) {
    return (edge, flow) => calcPathShapes(pathGetter(flow, edge), steps);
    function calcPathShapes(svgPath, steps) {
        assert(steps > 0);
        const walker = PathWalker(svgPath);
        const segmentLen = 1 / steps;
        return range(steps).reduce((acc, idx) => {
            const s = !idx ? walker.at(0) : XY(acc.at(-1).x2, acc.at(-1).y2);
            const t = walker.at(segmentLen * (idx + 1));
            acc.push(Line.from({ x1: X(s), y1: Y(s), x2: X(t), y2: Y(t) }));
            return acc;
        }, []);
    }
}
export function calcLineEdgePath(flow, edge) {
    const source = flow.getNode(edge.source);
    const target = flow.getNode(edge.target);
    const s = getSourceIntersection(source, target);
    const t = getSourceIntersection(target, source);
    return PathBuilder().M(s.x, s.y).L(t.x, t.y).path();
}
