import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
export function TextHighlighter({ highlightedText: { highlights, text }, renderer = DefaultSegmentRenderer, }) {
    const segments = React.useMemo(function createSegments() {
        var _a, _b;
        const sorted = (highlights || []).sort((a, b) => a.startOffset - b.startOffset);
        const segments = sorted.reduce((acc, { startOffset: start, endOffset: end, payload }) => {
            var _a, _b;
            const last = (_b = (_a = acc.at(-1)) === null || _a === void 0 ? void 0 : _a.end) !== null && _b !== void 0 ? _b : 0;
            if (last < start) {
                acc.push({
                    idx: acc.length,
                    text: text.slice(last, start),
                    highlight: false,
                    start: last,
                    end: start,
                });
            }
            acc.push({
                idx: acc.length,
                text: text.slice(start, end),
                highlight: true,
                payload,
                start,
                end,
            });
            return acc;
        }, []);
        const last = (_b = (_a = segments.at(-1)) === null || _a === void 0 ? void 0 : _a.end) !== null && _b !== void 0 ? _b : 0;
        return last < text.length
            ? [
                ...segments,
                {
                    idx: segments.length,
                    text: text.slice(last),
                    highlight: false,
                    start: last,
                    end: text.length,
                },
            ]
            : segments;
    }, [highlights, text]);
    return _jsx(_Fragment, { children: segments.map(renderer) });
}
export function DefaultSegmentRenderer({ idx, text, highlight }) {
    return (_jsx("span", Object.assign({ className: highlight ? "bg-text_highlight_subtle" : "" }, { children: text }), idx));
}
