import { Beacon, ParamsBeacon, useBeacon } from "./Beacon";
export function createLocalStorageParams(key, initial) {
    function getInitialValue() {
        const local = window.localStorage.getItem(key);
        return Object.assign(Object.assign({}, initial), (local && JSON.parse(local)));
    }
    const beacon = ParamsBeacon(Beacon({
        initial: getInitialValue(),
    }));
    beacon.on(value => {
        window.localStorage.setItem(key, JSON.stringify(value));
    });
    function useLocalStorageValue() {
        return useBeacon(beacon)[0];
    }
    return [useLocalStorageValue, beacon.value];
}
