var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useNodeMergeDragging } from "./nodeMergeDragging";
import { CombinationType, Stage } from "./types";
import { showPopupMenu } from "../shared/actions/PopupMenu";
import { limitLength, valuesAre } from "../utils/util";
import { createNodePositionUpdater, updateNodes } from "./gUtils";
import { TheoState, useTheoState } from "./TheoState";
import { API } from "./api";
import { setLoading } from "../shared/components/Loading";
export function useCombineNodes(flow) {
    const { checkpoint, ontologies, stage } = useTheoState();
    const canModifyGraph = stage === "done";
    return useNodeMergeDragging(flow, (e, source, target, position) => {
        showPopupMenu({
            top: e.clientY,
            left: e.clientX,
            entries: [
                {
                    caption: caption("Merge", "to"),
                    disabled: !canModifyGraph,
                    action() {
                        return __awaiter(this, void 0, void 0, function* () {
                            yield combineNodes(CombinationType.merge);
                        });
                    },
                },
                {
                    caption: caption("Make", "a type of"),
                    disabled: !canModifyGraph ||
                        areRelated(source.data.label, target.data.label, ontologies.find(o => o.name === "Subtypes")),
                    action() {
                        return __awaiter(this, void 0, void 0, function* () {
                            updateNodes(flow, createNodePositionUpdater(source.id, position));
                            yield combineNodes(CombinationType.isA);
                        });
                    },
                },
                {
                    caption: caption("Make", "is part of"),
                    disabled: !canModifyGraph ||
                        source.data.type === "state" ||
                        areRelated(source.data.label, target.data.label, ontologies.find(o => o.name === "Parts")),
                    action() {
                        return __awaiter(this, void 0, void 0, function* () {
                            updateNodes(flow, createNodePositionUpdater(source.id, position));
                            yield combineNodes(CombinationType.partOf);
                        });
                    },
                },
            ],
        });
        function caption(prefix, op) {
            return (_jsxs("span", { children: [_jsx("span", Object.assign({ className: "font-bold" }, { children: prefix })), " '", limitLength(source.data.label, 25), "'", " ", _jsx("span", Object.assign({ className: "font-bold" }, { children: op })), " '", limitLength(target.data.label, 25), "'"] }));
        }
        function combineNodes(combinationType) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    setLoading("Combining nodes...");
                    const response = yield API.combine_nodes({
                        sourceNodeId: source.id,
                        targetNodeId: target.id,
                        checkpoint,
                        combinationType,
                    });
                    TheoState(response);
                }
                finally {
                    setLoading(false);
                }
            });
        }
    }, function canMerge(source, target) {
        const sourceState = source.data.stage;
        const targetStage = target.data.stage;
        return (!valuesAre(sourceState, targetStage, Stage.source, Stage.target) &&
            source.data.type === target.data.type);
    });
}
function areRelated(source, target, ontology) {
    return (isDescendant(source, ontology === null || ontology === void 0 ? void 0 : ontology.children.find(c => c.name === target)) ||
        isDescendant(target, ontology === null || ontology === void 0 ? void 0 : ontology.children.find(c => c.name === source)));
}
function isDescendant(item, ontology) {
    return (!!ontology &&
        (item === ontology.name ||
            ontology.children.some(child => isDescendant(item, child))));
}
