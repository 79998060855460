function creator() {
    let calculator;
    let cache;
    function EdgeInfo(edgeId, nodes, nodesById, edges) {
        let info = cache === null || cache === void 0 ? void 0 : cache[edgeId];
        if (!cache || !info) {
            if (!calculator)
                throw "Np calculator for EdgeInfo";
            EdgeInfo.reset(calculator(nodes, nodesById, edges));
        }
        info = cache[edgeId];
        return info;
    }
    EdgeInfo.reset = (info) => {
        cache = info;
    };
    EdgeInfo.setCalculator = (calc) => {
        calculator = calc;
        cache = undefined;
    };
    return EdgeInfo;
}
export const EdgeInfo = creator();
