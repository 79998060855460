import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { ConfigProvider } from "antd";
import { buildAntTheme } from "./buildAntTheme";
import { ThemeProvider, useTheme } from "./ThemeContext";
export function WithTheme({ children }) {
    return (_jsx(ThemeProvider, { children: _jsx(AntWithTheme, { children: children }) }));
}
export function AntWithTheme({ children }) {
    const theme = useTheme();
    return (_jsx(ConfigProvider, Object.assign({ theme: buildAntTheme(theme) }, { children: children })));
}
