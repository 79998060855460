var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { TitledPanel } from "./TitledPanel";
import { Scroller } from "../shared/components/Scroller";
import { TheoState, useTheoState } from "./TheoState";
import { Empty } from "../shared/components/Empty";
import { useTheme } from "./theme/ThemeContext";
import chroma from "chroma-js";
import { API } from "./api";
import { useEffectIfDifferent } from "../utils/hooks";
import { useDiagramState } from "./DiagramState";
import { useReactFlow } from "reactflow";
import { DefaultSegmentRenderer, TextHighlighter } from "./TextHighlighter";
export function AnalysisPanel() {
    const { answer, graph, checkpoint, stage } = useTheoState();
    const theme = useTheme();
    const [loading, setLoading] = React.useState(false);
    const done = stage === "done";
    useEffectIfDifferent(() => {
        generate();
        function generate() {
            return __awaiter(this, void 0, void 0, function* () {
                setLoading(true);
                const remote = !(graph === null || graph === void 0 ? void 0 : graph.nodes.length) || !checkpoint || !done
                    ? null
                    : yield API.get_analysis({ checkpoint });
                TheoState({ answer: (remote === null || remote === void 0 ? void 0 : remote.answer) || null });
                setLoading(false);
            });
        }
    }, [], [graph, done, checkpoint]);
    const [{ nodesById, edges, viewport: { zoom }, }, updateState,] = useDiagramState();
    const flow = useReactFlow();
    return (_jsx(TitledPanel, Object.assign({ title: "analysis", className: "w-full h-full pt-2" }, { children: _jsx(Empty, Object.assign({ loading: loading && "generating...", empty: !done ? "Constructing Causal Model..." : !answer && "no analysis", showIcon: false }, { children: _jsx(Scroller, { children: _jsx("div", Object.assign({ className: "whitespace-pre-wrap" }, { children: answer && (_jsx(TextHighlighter, { highlightedText: addPassageHighlights(answer), renderer: h => {
                            var _a;
                            const { highlight, idx, text } = h;
                            const payload = h.payload || {};
                            const selection = (payload.type === "path" ? payload.ids : [payload.id])
                                .map((id) => nodesById[id])
                                .filter(Boolean);
                            const backgroundColor = !selection.length ||
                                payload.type !== "node" ||
                                !((_a = selection[0]) === null || _a === void 0 ? void 0 : _a.selected)
                                ? theme.colors.background
                                : chroma
                                    .mix(theme.colors.text_primary, theme.colors.background, 0.8)
                                    .css();
                            return highlight ? (_jsx("a", Object.assign({ className: "underline cursor-pointer", style: { backgroundColor }, onClick: () => {
                                    if (selection.length) {
                                        updateState(s => {
                                            const ids = new Set(selection.map(n => n.id));
                                            const eIds = new Set(s.edges
                                                .filter(e => ids.has(e.source) && ids.has(e.target))
                                                .map(e => e.id));
                                            return {
                                                nodes: s.nodes.map(n => (Object.assign(Object.assign({}, n), { selected: ids.has(n.id) }))),
                                                edges: s.edges.map(e => (Object.assign(Object.assign({}, e), { selected: eIds.has(e.id) }))),
                                            };
                                        });
                                        requestAnimationFrame(() => {
                                            flow.fitView({
                                                nodes: selection,
                                                duration: 1000,
                                                minZoom: 0.5,
                                                maxZoom: Math.max(1, zoom),
                                            });
                                        });
                                    }
                                } }, { children: text }), idx)) : (DefaultSegmentRenderer(h));
                        } })) })) }) })) })));
}
function addPassageHighlights(h) {
    return h;
    // const numerals = Array.from(h.text.matchAll(/(\n(\d+\.))/g) || [])
    // const newHighlights = [] as Highlight[]
    // if (numerals.length) {
    //   numerals.forEach((m, idx) => {
    //     const start = m.index!
    //     const end =
    //       idx < numerals.length - 1 ? numerals[idx + 1].index! : h.text.length
    //     const hs = h.highlights.filter(
    //       h => h.startOffset >= start && h.endOffset <= end
    //     )
    //     if (hs.length) {
    //       newHighlights.push({
    //         startOffset: start,
    //         endOffset: start + 3,
    //         payload: {type: "path", ids: hs.map(h => h.payload!.id)},
    //       })
    //     }
    //   }, 0)
    // }
    // return newHighlights.length
    //   ? {...h, highlights: [...h.highlights, ...newHighlights]}
    //   : h
}
