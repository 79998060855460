import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip } from "../shared/components/Tooltip";
import { classes } from "../utils/classes";
import React from "react";
export function CtrlButton({ onClick, disabled, tooltip, children, warning, }) {
    return (_jsx(Tooltip, Object.assign({ content: tooltip, placement: "bottom", theme: warning ? "warning" : undefined }, { children: _jsx("button", Object.assign({ className: classes(" text-2xl text-2xl bg-text_primary text-background", disabled ? "opacity-50 cursor-not-allowed" : ""), style: {
                width: 36,
                height: 36,
                borderRadius: 8,
            }, disabled: disabled, onClick: onClick }, { children: children })) }), warning ? "warning" : undefined));
}
