import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BaseEdge, EdgeLabelRenderer } from "reactflow";
import chroma from "chroma-js";
import { getArrowPaths, getEdgeWidth } from "./graphGeo";
import { getEdgeTypeValue, getGraphColors, getNodeColor, getNodeValue, isQuantity, isState, theoType, } from "./gUtils";
import { classes } from "../utils/classes";
import { EdgeType } from "./types";
import { Circle } from "../utils/drawing/Circle";
import { useTheoState } from "./TheoState";
import { useTheme } from "./theme/ThemeContext";
import { EdgeInfo } from "./edgeInfo/EdgeInfo";
import { Tooltip } from "../shared/components/Tooltip";
import { getEdgeDescription, LABELS_BY_TYPE } from "./nlg";
import { useDiagramState } from "./DiagramState";
const TheoEdge = React.memo(function TheoEdge(props) {
    var _a, _b;
    const { id, source, target, markerEnd, style, data, selected, } = props;
    const { type, evidenceIds } = data;
    const isOntology = [EdgeType.isA, EdgeType.partOf].includes(type);
    const theme = useTheme();
    const dark = theme.active === "dark";
    const [{ nodes, nodesById, edges }] = useDiagramState();
    const sourceNode = nodesById[source];
    const targetNode = nodesById[target];
    if (!sourceNode || !targetNode) {
        return null;
    }
    const { changes, evidenceFor } = useTheoState();
    const info = EdgeInfo(id, nodes, nodesById, edges);
    if (!info)
        return null;
    const { edgePath, labelX, labelY, sourceX, sourceY } = info;
    const sourceValue = getNodeValue(sourceNode, changes[sourceNode.id]);
    const weight = (_b = (_a = changes[id]) !== null && _a !== void 0 ? _a : data === null || data === void 0 ? void 0 : data.weight) !== null && _b !== void 0 ? _b : 0;
    const edgeWidth = isOntology ? 3 : getEdgeWidth(weight);
    const [arrowHead, body] = getArrowPaths(edgePath, edgeWidth);
    const mul = [EdgeType.influence_inv].includes(type) ? -1 : 1;
    const value = getEdgeTypeValue(type);
    const colors = getGraphColors(dark);
    const color = isQuantity(sourceNode) && isState(targetNode)
        ? sourceValue > 0 && value > 0
            ? colors.increasing
            : sourceValue < 0 && value < 0
                ? colors.decreasing
                : colors.disabled
        : isState(sourceNode) && isQuantity(targetNode)
            ? value > 0 && sourceValue > 0
                ? colors.increasing
                : value < 0 && sourceValue > 0
                    ? colors.decreasing
                    : colors.disabled
            : isOntology
                ? colors.neutral
                : getNodeColor(sourceNode, {
                    override: changes[sourceNode.id],
                    mul,
                    darkTheme: dark,
                });
    const highlighted = evidenceFor
        ? evidenceFor === id
        : sourceNode.selected || targetNode.selected || selected;
    return (_jsxs(_Fragment, { children: [highlighted && (_jsx("path", { d: edgePath, fill: "none", stroke: chroma(color)
                    .alpha(dark ? 0.5 : 0.25)
                    .css(), 
                // strokeWidth={edgeWidth + 6}
                strokeWidth: Math.max(edgeWidth * 3, 9), strokeLinecap: "round" })), _jsx(BaseEdge, { path: body, markerEnd: markerEnd, style: Object.assign(Object.assign({}, style), { strokeWidth: edgeWidth, strokeLinecap: "round", stroke: color, strokeDasharray: isOntology
                        ? "5,5"
                        : !(evidenceIds === null || evidenceIds === void 0 ? void 0 : evidenceIds.length)
                            ? `${Math.max(5, edgeWidth)},${Math.max(5, edgeWidth * 2)}`
                            : undefined }) }), arrowHead && _jsx("path", { d: arrowHead, fill: color, stroke: "none" }), _jsx(Circle, { params: { r: edgeWidth, x: sourceX, y: sourceY }, fill: color }), _jsx(EdgeLabelRenderer, { children: _jsx("div", Object.assign({ id: edgeLabelId(id), style: {
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: "all",
                        position: "absolute",
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 11,
                        backgroundColor: theme.colors.background,
                        color: color,
                        borderWidth: 2,
                        borderColor: color,
                        borderStyle: "solid",
                    }, className: "nodrag nopan px-1 rounded-xl" }, { children: _jsx(Tooltip, Object.assign({ content: getEdgeDescription(data, id => (id === source ? sourceNode : targetNode), changes) }, { children: _jsx("div", Object.assign({ className: classes("font-semibold", !isOntology && "cursor-pointer") }, { children: LABELS_BY_TYPE[type] })) })) })) })] }));
});
export const EDGE_TYPES = {
    [theoType]: TheoEdge,
};
export function edgeLabelId(id) {
    return "EdgeLabel:" + id;
}
