var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Menu } from "./Menu";
import { showPopUp } from "../components/GlobalPopUp";
export function showPopupMenu(config) {
    if (!config) {
        showPopUp(false);
    }
    else {
        const { left, top, maxHeight, header, onVisibilityChanged } = config, props = __rest(config, ["left", "top", "maxHeight", "header", "onVisibilityChanged"]);
        showPopUp({
            left,
            top,
            autoHide: true,
            hideOnWheel: !maxHeight,
            onVisibilityChanged,
            content: (_jsx(Menu, Object.assign({}, props, { maxHeight: maxHeight, onSelect: () => showPopUp(false), getHeader: !header
                    ? undefined
                    : () => (_jsx("div", Object.assign({ className: "flex items-center justify-between px-4 border-b" }, { children: _jsx("div", Object.assign({ className: "font-semibold " }, { children: header })) }))) }))),
        });
    }
}
