import { curveToBezier } from "points-on-curve/lib/curve-to-bezier";
import { XY } from "./geometry";
import abs from "abs-svg-path";
import parse from "parse-svg-path";
import curvify from "curvify-svg-path";
export function toBeziersPoints(points, curveTightness = 0) {
    return curveToBezier(points, curveTightness);
}
export function bezierCount(beziers) {
    return (beziers.length - 1) / 3;
}
export function beziersSlice(beziers, segmentIdx, segmentCount = 1) {
    return beziers.slice(toIdx(segmentIdx), toIdx(segmentIdx + segmentCount) + 1);
    function toIdx(segmentIdx) {
        return segmentIdx * 3;
    }
}
export function toArray(beziers, from = 0, to) {
    to = to !== null && to !== void 0 ? to : bezierCount(beziers);
    const arr = [];
    for (let i = from; i < to; i++)
        arr.push(beziersSlice(beziers, i));
    return arr;
}
export function reverse(a) {
    return [...a].reverse();
}
export function BCollection(points, rev) {
    const beziers = toBeziersPoints(rev ? reverse(points) : points);
    const count = bezierCount(beziers);
    return {
        beziers,
        count,
        slice(idx, len) {
            return beziersSlice(beziers, rev ? count - idx - len : idx, len);
        },
    };
}
export function parseSvgPath(path) {
    return cleanConsecutiveMs(abs(parse(path)));
    function cleanConsecutiveMs(arr) {
        return arr.reduce((acc, val, idx) => {
            if (val[0] !== "M" || (idx < arr.length - 1 && arr[idx + 1][0] !== "M")) {
                acc.push(val);
            }
            return acc;
        }, []);
    }
}
export function svgPathToBeziers(path) {
    return xyToXY(curvify(parseSvgPath(path))
        .map(arr => arr.slice(1))
        .flat());
}
export function xyToXY(xys) {
    if (xys.length % 2)
        throw `Uneven array size ${xys.length}`;
    const XYs = [];
    for (let i = 0; i < xys.length; i += 2)
        XYs.push(XY(xys[i], xys[i + 1]));
    return XYs;
}
