var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { TheoState, useTheoState } from "./TheoState";
import { API } from "./api";
import { setLoading } from "../shared/components/Loading";
import { RoundButton } from "./RoundButton";
export function UpdateSignsButton() {
    const { changes, graph, checkpoint, stage } = useTheoState();
    const done = stage === "done";
    const hasChanges = React.useMemo(() => graph &&
        (graph.nodes.some(i => i.id in changes && i.value !== changes[i.id]) ||
            graph.edges.some(i => i.id in changes && i.weight !== changes[i.id])), [graph, changes]);
    return (_jsx(RoundButton, Object.assign({ onClick: () => __awaiter(this, void 0, void 0, function* () {
            try {
                setLoading("Recalculating...");
                const response = yield API.recalculate({
                    changes,
                    checkpoint,
                });
                TheoState(response);
            }
            finally {
                setLoading(false);
            }
        }), disabled: !checkpoint || !hasChanges || !done }, { children: "Recalculate" })));
}
