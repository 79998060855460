import { Position } from "reactflow";
import { calculateAngle, X, XY, Y } from "../../utils/drawing/geometry";
import { keyBy, mapValues } from "lodash";
import { Bounds } from "../../utils/drawing/Bounds";
import { interpolation } from "../../utils/interpolation";
import { axisFor, Delta, getBounds, getNodeShape } from "../graphGeo";
import intersect from "path-intersection";
import { PathBuilder } from "../../utils/drawing/PathBuilder";
import { PathWalker } from "../../utils/drawing/PathWalker";
import { getEdgePath } from "../theoGraphLayout";
function invalidTip(tip) {
    return !tip.xy || tip.xy.length < 2;
}
export default function equidistantEdgesInfoCalculatorCreator(paramsGetterCreator) {
    return function equidistantEdgesInfoCalculator(nodes, nodesById, edges) {
        const nodeSides = Object.fromEntries(nodes.map(n => {
            const b = getBounds(n);
            const p1 = XY(Bounds.x(b), Bounds.y(b));
            const p2 = XY(Bounds.xMax(b), Bounds.y(b));
            const p3 = XY(Bounds.xMax(b), Bounds.yMax(b));
            const p4 = XY(Bounds.x(b), Bounds.yMax(b));
            return [
                n.id,
                {
                    [Position.Top]: [p1, p2],
                    [Position.Right]: [p2, p3],
                    [Position.Bottom]: [p3, p4],
                    [Position.Left]: [p4, p1],
                },
            ];
        }));
        const paramsByEdgeId = calcEquidistantEdgeParams(nodes, edges);
        return edges.reduce((acc, edge) => {
            const params = paramsByEdgeId[edge.id];
            if (!params)
                return acc;
            const edgePath = getEdgePath(params, nodes);
            const [labelX, labelY] = PathWalker(edgePath).at(0.5);
            acc[edge.id] = Object.assign(Object.assign({}, params), { edgePath,
                labelX,
                labelY });
            return acc;
        }, {});
        function calcEquidistantEdgeParams(nodes, edges) {
            const edgeParamsGetter = paramsGetterCreator(nodes, edges);
            const nodesById = keyBy(nodes, "id");
            const bboxes = mapValues(nodesById, getBounds);
            const edgeParams = Object.fromEntries(edges.map(e => [
                e.id,
                edgeParamsGetter(nodesById[e.source], nodesById[e.target]),
            ]));
            // group edges by node and side
            const edgeTips = equallyDistributeTips(edgeTipsByNodeAndSide(edges));
            return Object.fromEntries(edges
                .map(e => {
                const p = edgeParams[e.id];
                const [s, t] = [
                    ...edgeTips[e.source][p.sourcePosition].filter(tip => tip.edgeId === e.id),
                    ...edgeTips[e.target][p.targetPosition].filter(tip => tip.edgeId === e.id),
                ].sort((a, b) => a.tipIsTarget - b.tipIsTarget);
                if (invalidTip(s) || invalidTip(t))
                    return [];
                return [
                    e.id,
                    Object.assign(Object.assign({}, p), { sourceX: X(s.xy), sourceY: Y(s.xy), targetX: X(t.xy), targetY: Y(t.xy) }),
                ];
            })
                .filter(e => e.length > 0));
            function edgeTipsByNodeAndSide(edges) {
                return edges.reduce((acc, e) => {
                    const params = edgeParams[e.id];
                    acc[e.source][params.sourcePosition].push({
                        isSource: true,
                        pos: params.sourcePosition,
                        edgeId: e.id,
                        tip: e.source,
                        counterTip: e.target,
                        counterPos: params.targetPosition,
                        tipIsTarget: 0,
                        xy: XY(params.sourceX, params.sourceY),
                        counterXY: XY(params.targetX, params.targetY),
                    });
                    acc[e.target][params.targetPosition].push({
                        isSource: false,
                        pos: params.targetPosition,
                        edgeId: e.id,
                        tip: e.target,
                        counterTip: e.source,
                        counterPos: params.sourcePosition,
                        tipIsTarget: 1,
                        xy: XY(params.targetX, params.targetY),
                        counterXY: XY(params.sourceX, params.sourceY),
                    });
                    return acc;
                }, mapValues(nodesById, () => mapValues(Delta, () => [])));
            }
            function sortTips(nodeTips) {
                return mapValues(nodeTips, sides => mapValues(sides, (tips, side) => {
                    function calcAngle(tip) {
                        const [p1, p2] = nodeSides[tip.tip][side];
                        return calculateAngle(p1, p2, tip.counterXY);
                    }
                    return tips.sort((a, b) => {
                        const sign = side === Position.Bottom || side === Position.Left ? -1 : 1;
                        return (sign * (calcAngle(b) - calcAngle(a)) ||
                            a.edgeId.localeCompare(b.edgeId));
                    });
                    // return tips.sort((a, b) => {
                    //    const axis = axisFor(<Position>side)
                    //    const sign =
                    //      side === Position.Top || side === Position.Left ? -1 : 1
                    //    const aXY = a.counterXY
                    //    const bXY = b.counterXY
                    //    const delta = axis(...aXY) - axis(...bXY)
                    //    const oDelta = sign * (axis.o(...aXY) - axis.o(...bXY))
                    //    const [d1, d2] = axis.xy(delta, oDelta)
                    //    return d1 || d2 || a.edgeId.localeCompare(b.edgeId)
                    //  })
                }));
            }
            function equallyDistributeTips(nodeTips) {
                return mapValues(sortTips(nodeTips), (sides, nodeId) => mapValues(sides, (tips, side) => {
                    const count = tips.length;
                    if (!count)
                        return [];
                    const bbox = bboxes[nodeId];
                    const center = Bounds.center(bbox);
                    const axis = axisFor(side);
                    const len = axis(Bounds.w(bbox), Bounds.h(bbox));
                    const gap = len / (count + 1);
                    const inter = interpolation(0, gap, count - 1, len - gap);
                    const node = nodesById[nodeId];
                    return tips.map((tip, idx) => {
                        const offset = axis(Bounds.x(bbox), Bounds.y(bbox)) + inter(idx);
                        const segment = PathBuilder()
                            .M(...axis.xy(offset, axis.o(center)))
                            .L(...axis.xy(offset, axis.o(center) +
                            axis.o(Delta[side]) *
                                axis.o(Bounds.w(bbox), Bounds.h(bbox))))
                            .path();
                        const xy = intersect(getNodeShape(node, true), segment).map(({ x, y }) => XY(x, y))[0];
                        return Object.assign(Object.assign({}, tip), { xy });
                    });
                }));
            }
        }
    };
}
