import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useState } from "react";
import colors from "../../assets/colors";
import themes from "../../assets/themes";
const DEFAULT_THEME = "light";
const defaultThemeState = {
    active: DEFAULT_THEME,
    colors: Object.assign(Object.assign({}, colors), themes[DEFAULT_THEME]),
};
const ThemeContext = createContext(Object.assign(Object.assign({}, defaultThemeState), { setTheme: () => {
        /* noop */
    } }));
export function useTheme() {
    return useContext(ThemeContext);
}
const THEME_KEY = "THEME";
export function setThemePreference(theme) {
    window.localStorage.setItem(THEME_KEY, theme);
}
export function getThemePreference() {
    const colorTheme = window.localStorage.getItem(THEME_KEY);
    if (!colorTheme) {
        setThemePreference(DEFAULT_THEME);
        return DEFAULT_THEME;
    }
    return colorTheme;
}
export function ThemeProvider({ children }) {
    const colorTheme = getThemePreference();
    const [theme, setThemeState] = useState(defaultThemeState);
    const setTheme = (theme) => {
        const targetTheme = theme || DEFAULT_THEME;
        if (themes[targetTheme]) {
            document.body.className = targetTheme;
            setThemePreference(targetTheme);
            setThemeState({
                active: targetTheme,
                colors: Object.assign(Object.assign({}, colors), themes[targetTheme]),
            });
        }
        else {
            console.error("Theme not found");
        }
    };
    React.useEffect(() => {
        if (themes[colorTheme] && colorTheme !== theme.active) {
            setTheme(colorTheme);
        }
        if (!document.body.className || document.body.className !== colorTheme) {
            document.body.className = colorTheme;
        }
    });
    return (_jsx(ThemeContext.Provider, Object.assign({ value: Object.assign(Object.assign({}, theme), { setTheme }) }, { children: children })));
}
