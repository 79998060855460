var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HTTP } from "../utils/http";
import { getConfig } from "../utils/serverConfig";
import { toFEGraphResponse } from "./theoUtil";
var Capability;
(function (Capability) {
    Capability["add_edge"] = "/api/v1/add_edge";
    Capability["add_node"] = "/api/v1/add_node";
    Capability["combine_nodes"] = "/api/v1/combine_nodes";
    Capability["delete_elements"] = "/api/v1/delete_elements";
    Capability["delete_session"] = "/api/v1/delete_session";
    Capability["domains"] = "/api/v1/domains";
    Capability["examples"] = "/api/v1/examples";
    Capability["generate_graph"] = "/api/v1/generate_graph";
    Capability["generation_modes"] = "/api/v1/generation_modes";
    Capability["get_analysis"] = "/api/v1/get_analysis";
    Capability["get_user_directory"] = "/api/v1/get_user_directory";
    Capability["models"] = "/api/v1/models";
    Capability["recalculate"] = "/api/v1/recalculate";
    Capability["restore_session"] = "/api/v1/restore_session";
    Capability["save"] = "/api/v1/save";
})(Capability || (Capability = {}));
function createApi() {
    const http = HTTP((c) => ({ description: c }));
    function fetch(capability, params = {}, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return http.fetchData(getServerUrl(), capability, params, config);
        });
    }
    return {
        add_edge(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return toFEGraphResponse(yield fetch(Capability.add_edge, params, { noCache: true }));
            });
        },
        add_node(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return toFEGraphResponse(yield fetch(Capability.add_node, params, { noCache: true }));
            });
        },
        combine_nodes(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return toFEGraphResponse(yield fetch(Capability.combine_nodes, params, { noCache: true }));
            });
        },
        delete_elements(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return toFEGraphResponse(yield fetch(Capability.delete_elements, params, { noCache: true }));
            });
        },
        delete_session(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield fetch(Capability.delete_session, params, { noCache: true });
            });
        },
        domains() {
            return __awaiter(this, void 0, void 0, function* () {
                return yield fetch(Capability.domains, {});
            });
        },
        examples(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield fetch(Capability.examples, params);
            });
        },
        generate_graph(params, config) {
            return __awaiter(this, void 0, void 0, function* () {
                return toFEGraphResponse(yield fetch(Capability.generate_graph, params, config));
            });
        },
        get_analysis(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return toFEGraphResponse(yield fetch(Capability.get_analysis, params, { noCache: true }));
            });
        },
        get_user_directory() {
            return __awaiter(this, void 0, void 0, function* () {
                return yield fetch(Capability.get_user_directory, {}, { noCache: true });
            });
        },
        models() {
            return __awaiter(this, void 0, void 0, function* () {
                return yield fetch(Capability.models);
            });
        },
        generation_modes() {
            return __awaiter(this, void 0, void 0, function* () {
                return yield fetch(Capability.generation_modes);
            });
        },
        recalculate(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return toFEGraphResponse(yield fetch(Capability.recalculate, params, { noCache: true }));
            });
        },
        restore_session(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return toFEGraphResponse(yield fetch(Capability.restore_session, params));
            });
        },
        save(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return toFEGraphResponse(yield fetch(Capability.save, params, { noCache: true }));
            });
        },
    };
}
export const API = createApi();
export function getServerUrl() {
    return getConfig("THEO_SERVER_URL");
}
