import dagre from "dagre";
import { groupBy, keyBy, memoize, partition } from "lodash";
const { alg, Graph } = dagre.graphlib;
export function GraphAnalysisCreator({ nodeId, sourceId, targetId, }) {
    function GraphAnalysis(nodes, edges) {
        const graph = memoize(() => {
            const graph = new Graph({ directed: true });
            nodes.forEach(node => {
                graph.setNode(nodeId(node), node);
            });
            edges.forEach(e => graph.node(sourceId(e)) &&
                graph.node(targetId(e)) &&
                graph.setEdge(sourceId(e), targetId(e), e));
            return graph;
        });
        const nodesById = memoize(() => keyBy(nodes, nodeId));
        const components = memoize(() => alg.components(graph()));
        const edgesByNodeId = memoize(() => GraphAnalysis.edgesByNodeId(edges));
        function neighbors(id) {
            return (edgesByNodeId()[id] || []).map(e => nodesById()[sourceId(e) === id ? targetId(e) : sourceId(e)]);
        }
        const nodeComponent = memoize(() => Object.fromEntries(components()
            .map((comp, idx) => comp.map(id => [id, idx]))
            .flat()));
        const [validEdges, invalidEdges] = partition(edges, e => nodesById()[sourceId(e)] && nodesById()[targetId(e)]);
        edges = validEdges.length === edges.length ? edges : validEdges;
        return {
            nodes,
            edges,
            invalidEdges,
            graph,
            nodesById,
            components,
            componentIdx(id) {
                return nodeComponent()[id];
            },
            edgesByNodeId,
            edgesForNode(id) {
                return edgesByNodeId()[id] || [];
            },
            neighbors,
            node(id) {
                return nodesById()[id];
            },
            getEdges(nodeId) {
                return edgesByNodeId()[nodeId] || [];
            },
            neighborIds(id) {
                return new Set(neighbors(id).map(nodeId));
            },
            nodeAndNeighborIds(id) {
                return [id, ...neighbors(id).map(nodeId)];
            },
        };
    }
    GraphAnalysis.edgesByNodeId = (edges) => {
        const s = groupBy(edges, sourceId);
        const t = groupBy(edges, targetId);
        return Object.fromEntries(Object.entries(s)
            .map(([k, es]) => [k, !t[k] ? es : [...es, ...t[k]]])
            .concat(Object.keys(t)
            .filter(k => !s[k])
            .map(k => [k, t[k]])));
    };
    return GraphAnalysis;
}
