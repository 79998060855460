var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Button } from "antd";
import { classes } from "../../../utils/classes";
import { TABS_BG } from "../../../cora/components/lf";
import { Tooltip } from "../Tooltip";
export function ActionButton(_a) {
    var { invert, className, roundedClass = "rounded-lg", sizeClass = "w-6 h-6", tooltip } = _a, props = __rest(_a, ["invert", "className", "roundedClass", "sizeClass", "tooltip"]);
    return (_jsx(Tooltip, Object.assign({ content: tooltip }, { children: _jsx(Button, Object.assign({ ghost: !invert, className: classes("ActionButton", "flex justify-center items-center p-0", roundedClass, sizeClass, props.disabled && "cursor-not-allowed", className) }, props)) })));
}
export function BrandButton(_a) {
    var { className, roundedClass = "rounded", paddingClass = "px-5 py-1", disabled, type } = _a, props = __rest(_a, ["className", "roundedClass", "paddingClass", "disabled", "type"]);
    return (_jsx(Button, Object.assign({ type: type, ghost: !type, className: classes("BrandButton", "flex justify-center items-center", className, paddingClass, roundedClass, disabled && "cursor-not-allowed"), disabled: disabled }, props)));
}
export function ButtonWrapper(_a) {
    var { className, onClick, children } = _a, props = __rest(_a, ["className", "onClick", "children"]);
    return (_jsx(DivButton, Object.assign({ className: classes("flex-1 flex space-x-1 bg-transparent py-2", className), type: "text", onMouseDown: onClick }, props, { children: children })));
}
export function DivButton(_a) {
    var { className, disabled, onMouseDown, onClick } = _a, props = __rest(_a, ["className", "disabled", "onMouseDown", "onClick"]);
    return (_jsx("div", Object.assign({ className: classes("select-none", disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer", className), onMouseDown: disabled ? undefined : onMouseDown, onClick: disabled || !onClick
            ? undefined
            : e => {
                e.preventDefault();
                onClick(e);
            } }, props)));
}
export function TinyButton(_a) {
    var { className, size = 16, bgClassName = "bg-brand-100" } = _a, props = __rest(_a, ["className", "size", "bgClassName"]);
    return (_jsx(DivButton, Object.assign({ className: classes("flex justify-center items-center rounded-md border", bgClassName, className), style: { fontSize: size, width: size + 6, height: size + 6 } }, props)));
}
const BUTTON_CLASSES = "flex items-center py-1 px-2 space-x-2 ";
export function TabButton(_a) {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx(DivButton, Object.assign({ className: classes(BUTTON_CLASSES, TABS_BG, className) }, props)));
}
export function LargeRoundButton(_a) {
    var { className, style } = _a, props = __rest(_a, ["className", "style"]);
    return (_jsx(DivButton, Object.assign({}, props, { className: classes("flex justify-center items-center cursor-pointer", className), style: Object.assign({ width: 48, height: 48, bottom: 24, right: 24, borderRadius: "50%" }, style) })));
}
export function PillButton(_a) {
    var { className, style, size = 23 } = _a, props = __rest(_a, ["className", "style", "size"]);
    return (_jsx(DivButton, Object.assign({}, props, { className: classes("flex justify-center items-center cursor-pointer space-x-2 whitespace-nowrap", className), style: Object.assign({ height: size, borderRadius: size / 2, paddingLeft: 4 + size / 2, paddingRight: 4 + size / 2 }, style) })));
}
export function SimpleButton(_a) {
    var { className, style, size = 23 } = _a, props = __rest(_a, ["className", "style", "size"]);
    return (_jsx(DivButton, Object.assign({ className: classes("border px-1 text-gray-700 border-gray-300 text-sm flex items-center rounded hover:bg-gray-100", className) }, props)));
}
export function HeaderButton(_a) {
    var { disabled, className } = _a, props = __rest(_a, ["disabled", "className"]);
    return (_jsx(DivButton, Object.assign({ className: classes("border rounded-lg border-neutral-800 flex items-center justify-center border border-neutral-800 text-neutral-500", disabled
            ? "cursor-not-allowed opacity-50"
            : "hover:bg-accent-600 hover:text-white hover:border-accent-600", className), disabled: disabled, style: {
            width: 28,
            height: 28,
            // borderRadius: 8,
        } }, props)));
}
