import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { classes } from "../../utils/classes";
import { getShortcutDisplayText } from "../../utils/keyboard";
import { Right } from "../components/Icons";
import { Tooltip } from "../components/Tooltip";
import { getActionIcon } from "./ActionParams";
export function BasicMenuRow(props) {
    const { disabled, className, hasIcons, captionClassName, caption, onMouseDown, shortcut, isSubMenu, desc, } = props;
    const icon = getActionIcon(props);
    const row = (_jsxs("div", Object.assign({ className: classes("flex-1 flex px-2 py-1 items-center", disabled
            ? "cursor-not-allowed opacity-50"
            : "cursor-pointer hover:bg-hover_active", className), onMouseDown: onMouseDown, style: { minWidth: 192 } }, { children: [hasIcons && (_jsx("div", Object.assign({ className: "flex items-center", style: { marginRight: icon ? 8 : 24 } }, { children: icon }))), _jsx("div", Object.assign({ className: classes("flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis", captionClassName) }, { children: caption })), (shortcut || isSubMenu) && (_jsx("div", Object.assign({ className: classes("font-bold ml-1 flex justify-end ") }, { children: isSubMenu ? (_jsx(Right, {})) : (_jsx("span", Object.assign({ className: "bg-gray-200  px-1 py-0.5 rounded opacity-30" }, { children: getShortcutDisplayText(shortcut) }))) })))] })));
    const disabledMsg = disabled && disabled !== true ? disabled : null;
    return disabledMsg || desc ? (_jsx(Tooltip, Object.assign({ content: disabled || desc, placement: "right-end" }, { children: row }))) : (row);
}
