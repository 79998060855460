import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useGlobalConfig } from "./GlobalConfig";
import { updateTheoSession, useTheoSession } from "./TheoSession";
import { DropDownMenu } from "../shared/components/DropDownMenu";
import { TabButton } from "../shared/components/Tabs";
import { Down } from "../shared/components/Icons";
import { EMPTY_STATE, TheoState } from "./TheoState";
export function DomainPicker() {
    const { domains } = useGlobalConfig();
    const domain = useCurrentDomain();
    return (_jsx(DropDownMenu, Object.assign({ title: "Domains", hasClose: true, entries: domains.map(d => ({
            caption: d,
            action() {
                updateTheoSession({ domain: d });
                TheoState(EMPTY_STATE);
            },
            selected: d === domain,
        })) }, { children: _jsx(TabButton, { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx("div", { children: "domain:" }), _jsx("div", { children: domain }), _jsx(Down, { className: "text-xs" })] })) }) })));
}
export function useCurrentDomain() {
    const { domains } = useGlobalConfig();
    const { domain } = useTheoSession();
    return domains.includes(domain) ? domain : domains[0];
}
