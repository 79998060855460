var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback } from "react";
import { debounce, memoize } from "lodash";
import { Emitter } from "./Emitter";
import { merge } from "./collections";
import { equals } from "./util";
export function useMemoIfEqual(value) {
    const ref = React.useRef(value);
    const equal = equals(ref.current, value);
    React.useLayoutEffect(() => {
        if (!equal) {
            ref.current = value;
        }
    }, [equal, value]);
    return equal ? ref.current : value;
}
export function usePreviousValue(value) {
    const ref = React.useRef(undefined);
    React.useLayoutEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
export function useValueChanged(value) {
    const ref = React.useRef(value);
    const changed = !equals(ref.current, value);
    React.useLayoutEffect(() => {
        ref.current = value;
    });
    return changed;
}
export function useUpdateIfDifferent(value, transformer, updater) {
    React.useEffect(() => {
        const t = transformer(value);
        updater(current => (equals(current, t) ? current : t));
    }, [value, transformer, updater]);
}
export function useEffectIfDifferent(effect, dependencies, triggerIfAnyDifferent, triggerOnMount = false) {
    const prior = React.useRef(triggerIfAnyDifferent);
    const forceTrigger = React.useRef(triggerOnMount);
    React.useEffect(() => {
        const firstTime = forceTrigger.current;
        if (firstTime || !equals(prior.current, triggerIfAnyDifferent)) {
            forceTrigger.current = false;
            effect(firstTime);
        }
        prior.current = triggerIfAnyDifferent;
    }, dependencies.concat(triggerIfAnyDifferent));
}
export function useDiffState(initial) {
    const [value, setValue] = React.useState(initial);
    const setIfDifferent = React.useCallback((v) => setValue(current => {
        return equals(current, v) ? current : v;
    }), [setValue]);
    return [value, setIfDifferent];
}
let instanceId = 0;
export function useInstanceId() {
    return React.useMemo(() => `INSTANCE_ID_${++instanceId}`, []);
}
const globals = {};
export function setGlobal(id, value) {
    var _a;
    const emitter = ((_a = globals[id]) === null || _a === void 0 ? void 0 : _a.emitter) || Emitter();
    globals[id] = { value, emitter };
    emitter.emit(value);
}
export function getGlobal(id) {
    var _a;
    return (_a = globals[id]) === null || _a === void 0 ? void 0 : _a.value;
}
export function useGlobal(id, initialState) {
    const [state, setState] = React.useState(() => {
        const entry = globals[id];
        if (entry) {
            return entry.value;
        }
        else {
            const value = typeof initialState === "function"
                ? initialState()
                : initialState;
            globals[id] = { value, emitter: Emitter() };
        }
    });
    React.useEffect(() => {
        return globals[id].emitter.on((s) => setState(s));
    }, []);
    const set = React.useCallback((value) => {
        setState(value);
        setGlobal(id, value);
    }, [setState]);
    return [state, set];
}
export function useOnRefEffect(effect) {
    const destroy = React.useRef();
    React.useEffect(() => {
        return () => {
            if (destroy.current)
                destroy.current();
        };
    }, []);
    return React.useCallback((node) => {
        if (destroy.current)
            destroy.current();
        destroy.current = node && effect(node);
    }, [effect]);
}
export function useGlobalKeyDown(callback, key, enabled = false) {
    React.useEffect(() => {
        if (!enabled)
            return;
        const keyListener = (e) => {
            if (e.key === key) {
                callback(e);
            }
        };
        document.addEventListener("keydown", keyListener);
        return () => {
            document.removeEventListener("keydown", keyListener);
        };
    }, [callback, enabled]);
}
export function useGlobalEscape(callback, enabled = false) {
    useGlobalKeyDown(callback, "Escape", enabled);
}
export function useCounter() {
    const [count, setCount] = React.useState(0);
    const next = React.useCallback(() => setCount(c => c + 1), []);
    return [count, next];
}
export function useIsMounting() {
    const mounting = React.useRef(true);
    React.useEffect(() => {
        mounting.current = false;
    }, []);
    return mounting.current;
}
export function useLoad() {
    const [loading, setLoading] = React.useState(0);
    const load = React.useCallback((task, afterLoading) => __awaiter(this, void 0, void 0, function* () {
        try {
            setLoading(l => l + 1);
            yield task();
        }
        finally {
            setLoading(l => l - 1);
            afterLoading === null || afterLoading === void 0 ? void 0 : afterLoading();
        }
    }), []);
    return [loading > 0, load];
}
export function useUpdater(setValue) {
    return React.useCallback((updateOrFn) => {
        setValue(value => {
            return merge(value, updateOrFn, true);
        });
    }, []);
}
export function useParams(initial) {
    const [state, setState] = React.useState(initial);
    return [state, useUpdater(setState)];
}
function useLocalStorageRetrieve(key, initial) {
    return useMemoIfEqual(React.useMemo(() => {
        const stored = !key ? null : window.localStorage.getItem(key);
        return stored !== null ? JSON.parse(stored) : initial;
    }, [key]));
}
function useLocalStorageStore(key, value, inhibit = false) {
    React.useEffect(() => {
        if (!inhibit && key) {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
    }, [value, key, inhibit]);
}
function useLocalStorageHelper(hook, key, initial) {
    const stored = useLocalStorageRetrieve(key, initial);
    const [value, update] = hook(stored);
    React.useEffect(() => {
        update(stored);
    }, [stored]);
    useLocalStorageStore(key, value);
    return [value, update];
}
export function useLocalStorageParams(key, initial) {
    return useLocalStorageHelper(useParams, key, initial);
}
export function useLocalStorageState(key, initial) {
    return useLocalStorageHelper(React.useState, key, initial);
}
export function useTriggerOnChange(dependencies) {
    const last = React.useRef();
    const triggers = React.useRef([]);
    React.useEffect(() => {
        const current = triggers.current;
        triggers.current = [];
        current.forEach(trigger => trigger());
    });
    return (trigger, replace = true) => {
        if (replace) {
            triggers.current = [trigger];
        }
        else {
            triggers.current.push(trigger);
        }
    };
}
export function useCache() {
    const cache = React.useRef({});
    return {
        get(key) {
            return cache.current[key];
        },
        add(key, value) {
            return (cache.current[key] = value);
        },
        evict(key) {
            delete cache.current[key];
        },
        clear() {
            cache.current = {};
        },
    };
}
export function useInhibitEffect(effect, defaultInhibited, deps) {
    const inhibited = React.useRef(defaultInhibited);
    React.useEffect(inhibited.current
        ? () => {
            inhibited.current = defaultInhibited;
        }
        : effect, deps);
    return () => {
        inhibited.current = !defaultInhibited;
    };
}
export function useUniqId(idSupplier) {
    return React.useMemo(idSupplier, []);
}
export function useIsOverflow(element, vertical = true) {
    const [isOverflow, setIsOverflow] = React.useState();
    useOnElementResize(element, () => {
        setIsOverflow(vertical
            ? element.scrollHeight > element.clientHeight
            : element.scrollWidth > element.clientWidth);
    });
    return isOverflow;
}
export function useHorizontalScroll() {
    const elRef = React.useRef();
    React.useEffect(() => {
        const el = elRef.current;
        if (el) {
            const onWheel = (e) => {
                if (e.deltaY == 0)
                    return;
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + e.deltaY,
                    behavior: "smooth",
                });
            };
            el.addEventListener("wheel", onWheel);
            return () => el.removeEventListener("wheel", onWheel);
        }
    }, []);
    return elRef;
}
export const DEFAULT_DEBOUNCE_MS = 300;
export function useDebouncedValue(value, delayMs = DEFAULT_DEBOUNCE_MS) {
    const [delayed, setDelayed] = React.useState(value);
    const update = React.useCallback(debounce((value) => setDelayed(value), delayMs), [delayMs]);
    React.useEffect(() => update(value), [value, update]);
    return delayed;
}
export function useInitializer(initializer) {
    const [ready, setReady] = React.useState(false);
    React.useEffect(() => {
        initializer()
            .then(() => setReady(true))
            .catch(e => e);
    }, []);
    return ready;
}
export function useTriggerRefresh() {
    const [_, setCount] = React.useState(0);
    return useCallback(() => setCount(c => c + 1), []);
}
export function useChangeCounter(value) {
    const ref = React.useRef(value);
    const count = React.useRef(0);
    React.useLayoutEffect(() => {
        ref.current = value;
        count.current++;
    }, [ref.current, value]);
    function increase() {
        count.current++;
    }
    return [count.current, increase];
}
export function useLocalState(value) {
    const local = React.useState(value);
    useEffectIfDifferent(() => local[1](value), [], [value]);
    return local;
}
export function useOnElementResize(element, onResize, dependencies = []) {
    React.useLayoutEffect(() => {
        if (!element)
            return;
        const resizeObserver = new ResizeObserver(debounce((entries) => {
            var _a;
            onResize((_a = entries[0]) === null || _a === void 0 ? void 0 : _a.contentRect);
        }, 100));
        resizeObserver.observe(element);
        return () => resizeObserver.disconnect();
    }, [element, ...dependencies]);
}
export function useOnUnmount(exec, deps = []) {
    useEffectIfDifferent(() => exec, deps, []);
}
export function useMemoizedProvider(provider, params) {
    return React.useMemo(() => memoize(() => provider(params)), [useValueChanged(params)]);
}
export function useMemoizedValue(supplier) {
    const ref = React.useRef();
    function getter() {
        if (ref.current === undefined) {
            ref.current = supplier();
        }
        return ref.current;
    }
    getter.reset = () => {
        ref.current = undefined;
    };
    return getter;
}
