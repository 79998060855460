import "./graphAnimator.less";
import { edgeLabelId } from "./TheoEdge";
import { classes } from "../utils/classes";
import { keyBy, mapValues } from "lodash";
import { XY } from "../utils/drawing/geometry";
import { interpolation } from "../utils/interpolation";
import { equals } from "../utils/util";
import { EdgeInfo } from "./edgeInfo/EdgeInfo";
import { getNodeBounds, getViewportForBounds, setPosition } from "./graphGeo";
import { delta } from "../utils/collections";
export function graphAnimator(state, dUpdater, ne, config = {}, updateNodeInternals) {
    const { fadeOutDurationMs = 1500, fadeInDurationMs = 1500, moveDurationMs = 1500, panTo, } = config;
    const { added, removed } = delta(state.nodes.concat(state.edges), ne.nodes.concat(ne.edges));
    const bounds = getNodeBounds(...ne.nodes);
    fade(state, removed, "fadeout", fadeOutDurationMs, () => {
        const updated = updateClass(ne, added, true, "hidden");
        moveNodes(updated, mapValues(keyBy(updated.nodes, "id"), n => { var _a; return ((_a = state.nodesById[n.id]) === null || _a === void 0 ? void 0 : _a.position) || n.position; }), moveDurationMs, ne => {
            const updated = updateClass(ne, added, false, "hidden");
            fade(updated, added, "fadein", fadeInDurationMs, updateNodeInternals);
            dUpdater(updated);
        });
    });
    function fade(ne, toFade, className, durationMs, afterFade) {
        if (!toFade.size) {
            afterFade === null || afterFade === void 0 ? void 0 : afterFade(ne);
            return;
        }
        setFadeTime(durationMs);
        setTimeout(() => {
            EdgeInfo.reset();
            ne = updateClass(ne, toFade, false, className);
            dUpdater(ne);
            afterFade === null || afterFade === void 0 ? void 0 : afterFade(ne);
        }, durationMs);
        EdgeInfo.reset();
        dUpdater(() => {
            return updateClass(ne, toFade, true, className);
        });
    }
    function updateClass({ nodes, edges }, ids, add, ...classNames) {
        const op = add ? classes.add : classes.remove;
        return {
            nodes: nodes.map(n => !ids.has(n.id)
                ? n
                : Object.assign(Object.assign({}, n), { className: op(n.className, ...classNames) })),
            edges: edges.map(n => {
                var _a;
                if (!ids.has(n.id))
                    return n;
                const label = (_a = document.getElementById(edgeLabelId(n.id))) === null || _a === void 0 ? void 0 : _a.classList;
                classNames.forEach(className => add ? label === null || label === void 0 ? void 0 : label.add(className) : label === null || label === void 0 ? void 0 : label.remove(className));
                return Object.assign(Object.assign({}, n), { className: op(n.className, ...classNames) });
            }),
        };
    }
    function moveNodes(ne, org, timeMs, afterFade) {
        if (ne.nodes.every(n => equals(n.position, org[n.id]))) {
            afterFade === null || afterFade === void 0 ? void 0 : afterFade(ne);
            return;
        }
        const inter = ne.nodes.reduce((acc, n) => {
            const tx = interpolation(0, org[n.id].x, timeMs, n.position.x);
            const ty = interpolation(0, org[n.id].y, timeMs, n.position.y);
            acc[n.id] = t => XY(tx(t), ty(t));
            return acc;
        }, {});
        const start = Date.now();
        function updatePosition(firstTime) {
            requestAnimationFrame(() => {
                const t = Math.min(Date.now() - start, timeMs);
                let updated = ne;
                EdgeInfo.reset();
                if (firstTime) {
                    updated = positionUpdater(ne, t);
                    dUpdater(updated);
                    updateNodeInternals === null || updateNodeInternals === void 0 ? void 0 : updateNodeInternals();
                    if (panTo) {
                        const { width, height, minZoom, maxZoom, flow } = panTo;
                        EdgeInfo.reset();
                        flow.setViewport(getViewportForBounds(bounds, width, height, minZoom, maxZoom, 0.1), {
                            duration: timeMs,
                        });
                    }
                }
                else {
                    dUpdater(ne => {
                        updated = positionUpdater(ne, t);
                        return updated;
                    });
                }
                if (t < timeMs) {
                    updatePosition(false);
                }
                else {
                    afterFade === null || afterFade === void 0 ? void 0 : afterFade(updated);
                }
            });
            function positionUpdater({ nodes, edges }, t) {
                return {
                    nodes: nodes.map(n => {
                        var _a;
                        const pos = (_a = inter[n.id]) === null || _a === void 0 ? void 0 : _a.call(inter, t);
                        if (!pos) {
                            console.log("no pos for", n.id);
                        }
                        return pos ? setPosition(n, ...pos) : n;
                    }),
                    edges,
                };
            }
        }
        updatePosition(true);
    }
    function setFadeTime(timeMs) {
        document.documentElement.style.setProperty("--fadeTime", `${timeMs}ms`);
    }
}
