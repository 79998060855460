import { Bezier } from "bezier-js";
import { atanDeg, sign, XY, zeroTo360 } from "./geometry";
import { lineal, range } from "../iterators";
import { bezierCount, beziersSlice, svgPathToBeziers, toBeziersPoints, } from "./bezier";
import { PathBuilder } from "./PathBuilder";
import { X, Y } from "./Axis";
import { Bounds } from "./Bounds";
import fitCurve from "fit-curve";
import simplify from "simplify-path";
import { binarySearch } from "../collections";
import { QTree } from "../QTree";
export function PolyBezier(segments) {
    const length = segments.reduce((acc, s) => acc + s.length(), 0);
    const offsets = segments.reduce((acc, s, idx) => {
        idx < segments.length - 1 && acc.push(acc.at(-1) + s.length() / length);
        return acc;
    }, [0]);
    let bounds;
    const self = {
        segments,
        length() {
            return length;
        },
        offset(idx) {
            return offsets[idx];
        },
        at(len) {
            len = normalizeLen(len);
            const [idx, l] = polyLenToSegLen(len);
            return XY(segments[idx].get(l));
        },
        toLen(dist) {
            return !length ? 0 : dist / length;
        },
        tan(len, dx = 0.001) {
            const p1 = self.at(Math.max(0, len - dx * 0.5));
            const p2 = self.at(Math.min(1, len + dx * 0.5));
            return atanDeg(p2[1] - p1[1], p2[0] - p1[0]);
        },
        normal(len, dir, dx = 0.001) {
            const tan = self.tan(len, dx);
            return zeroTo360(tan + 90);
        },
        intersects(other) {
            const q = QTree(range(other.segments.length).map(idx => idx), idx => bezierBounds(other.segments[idx]));
            return segments
                .reduce((acc, segment, idx) => {
                Array.from(q.getItemsWithin(bezierBounds(segment))).forEach(oIdx => {
                    segment.intersects(other.segments[oIdx], 0.01).forEach(inter => {
                        const [len, oLen] = String(inter)
                            .split("/")
                            .map(v => Number(v));
                        acc.push(XY(self.offset(idx) + (len * segment.length()) / self.length(), other.offset(oIdx) +
                            (oLen * other.segments[oIdx].length()) / other.length()));
                    });
                }, []);
                return acc;
            }, [])
                .sort((a, b) => a[0] - b[0]);
        },
        project(x, y) {
            return self.segments
                .map(s => s.project({ x, y }))
                .map(({ x, y, d }) => ({ xy: XY(x, y), dist: d }))
                .sort((a, b) => a.dist - b.dist);
        },
        split(...lens) {
            if (!lens.length)
                return [self];
            return Array.from(new Set([0, ...lens.map(normalizeLen), 1]))
                .sort()
                .reduce((acc, len, idx, lens) => {
                if (idx) {
                    const [fIdx, fLen] = polyLenToSegLen(lens[idx - 1]);
                    const [tIdx, tLen] = polyLenToSegLen(lens[idx]);
                    if (fIdx === tIdx) {
                        acc.push(PolyBezier([segments[fIdx].split(fLen, tLen)]));
                    }
                    else {
                        const { right } = segments[fIdx].split(fLen);
                        const { left } = segments[tIdx].split(tLen);
                        acc.push(PolyBezier([right, ...segments.slice(fIdx + 1, tIdx), left]));
                    }
                }
                return acc;
            }, []);
        },
        bounds() {
            if (!bounds) {
                bounds = segments.reduce((acc, s) => {
                    const b = bezierBounds(s);
                    return !acc ? b : Bounds.union(acc, b);
                }, undefined);
            }
            return bounds;
        },
        iterLens(minSteps = 10, maxSteps = 10) {
            return self.segments
                .map((seg, idx) => {
                const arcs = seg.arcs();
                return arcs.map(a => {
                    console.log(a.r, a.r > 10 * seg.length());
                    return lineal(a.r > 10 * seg.length() ? minSteps : maxSteps, segLenToPolyLen(idx, a.interval.start), segLenToPolyLen(idx, a.interval.end)).map(len => len);
                });
            })
                .flat(2)
                .filter((n, idx, arr) => idx === 0 || arr[idx - 1] !== n);
        },
        toMCS(builder = PathBuilder()) {
            return polyToMCS(self.segments, builder);
        },
        toLCS(builder = PathBuilder()) {
            return polyToLCS(self.segments, builder);
        },
    };
    return self;
    function polyLenToSegLen(len) {
        const norm = normalizeLen(len);
        const p = binarySearch(offsets, norm, (a, b) => a - b);
        const idx = p < 0 ? -p - 2 : p;
        if (!segments[idx]) {
            throw `Invalid length ${len}`;
        }
        const segLen = ((len - offsets[idx]) * length) / segments[idx].length();
        return [idx, segLen];
    }
    function segLenToPolyLen(segIdx, len) {
        const seg = segments[segIdx];
        return offsets[segIdx] + (len * seg.length()) / length;
    }
    function normalizeLen(len) {
        const s = sign(len);
        len = Math.min(1, Math.max(0, Math.abs(len)));
        if (s < 0)
            len = 1 - len;
        return len;
    }
}
function bezierBounds(b) {
    const { x, y } = b.bbox();
    return Bounds(x.min, y.min, x.max - x.min, y.max - y.min);
}
export function toBeziers(value, curveTightness = 0, simplificationTolerance = 0) {
    if (Array.isArray(value) && (!value.length || value[0] instanceof Bezier))
        return value;
    const points = typeof value === "string"
        ? svgPathToBeziers(value)
        : toBeziersPoints((simplificationTolerance > 0
            ? simplify(value, simplificationTolerance)
            : value), curveTightness);
    return range(bezierCount(points)).reduce((acc, idx) => {
        const [p1, c1, c2, p2] = beziersSlice(points, idx);
        acc.push(toBezier(p1, c1, c2, p2));
        return acc;
    }, []);
}
export function toBezier(p1, c1, c2, p2) {
    return new Bezier(...p1, ...c1, ...c2, ...p2);
}
export function polyToMCS(poly, builder = PathBuilder()) {
    return polyToPath(poly, builder, "M");
}
export function polyToLCS(poly, builder = PathBuilder()) {
    return polyToPath(poly, builder, "L");
}
export function polyToPath(poly, builder, start) {
    var _a;
    const first = (_a = poly[0]) === null || _a === void 0 ? void 0 : _a.points;
    first && builder[start](X(first[0]), Y(first[0]));
    return poly.reduce((acc, b, idx) => {
        return builder.CS(b.points.map(p => XY(X(p), Y(p))));
    }, builder);
}
export function fitPointsToPolyBezier(points, error = 0) {
    return PolyBezier(fitCurve(points, error).map(pts => toBezier(...pts)));
}
