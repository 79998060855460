import { clamp, Position } from "reactflow";
import { calcBounds, dist, distSq, X, XY, Y } from "../utils/drawing/geometry";
import { Pen } from "../utils/drawing/Pen";
import { PathBuilder } from "../utils/drawing/PathBuilder";
import { Stage } from "./types";
import { Bounds } from "../utils/drawing/Bounds";
import intersect from "path-intersection";
import { Axis } from "../utils/drawing/Axis";
import { PolyBezier, toBeziers } from "../utils/drawing/PolyBezier";
import { updateItems } from "../utils/collections";
import { interpolation } from "../utils/interpolation";
export const NODE_BORDER_WIDTH = 3;
export const MIN_EDGE_WIDTH = 0.5;
export const MAX_EDGE_WIDTH = 6;
export function getEdgeWidth(weight = 1) {
    return widthGetter(Math.min(1, Math.max(0, weight)));
}
const widthGetter = interpolation(0, MIN_EDGE_WIDTH, 1, MAX_EDGE_WIDTH);
export function getBounds(n) {
    var _a, _b, _c, _d;
    return Bounds((_b = (_a = n.positionAbsolute) === null || _a === void 0 ? void 0 : _a.x) !== null && _b !== void 0 ? _b : n.position.x, (_d = (_c = n.positionAbsolute) === null || _c === void 0 ? void 0 : _c.y) !== null && _d !== void 0 ? _d : n.position.y, n.width || 0, n.height || 0);
}
export function getPosition(n) {
    const b = getBounds(n);
    return XY(Bounds.x(b), Bounds.y(b));
}
export function setPosition(n, x, y) {
    return Object.assign(Object.assign({}, n), { position: { x, y }, positionAbsolute: { x, y } });
}
export function setBounds(node, x, y, width, height) {
    return Object.assign(Object.assign({}, setPosition(node, x, y)), { width, height });
}
export function offsetNodes(nodes, x = 0, y = 0) {
    const [minX, minY] = getNodeBounds(...nodes);
    return updateItems(nodes, n => {
        const [nx, ny] = getPosition(n);
        return setPosition(n, nx + x - minX, ny + y - minY);
    });
}
export function getDiagonal(n) {
    return Bounds.toDiagonal(getBounds(n));
}
export function getNodeBounds(...nodes) {
    return calcBounds(nodes, n => getDiagonal(n));
}
function getNodeIntersection(source, target) {
    const [sX, sY, sW, sH] = getBounds(source);
    const [tX, tY, tW, tH] = getBounds(target);
    const w = sW / 2;
    const h = sH / 2;
    const x2 = sX + w;
    const y2 = sY + h;
    const x1 = tX + tW / 2;
    const y1 = tY + tH / 2;
    const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
    const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
    const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
    const xx3 = a * xx1;
    const yy3 = a * yy1;
    const x = w * (xx3 + yy3) + x2;
    const y = h * (-xx3 + yy3) + y2;
    return { x, y };
}
function getEdgePosition(node, { x: ix, y: iy }) {
    const [x, y, w, h] = getBounds(node);
    if (eq(x, ix))
        return Position.Left;
    if (eq(x + w, ix))
        return Position.Right;
    if (eq(y, iy))
        return Position.Top;
    return Position.Bottom;
    function eq(a, b) {
        return Math.abs(a - b) < 0.001;
    }
}
export function getClosestEdgeParams(source, target) {
    if (!source || !target) {
        throw "No source or target";
    }
    const s = getNodeIntersection(source, target);
    const t = getNodeIntersection(target, source);
    const [sX, sY, sW, sH] = getBounds(source);
    const [tX, tY, tW, tH] = getBounds(target);
    const sc = XY(sX + sW / 2, sY + sH / 2);
    const tc = XY(tX + tW / 2, tY + tH / 2);
    const segment = PathBuilder()
        .M(...sc)
        .L(...tc)
        .path();
    const sp = getInterPoint(source, sc, tc, segment);
    const tp = getInterPoint(target, tc, sc, segment);
    return {
        sourceX: X(sp),
        sourceY: Y(sp),
        targetX: X(tp),
        targetY: Y(tp),
        sourcePosition: getEdgePosition(source, s),
        targetPosition: getEdgePosition(target, t),
    };
    function getInterPoint(node, nCenter, oCenter, segment) {
        return (intersect(getNodeShape(node, true), segment)
            .map(({ x, y }) => XY(x, y))
            .sort((a, b) => distSq(...a, ...oCenter) - dist(...b, ...oCenter))[0] ||
            nCenter);
    }
}
export const Delta = {
    [Position.Top]: XY(0, -1),
    [Position.Bottom]: XY(0, 1),
    [Position.Left]: XY(-1, 0),
    [Position.Right]: XY(1, 0),
};
export function axisFor(pos) {
    return pos === Position.Left || pos === Position.Right ? Axis.Y : Axis.X;
}
export function roundedRect(x, y, width, height, borderRadius) {
    borderRadius = Math.min(borderRadius, width / 2, height / 2);
    const rx = Math.min(borderRadius, width / 2);
    const ry = Math.min(borderRadius, height / 2);
    return PathBuilder()
        .M(x + rx, y)
        .L(x + width - rx, y)
        .C(x + width - rx + rx * 0.552, y, x + width, y + ry - ry * 0.552, x + width, y + ry)
        .L(x + width, y + height - ry)
        .C(x + width, y + height - ry + ry * 0.552, x + width - rx + rx * 0.552, y + height, x + width - rx, y + height)
        .L(x + rx, y + height)
        .C(x + rx - rx * 0.552, y + height, x, y + height - ry + ry * 0.552, x, y + height - ry)
        .L(x, y + ry)
        .C(x, y + ry - ry * 0.552, x + rx - rx * 0.552, y, x + rx, y)
        .Z()
        .path();
}
export function getNodeShape(node, offset = false, size) {
    var _a;
    const bounds = getBounds(node);
    const width = (size === null || size === void 0 ? void 0 : size.width) || Bounds.w(bounds);
    const height = (size === null || size === void 0 ? void 0 : size.height) || Bounds.h(bounds);
    const intermediate = ((_a = node.data) === null || _a === void 0 ? void 0 : _a.stage) === Stage.intermediate;
    return roundedRect((offset ? Bounds.x(bounds) : 0) + NODE_BORDER_WIDTH / 2, (offset ? Bounds.y(bounds) : 0) + NODE_BORDER_WIDTH / 2, width - NODE_BORDER_WIDTH, height - NODE_BORDER_WIDTH, intermediate ? 3 : 32);
}
/**
 *       /\      -
 *      /  \     |
 *     /    \   len
 *    /  **  \   |
 *    * ang *    -
 *   |-width-|
 */
export function getArrowPaths(edgePath, edgeWidth) {
    const poly = PolyBezier(toBeziers(edgePath));
    const arrowWidth = edgeWidth + 4;
    const arrowLen = edgeWidth + 7;
    const ang = 20;
    const tipDist = poly.length() - 0.5;
    const tip = poly.at(poly.toLen(tipDist));
    const [org, ...rest] = Pen(tip)
        .pointTo(...poly.at(poly.toLen(tipDist - arrowLen)))
        .adv(arrowLen)
        .rot(90 - ang)
        .adv(arrowWidth)
        .rot(180)
        .down()
        .adv(arrowWidth)
        .rot(2 * ang)
        .adv(arrowWidth)
        .home()
        .points();
    const head = PathBuilder()
        .M(...org)
        .addLines(rest)
        .Z()
        .path();
    const headCenter = tipDist - arrowLen * 0.9;
    const body = poly.split(poly.toLen(headCenter))[0].toMCS().path();
    return [head, body];
}
export function getSpline(p) {
    var _a;
    const s = XY(p.sourceX, p.sourceY);
    const t = XY(p.targetX, p.targetY);
    const ds = Delta[p.sourcePosition];
    const dt = Delta[p.targetPosition];
    const rigidity = (_a = p.rigidity) !== null && _a !== void 0 ? _a : 40;
    const distX = Math.max(rigidity, Math.abs(X(s) - X(t)) * 0.5);
    const distY = Math.max(rigidity, Math.abs(Y(s) - Y(t)) * 0.5);
    const c1 = XY(X(s) + distX * X(ds), Y(s) + distY * Y(ds));
    const c2 = XY(X(t) + distX * X(dt), Y(t) + distY * Y(dt));
    const edgePath = PathBuilder()
        .M(...s)
        .C(...c1, ...c2, ...t)
        .path();
    return edgePath;
}
export function getViewportForBounds(bounds, width, height, minZoom, maxZoom, padding) {
    const xZoom = width / (Bounds.w(bounds) * (1 + padding));
    const yZoom = height / (Bounds.h(bounds) * (1 + padding));
    const zoom = Math.min(xZoom, yZoom);
    const clampedZoom = clamp(zoom, minZoom, maxZoom);
    const boundsCenterX = Bounds.x(bounds) + Bounds.w(bounds) / 2;
    const boundsCenterY = Bounds.y(bounds) + Bounds.h(bounds) / 2;
    const x = width / 2 - boundsCenterX * clampedZoom;
    const y = height / 2 - boundsCenterY * clampedZoom;
    return { x, y, zoom: clampedZoom };
}
