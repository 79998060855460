export function filterByKey(obj, keyFilter) {
    return Object.fromEntries(Object.entries(obj).filter(([key]) => keyFilter(key)));
}
export function filterEntries(obj, valueFilter) {
    return Object.fromEntries(Object.entries(obj).filter(([key, value]) => {
        const b = valueFilter(value, key);
        return b;
    }));
}
export function mapEntries(obj, entryFilter) {
    return Object.fromEntries(Object.entries(obj).map(entryFilter));
}
export function normalize(v, sortArrays = false) {
    if (Array.isArray(v)) {
        const norm = v.map(i => normalize(i, sortArrays));
        return (sortArrays ? norm.sort() : norm);
    }
    else if (typeof v === "object" && v !== null) {
        return Object.fromEntries(Object.entries(v)
            .map(([k, v]) => [k, normalize(v, sortArrays)])
            .sort((a, b) => a[0].localeCompare(b[0])));
    }
    else {
        return v;
    }
}
export function append(obj, toAppend = {}) {
    return Object.assign(Object.assign({}, obj), filterEntries(toAppend, v => v !== undefined));
}
