import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { Handle, Position, useNodesInitialized, useReactFlow, useStore, } from "reactflow";
import { getNodeColor, getNodeIcon, isQuantity, NODE_SIZE, NumFormatter, theoType, } from "./gUtils";
import { Stage } from "./types";
import { classes } from "../utils/classes";
import { TheoState, useTheoState } from "./TheoState";
import { filterByKey } from "../utils/objects";
import { useTheme } from "./theme/ThemeContext";
import { getBounds, getNodeShape, NODE_BORDER_WIDTH } from "./graphGeo";
import chroma from "chroma-js";
import { X } from "../utils/drawing/geometry";
import { useOnElementResize } from "../utils/hooks";
import { EdgeInfo } from "./edgeInfo/EdgeInfo";
import { useTheoSession } from "./TheoSession";
const TheoNode = React.memo(function TheoNode(props) {
    var _a;
    const { data } = props;
    const theme = useTheme();
    const { showNodeScores } = useTheoSession();
    const { changes, frontEndData: { nodesPayload }, } = useTheoState();
    const { id, label, type, value, stage, magnitude } = data;
    const node = useStore(useCallback(store => store.nodeInternals.get(id), [id]));
    const selected = true;
    const override = changes[id];
    const forced = override !== undefined && isQuantity(data);
    const intermediate = stage === "intermediate";
    const flow = useReactFlow();
    const Icon = getNodeIcon(data, override);
    const color = chroma
        .mix(getNodeColor(data, {
        override,
        darkTheme: theme.active === "dark",
    }), theme.colors.background, selected ? 0 : 0.3)
        .css();
    const textColor = forced ? theme.colors.background : color;
    const backgroundColor = forced
        ? color
        : chroma(theme.colors.background).alpha(0.95).css();
    const ref = React.useRef(null);
    const [, , width, height] = getBounds(node);
    const [size, setSize] = React.useState({ width, height });
    const nodesInitialized = useNodesInitialized();
    useOnElementResize(ref.current, s => {
        EdgeInfo.reset();
        setSize(s);
    });
    const path = getNodeShape(node, false, size);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ ref: ref, className: "relative" }, { children: [_jsx("svg", Object.assign({ className: "absolute top-0 !m-0 left-0 w-full h-full !ml-0 pointer-events-none", viewBox: `0 0 ${size.width} ${size.height}`, style: { zIndex: -1 } }, { children: _jsx("path", { d: path, stroke: color, fill: backgroundColor, strokeWidth: NODE_BORDER_WIDTH }) })), _jsxs("div", Object.assign({ style: {
                            width: X(NODE_SIZE),
                            overflow: "hidden",
                            borderWidth: NODE_BORDER_WIDTH,
                            borderColor: "transparent",
                            color: textColor,
                        }, className: "flex items-start space-x-1 border p-2", onMouseDownCapture: e => {
                            if (e.button !== 1)
                                return;
                            e.preventDefault();
                            e.stopPropagation();
                            flow.setEdges(edges => edges.filter(e => e.source !== id && e.target !== id));
                            flow.setNodes(nodes => nodes.filter(n => n.id !== id));
                        } }, { children: [isQuantity(data) &&
                                showNodeScores &&
                                stage !== Stage.source &&
                                ((_a = nodesPayload[id]) === null || _a === void 0 ? void 0 : _a.showOperator) && (_jsx("div", Object.assign({ className: "flex items-center justify-center font-bold self-center", style: {
                                    fontSize: 14,
                                    borderRadius: 32,
                                    width: 20,
                                    height: 20,
                                    // backgroundColor: textColor,
                                    // color: backgroundColor,
                                    borderColor: textColor,
                                    borderWidth: 1,
                                } }, { children: "\u2211" }))), _jsx("div", Object.assign({ className: "flex-1 font-semibold", style: { marginLeft: !intermediate ? 16 : undefined } }, { children: label })), isQuantity(data) &&
                                !forced &&
                                data.stage !== Stage.source &&
                                magnitude !== null &&
                                showNodeScores && _jsx("div", { children: NumFormatter.format(magnitude) }), _jsx("div", Object.assign({ className: classes("flex justify-center items-center cursor-pointer", isQuantity(data) ? "rounded-xl p-1" : "text-2xl"), style: {
                                    backgroundColor: "transparent",
                                    color: textColor,
                                    marginRight: !intermediate ? 16 : undefined,
                                }, onMouseDownCapture: e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setNodeOverride(id, toggle());
                                } }, { children: _jsx(Icon, {}) }))] }))] })), _jsx(Handle, { type: "target", position: Position.Left }), _jsx(Handle, { type: "source", position: Position.Right })] }));
    function toggle() {
        if (!isQuantity(data)) {
            return override !== undefined ? undefined : !value ? 1 : 0;
        }
        const same = override === value;
        if (same)
            return undefined;
        const options = [
            ...(isQuantity(data) ? [1, -1, 0] : [0, 1]).filter(v => v !== value),
            value,
        ];
        const index = options.indexOf(override);
        return options[(index + 1) % options.length];
    }
    function setNodeOverride(id, value) {
        TheoState({
            changes: value === undefined
                ? filterByKey(changes, key => key !== id)
                : Object.assign(Object.assign({}, changes), { [id]: value }),
        });
    }
});
export const NODE_TYPES = {
    [theoType]: TheoNode,
};
