var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactFlow, { ReactFlowProvider, useNodesInitialized, useReactFlow, } from "reactflow";
import "reactflow/dist/style.css";
import dagre from "dagre";
import { showDialog } from "../utils/dialog";
import { RFGraphAnalysis } from "../utils/graph/RFGraphAnalysis";
import { Axis } from "../utils/drawing/Axis";
import { Segment } from "../utils/drawing/Segment";
import { X, xSegnent, XY, Y, ySegnent } from "../utils/drawing/geometry";
import { getNodeBounds, offsetNodes, setBounds, setPosition } from "./graphGeo";
import { Bounds } from "../utils/drawing/Bounds";
import { keyBy, mapValues } from "lodash";
import Viz from "viz.js";
import { Module, render } from "viz.js/full.render.js";
const viz = new Viz({ Module, render });
function CalcNodeSizes({ nodes: initialNodes, onLayoutNodes, nodeTypes }) {
    const flow = useReactFlow();
    const nodesInitialized = useNodesInitialized();
    const firstTime = React.useRef(true);
    React.useEffect(() => {
        if (nodesInitialized && firstTime.current) {
            firstTime.current = false;
            onLayoutNodes(flow.getNodes());
        }
    }, [nodesInitialized]);
    const size = 100;
    return (_jsx("div", Object.assign({ style: {
            width: size,
            height: size,
            position: "absolute",
            left: -size,
            top: -size,
        }, className: "flex items-stretch" }, { children: _jsx(ReactFlow, { onlyRenderVisibleElements: true, className: "flex-1", defaultNodes: initialNodes, nodeTypes: nodeTypes }) })));
}
export function computeNodeSizes(nodes, nodeTypes) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!nodes.some(n => isNaN(n.width) || isNaN(n.height)))
            return nodes;
        const withSize = (yield showDialog(onValue => (_jsx(ReactFlowProvider, { children: _jsx(CalcNodeSizes, { nodes: nodes.map((_a) => {
                    var { width, height, positionAbsolute } = _a, rest = __rest(_a, ["width", "height", "positionAbsolute"]);
                    return (Object.assign(Object.assign({}, rest), { position: { x: 0, y: 0 } }));
                }), onLayoutNodes: onValue, nodeTypes: nodeTypes }) }))));
        const sizes = Object.fromEntries(withSize.map(n => [n.id, { width: n.width, height: n.height }]));
        return nodes.map(n => (Object.assign(Object.assign({}, n), sizes[n.id])));
    });
}
export function layoutGraph({ nodes, edges }, nodeTypes, axis = Axis.X) {
    return __awaiter(this, void 0, void 0, function* () {
        nodes = yield computeNodeSizes(nodes, nodeTypes);
        if (!axis)
            return nodes;
        const graph = RFGraphAnalysis(nodes, edges).graph();
        graph.setGraph({
            rankdir: axis === Axis.X ? "LR" : "TB",
            // ranker: "longest-path",
            ranker: "network-simplex",
            // ranker: "tight-tree",
        });
        dagre.layout(graph);
        return nodes.map(node => {
            const { x, y, width, height } = graph.node(node.id);
            return setPosition(node, x - width / 2, y - height / 2);
        });
    });
}
/*
 *    axis ---->
 *    +----+  +----+  +----+
 *    | c1 |  | c2 |  | c3 |
 *    +----+  |    |  +----+
 *            +----+
 */
export function putComponentsInRow(comps, rowAxis, gap, alignment) {
    const segment = rowAxis(xSegnent, ySegnent);
    const oSegment = rowAxis.o(xSegnent, ySegnent);
    const bboxes = comps.map(nodes => getNodeBounds(...nodes));
    const offsets = Segment.align(alignment, bboxes.map(oSegment)).map(Segment.start);
    let rowOffset = 0;
    return comps
        .map((nodes, idx) => {
        const adjusted = offsetNodes(nodes, ...rowAxis.xy(rowOffset, offsets[idx]));
        rowOffset += gap + Segment.len(segment(bboxes[idx]));
        return adjusted;
    })
        .flat();
}
const FACT = 72;
export function vizLlayout({ nodes, edges }, nodeTypes, axis = Axis.X) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!(nodes === null || nodes === void 0 ? void 0 : nodes.length))
            return [];
        const s = yield computeNodeSizes(nodes.map(n => (Object.assign(Object.assign({}, n), { width: undefined, height: undefined }))), nodeTypes);
        const { nodeXYs, edgeXYs } = yield dotLayout({
            nodes: s,
            edges,
        }, axis);
        return nodes.map(n => setBounds(n, ...Bounds.fromPoints(nodeXYs[n.id])));
        function dotLayout({ nodes, edges }, axis) {
            return __awaiter(this, void 0, void 0, function* () {
                const nodeSizes = mapValues(keyBy(nodes, "id"), n => XY(n.width / FACT, n.height / FACT));
                const nodesIdx = Object.fromEntries(nodes.map((n, idx) => [n.id, idx]));
                const dot = createDot({ nodes, edges }, nodesIdx, nodeSizes, axis);
                // console.log(
                //   dot
                //     .split("\n")
                //     // .map((l, idx) => `${idx + 1}\t${l}`)
                //     .join("\n")
                // )
                const resp = yield viz.renderString(dot, { format: "json" });
                const json = JSON.parse(resp);
                const lay = {
                    nodeXYs: {},
                    edgeXYs: {},
                };
                (json.objects || []).map((i) => (lay.nodeXYs[i.id] = i._draw_.at(-1).points));
                (json.edges || []).map((i) => (lay.edgeXYs[i.id] = i._draw_.at(-1).points));
                return lay;
            });
        }
        function createDot({ nodes, edges }, nodesIdx, nodeSizes, axis) {
            function getNodeId(id) {
                return `"N${nodesIdx[id]}"`;
            }
            function getNode(id) {
                return nodes[nodesIdx[id]];
            }
            return `graph {
        rankdir="${axis("LR", "BT")}"
        node [ shape=box]
        ${nodes
                .map(node => {
                const { id } = node;
                const atts = Atts()
                    .set("id", id)
                    .set("label", "")
                    .set("shape", "rectangle")
                    .set("width", X(nodeSizes[id]))
                    .set("height", Y(nodeSizes[id]));
                return `${getNodeId(id)} [ ${atts.toString()} ]`;
            })
                .join("\n")}
        ${edges
                .map(edge => {
                const { id, type, source, target } = edge;
                const atts = Atts()
                    .set("id", id)
                    .set("label", " ")
                    .set("labelOverlay", true, false);
                return `${getNodeId(source)} -- ${getNodeId(target)} [${atts.toString()} ]`;
            })
                .join("\n")}
        }`;
            function Atts(atts = {}) {
                const inst = {
                    atts,
                    set(key, value, quote = true) {
                        atts[key] = typeof value === "string" && quote ? `"${value}"` : value;
                        return inst;
                    },
                    toString() {
                        return Object.entries(atts)
                            .map(([k, v]) => `${k}=${v}`)
                            .join(" ");
                    },
                };
                return inst;
            }
        }
    });
}
