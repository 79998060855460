import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { TitledPanel } from "./TitledPanel";
import { Scroller } from "../shared/components/Scroller";
import { useTheoState } from "./TheoState";
import { Empty } from "../shared/components/Empty";
import { Tabs, Tree } from "antd";
import { DownOutlined } from "@ant-design/icons/lib";
export function OntologiesPanel() {
    var _a;
    const { ontologies } = useTheoState();
    const [active, setActive] = React.useState(0);
    const activeOntology = ontologies[active];
    return (_jsx(TitledPanel, Object.assign({ title: "ontologies", className: "w-full h-full pt-2" }, { children: _jsx(Empty, Object.assign({ empty: !ontologies.length && "no ontologies", showIcon: false }, { children: _jsxs("div", Object.assign({ className: "flex-1 flex flex-col items-stretch" }, { children: [_jsx(Tabs, { activeKey: active + "", onChange: key => setActive(parseInt(key)), items: ontologies.map((o, idx) => ({
                            key: idx + "",
                            label: o.name,
                        })) }), _jsx(Empty, Object.assign({ empty: !(activeOntology === null || activeOntology === void 0 ? void 0 : activeOntology.children.length) && `no ${activeOntology === null || activeOntology === void 0 ? void 0 : activeOntology.name}`, showIcon: false }, { children: _jsx(Scroller, Object.assign({ className: "flex-1" }, { children: _jsx(Tree, { className: "flex-1", showLine: true, switcherIcon: _jsx(DownOutlined, {}), multiple: true, treeData: (((_a = ontologies[active]) === null || _a === void 0 ? void 0 : _a.children) || []).map(nodeToTreeData) }) })) }))] })) })) })));
}
function nodeToTreeData(node) {
    return {
        key: node.name,
        title: node.name,
        children: node.children.map(nodeToTreeData),
    };
}
