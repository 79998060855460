import { Position } from "reactflow";
import { X, XY, Y } from "../../utils/drawing/geometry";
import { Bounds } from "../../utils/drawing/Bounds";
import { getBounds } from "../graphGeo";
import { PathWalker } from "../../utils/drawing/PathWalker";
import { getEdgePath } from "../theoGraphLayout";
import { parseSvgPath } from "../../utils/drawing/bezier";
import { LAYOUT_CONFIG } from "../layoutConfig";
export function smartClosestEdgeInfo(nodes) {
    const midPoints = Object.fromEntries(nodes.map(n => {
        const b = getBounds(n);
        return [
            n.id,
            {
                [Position.Top]: XY(Bounds.x(b) + Bounds.w(b) / 2, Bounds.y(b)),
                [Position.Bottom]: XY(Bounds.x(b) + Bounds.w(b) / 2, Bounds.yMax(b)),
                [Position.Left]: XY(Bounds.x(b), Bounds.y(b) + Bounds.h(b) / 2),
                [Position.Right]: XY(Bounds.xMax(b), Bounds.y(b) + Bounds.h(b) / 2),
                center: Bounds.center(b),
            },
        ];
    }));
    return function selectCandidates(source, target) {
        const sx = X(midPoints[source.id].center);
        const sy = Y(midPoints[source.id].center);
        const tx = X(midPoints[target.id].center);
        const ty = Y(midPoints[target.id].center);
        const sourcesX = sx < tx
            ? [Position.Right]
            : sx > tx
                ? [Position.Left]
                : [Position.Left, Position.Right];
        const targetsX = sx < tx
            ? [Position.Left]
            : sx > tx
                ? [Position.Right]
                : [Position.Left, Position.Right];
        const sourcesY = sy < ty
            ? [Position.Bottom]
            : sy > ty
                ? [Position.Top]
                : [Position.Top, Position.Bottom];
        const targetsY = sy < ty
            ? [Position.Top]
            : sy > ty
                ? [Position.Bottom]
                : [Position.Top, Position.Bottom];
        const sources = [...sourcesX, ...sourcesY];
        const targets = [...targetsX, ...targetsY];
        return sources
            .map(sPos => targets.map(tPos => {
            const s = midPoints[source.id];
            const t = midPoints[target.id];
            const params = {
                sourcePosition: sPos,
                targetPosition: tPos,
                sourceX: X(s[sPos]),
                sourceY: Y(s[sPos]),
                targetX: X(t[tPos]),
                targetY: Y(t[tPos]),
            };
            const path = getEdgePath(params, nodes);
            return Object.assign(Object.assign({}, params), { path, cost: LAYOUT_CONFIG.smartEdgeCost(PathWalker(path).length, parseSvgPath(path).length) });
        }))
            .flat()
            .sort((a, b) => a.cost - b.cost)[0];
    };
}
