var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useMemoIfEqual } from "../hooks";
export function createPathElement(getPath) {
    return (_a) => {
        var { params } = _a, props = __rest(_a, ["params"]);
        const d = React.useMemo(() => getPath(params), [useMemoIfEqual(params)]);
        return _jsx("path", Object.assign({ d: d }, props));
    };
}
