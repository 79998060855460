var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Axis } from "../utils/drawing/Axis";
import { RFGraphAnalysis } from "../utils/graph/RFGraphAnalysis";
import { mapSync, mapValuesSync } from "../utils/util";
import { putComponentsInRow } from "./graphLayout";
import { Y } from "../utils/drawing/geometry";
import { NODE_SIZE } from "./gUtils";
import React from "react";
import { EdgeInfo } from "./edgeInfo/EdgeInfo";
import { getSmartEdge } from "@tisoap/react-flow-smart-edge";
import { getSpline } from "./graphGeo";
import { LAYOUT_CONFIG } from "./layoutConfig";
export function useTheoGraphLayout() {
    React.useEffect(() => {
        EdgeInfo.setCalculator(LAYOUT_CONFIG.edgePosition);
    }, [LAYOUT_CONFIG.edgePosition]);
    return theoGraphLayout;
}
export function theoGraphLayout({ nodes = [], edges = [] }, nodeTypes, axis = Axis.X) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        let StageType;
        (function (StageType) {
            StageType[StageType["source"] = 0] = "source";
            StageType[StageType["target"] = 1] = "target";
            StageType[StageType["sourceAndTarget"] = 2] = "sourceAndTarget";
            StageType[StageType["neither"] = 3] = "neither";
        })(StageType || (StageType = {}));
        const compsByStageType = classifyComponentsByStage();
        const sourceAndTarget = ((_a = compsByStageType[StageType.source]) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
            ((_b = compsByStageType[StageType.target]) === null || _b === void 0 ? void 0 : _b.length) > 0 &&
            !((_c = compsByStageType[StageType.sourceAndTarget]) === null || _c === void 0 ? void 0 : _c.length);
        return sourceAndTarget
            ? yield layoutSourceAndTarget()
            : yield LAYOUT_CONFIG.nodeLayout({ nodes, edges }, nodeTypes, axis);
        // return await layoutGraph({nodes, edges}, nodeTypes, axis)
        function classifyComponentsByStage() {
            const ga = RFGraphAnalysis(nodes, edges);
            const comps = ga.components();
            return comps.reduce((acc, comp) => {
                const type = calcStageType(comp);
                acc[type] = acc[type] || [];
                acc[type].push(comp.map(ga.node));
                return acc;
            }, {});
            function calcStageType(comp) {
                const histo = comp.reduce((acc, id) => {
                    const stage = ga.node(id).data.stage;
                    acc[stage] = (acc[stage] || 0) + 1;
                    return acc;
                }, {});
                return histo.source && !histo.target
                    ? StageType.source
                    : !histo.source && histo.target
                        ? StageType.target
                        : histo.source && histo.target
                            ? StageType.sourceAndTarget
                            : StageType.neither;
            }
        }
        function layoutSourceAndTarget() {
            return __awaiter(this, void 0, void 0, function* () {
                const layoutCompsByType = yield mapValuesSync(compsByStageType, (comps) => __awaiter(this, void 0, void 0, function* () {
                    return yield mapSync(comps, (comp) => __awaiter(this, void 0, void 0, function* () {
                        return yield LAYOUT_CONFIG.nodeLayout(RFGraphAnalysis(comp, edges), nodeTypes, axis);
                    }));
                }));
                const gap = Y(NODE_SIZE);
                return putComponentsInRow([
                    putComponentsInRow([
                        putComponentsInRow(layoutCompsByType[StageType.source], axis.o, gap, 0),
                        putComponentsInRow(layoutCompsByType[StageType.target], axis.o, gap, 1),
                    ], axis, gap, 0.5),
                    putComponentsInRow(layoutCompsByType[StageType.neither] || [], axis.o, gap, 0.5),
                ], axis.o, gap, 0.5);
            });
        }
    });
}
export function getEdgePath(params, nodes) {
    var _a;
    const smart = LAYOUT_CONFIG.smartEdges
        ? (_a = getSmartEdge(Object.assign(Object.assign({ options: {
                nodePadding: LAYOUT_CONFIG.nodePadding,
                gridRatio: LAYOUT_CONFIG.gridRatio,
                drawEdge: LAYOUT_CONFIG.edgeDrawer,
                generatePath: LAYOUT_CONFIG.pathGenerator,
            } }, params), { nodes }))) === null || _a === void 0 ? void 0 : _a.svgPathString
        : undefined;
    return smart || getSpline(params);
}
