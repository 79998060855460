import { pathfindingAStarDiagonal, pathfindingAStarNoDiagonal, pathfindingJumpPointNoDiagonal, svgDrawSmoothLinePath, svgDrawStraightLinePath, } from "@tisoap/react-flow-smart-edge";
import { layoutGraph, vizLlayout } from "./graphLayout";
import equidistantEdgesInfoCalculatorCreator from "./edgeInfo/equidistantEdgesInfo";
import { dupEdgesInfoCalculator } from "./edgeInfo/dupEdgesInfo";
import { smartClosestEdgeInfo } from "./edgeInfo/smartClosestEdgeInfo";
const PathGenerator = {
    DIAG: pathfindingAStarDiagonal,
    NO_DIAG: pathfindingAStarNoDiagonal,
    ORTH: pathfindingJumpPointNoDiagonal,
};
const EdgeDrawer = {
    STRAIGHT: svgDrawStraightLinePath,
    SMOOTH: svgDrawSmoothLinePath,
};
const NodeLayout = {
    VIZ: vizLlayout,
    DAGRE: layoutGraph,
};
const EdgePositionCalculator = {
    EQUI: equidistantEdgesInfoCalculatorCreator(smartClosestEdgeInfo),
    // EQUI: equidistantEdgesInfoCalculatorCreator(() => getClosestEdgeParams),
    DUP: dupEdgesInfoCalculator,
};
export const LAYOUT_CONFIG = {
    pathGenerator: PathGenerator.DIAG,
    edgeDrawer: EdgeDrawer.SMOOTH,
    nodeLayout: NodeLayout.DAGRE,
    edgePosition: EdgePositionCalculator.EQUI,
    nodePadding: 5,
    gridRatio: 10,
    smartEdges: true,
    smartEdgeCost: (length, complexity) => {
        return length + complexity * 10;
    },
    fadeOutDurationMs: 1500,
    fadeInDurationMs: 1500,
    moveDurationMs: 1500,
};
