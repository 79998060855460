import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SettingOutlined } from "@ant-design/icons";
import { ChevronLeft, ChevronNeutral, ChevronRight, } from "../shared/components/Icons";
import React, { useState } from "react";
import { useTheoConfig } from "./TheoConfig";
import { updateTheoSession, useTheoSession } from "./TheoSession";
export function TheoToolbar() {
    const [dialog, showConfig] = useTheoConfig();
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-center justify-between px-2" }, { children: [_jsx("div", {}), _jsx(ExpandButton, {}), _jsx(ToolbarButton, Object.assign({ onClick: e => showConfig(e.altKey) }, { children: _jsx(SettingOutlined, {}) }))] })), dialog] }));
}
function ToolbarButton(props) {
    return (_jsx("button", Object.assign({ style: { fontSize: 32 }, className: "text-gray-400 hover:text-gray-500" }, props)));
}
function ExpandButton(props) {
    const [neutral, setNeutral] = useState(true);
    const { showLeftPanel } = useTheoSession();
    return (_jsx(ToolbarButton, Object.assign({}, props, { onMouseOut: () => setNeutral(true), onMouseEnter: () => setNeutral(false), onClick: () => {
            updateTheoSession({ showLeftPanel: !showLeftPanel });
        } }, { children: !showLeftPanel ? (_jsx(ChevronRight, {})) : neutral ? (_jsx(ChevronNeutral, {})) : (_jsx(ChevronLeft, {})) })));
}
