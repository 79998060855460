import React from "react";
import { hideMenuBarPopup } from "../shared/actions/ActionsBar";
import { showPopupMenu } from "../shared/actions/PopupMenu";
import { getAncestor } from "../utils/dom";
export function useMouseDownHandlers(disabled, ...handlers) {
    return React.useCallback((e) => {
        if (disabled)
            return;
        handlers.find(handler => handler(e));
    }, [disabled, handlers]);
}
export function ignoreMouseHandling(e) {
    if (getAncestor(e.target, "react-flow__panel"))
        return true;
    return false;
}
export function useHidePopups() {
    return React.useCallback(e => {
        showPopupMenu(false);
        // Menu bar popup was shown, event was handled
        return hideMenuBarPopup(e);
    }, []);
}
