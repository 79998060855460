import { zeroTo360 } from "./geometry";
import { Polar } from "./Polar";
export function Pen(tip = [0, 0], ang = 0) {
    const home = tip;
    let down = false;
    const points = [];
    const pen = {
        up() {
            down = false;
            return pen;
        },
        down() {
            points.push(tip);
            down = true;
            return pen;
        },
        tip() {
            return tip;
        },
        xy(x, y) {
            tip = [x, y];
            down && points.push(tip);
            return pen;
        },
        ang(a) {
            if (a === undefined) {
                return a;
            }
            ang = zeroTo360(a);
            return pen;
        },
        rot(delta) {
            ang = zeroTo360(ang + delta);
            return pen;
        },
        adv(len) {
            return pen.xy(...Polar.toXY(len, ang, ...tip));
        },
        points() {
            return points;
        },
        clear() {
            points.length = 0;
            return pen;
        },
        pen() {
            return Pen(tip, ang);
        },
        pointTo(...p) {
            return pen.ang(Polar.ang(Polar.from(...p, ...tip)));
        },
        branch() {
            return Pen(tip, ang);
        },
        rec() {
            points.push(tip);
            return pen;
        },
        home() {
            return pen.xy(...home);
        },
    };
    return pen;
}
