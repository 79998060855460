var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { HTTP } from "../../utils/http";
import { createWriteStream } from "streamsaver";
import { noSqueeze } from "./readable";
import { pickFromObject } from "../../utils/collections";
import { getCoraServerUrl } from "../../utils/authInfo";
import { AppCapability, CorpusCapability, getCapabilityConfig, } from "../../utils/capabilities/capabilities";
function createApi() {
    const http = HTTP(getCapabilityConfig);
    function fetch(capability, params = {}, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return http.fetchData(getCoraServerUrl(), capability, params, config);
        });
    }
    return {
        analyzeQuery(params, config) {
            return __awaiter(this, void 0, void 0, function* () {
                const result = yield fetch(CorpusCapability.ANALYZE_QUERY, params, config);
                return result;
            });
        },
        lookupConcepts(params, config) {
            return __awaiter(this, void 0, void 0, function* () {
                const result = yield fetch(CorpusCapability.LOOKUP_CONCEPTS, params, config);
                return result;
            });
        },
        lookupKbIds(params, config) {
            return __awaiter(this, void 0, void 0, function* () {
                const result = yield fetch(CorpusCapability.LOOKUP_KB_IDS, params, config);
                return result;
            });
        },
        corpora() {
            return __awaiter(this, void 0, void 0, function* () {
                const result = yield http
                    .get(getCoraServerUrl(), CorpusCapability.CORPORA)
                    .then(resp => resp.json());
                return result.corpora;
            });
        },
        corpusInfo(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const result = yield fetch(CorpusCapability.CORPUS_INFO, params);
                return result;
            });
        },
        getDomainModel(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const result = yield fetch(CorpusCapability.GET_DOMAIN_MODEL, params);
                return result.domain_model;
            });
        },
        conjunctionBindings(params, config) {
            return __awaiter(this, void 0, void 0, function* () {
                const result = yield fetch(CorpusCapability.CONJUNCTION_BINDINGS, params, config);
                return result.bindings;
            });
        },
        searchArguments(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const result = yield fetch(CorpusCapability.SEARCH_ARGUMENTS, params);
                return result.argument_names;
            });
        },
        suggest_evidence(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const result = (yield fetch(CorpusCapability.SUGGEST_EVIDENCE, params)).result;
                return result;
            });
        },
        search_glossary(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const data = yield fetch(CorpusCapability.SEARCH_GLOSSARY, params);
                return data.results;
            });
        },
        searchConcepts(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SEARCH_CONCEPTS, params);
                return response.concepts;
            });
        },
        suggestFindings(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SUGGEST_FINDINGS, params);
                return response.suggestions;
            });
        },
        relations(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SEARCH_RELATIONS, params);
                return response.relations;
            });
        },
        searchEvidence(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SEARCH_EVIDENCE, params);
                const corpus_id = params.corpus_filter.corpus_ids[0];
                const evidence = response.evidence.map((_a) => {
                    var { instances } = _a, rest = __rest(_a, ["instances"]);
                    return (Object.assign(Object.assign({}, rest), { instances: instances.map(i => (Object.assign(Object.assign({}, i), { corpus_id }))) }));
                });
                return Object.assign(Object.assign({}, response), { evidence });
            });
        },
        searchRefutingRelations(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SEARCH_RELATIONS_REFUTING, params);
                return response.query_relations;
            });
        },
        searchRefutingConcepts(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SEARCH_CONCEPTS_REFUTING, params);
                return response.refuting_concept_clusters;
            });
        },
        suggestedQuestionsEvidence(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SUGGEST_QUERY_NEXT_EVIDENCE, params);
                return response.suggested_questions;
            });
        },
        suggestQuestionsForConcepts(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SUGGEST_QUERY_NEXT_CONCEPTS, params);
                return response.suggested_questions;
            });
        },
        createConcept(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.CREATE_CONCEPT, params);
                return response;
            });
        },
        searchConceptsMembers(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SEARCH_CONCEPTS_MEMBERS, params);
                return response.concepts;
            });
        },
        searchArgumentClauses(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SEARCH_ARGUMENTS_CLAUSES, params);
                return response.argument_clauses;
            });
        },
        corporaDocument(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.CORPORA_DOCUMENT, params);
                const squeezed = yield noSqueeze(response.document.text, "");
                return Object.assign(Object.assign({}, squeezed), pickFromObject(response.document, "id", "metadata", "external_url", "url", "corpus_id"));
            });
        },
        getUrlData(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.GET_URL_DATA, params);
                return response.data;
            });
        },
        getUrlId(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.GET_URL_ID, params);
                return response.id;
            });
        },
        concept_expansion(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = !params.cluster
                    ? []
                    : yield fetch(CorpusCapability.SUGGEST_CONCEPTS, params);
                return response.clusters;
            });
        },
        relation_expansion(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = !params.cluster
                    ? []
                    : yield fetch(CorpusCapability.SUGGEST_RELATIONS, params);
                return response.clusters;
            });
        },
        suggestRelatedQueries(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SUGGEST_QUERIES_RELATED, params);
                return response.queries;
            });
        },
        searchMetadata(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SEARCH_METADATA, params);
                return response;
            });
        },
        ////////////////////////////////////
        getSessionsDirectory() {
            return __awaiter(this, void 0, void 0, function* () {
                return yield http
                    .get(getCoraServerUrl(), AppCapability.USER_DATA, undefined, {
                    capabilityExtension: "?summary_fields=name",
                })
                    .then(resp => {
                    if (resp.ok) {
                        return resp.json().then(response => {
                            const summaries = response.summaries_by_name;
                            const keys = Object.keys(summaries);
                            return keys.map(key => {
                                return {
                                    id: key,
                                    name: summaries[key].name || "untitled",
                                };
                            });
                        });
                    }
                    return [];
                });
            });
        },
        loadSession(sessionId) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield http
                    .get(getCoraServerUrl(), AppCapability.USER_DATA, undefined, {
                    capabilityExtension: `/${sessionId}`,
                })
                    .then(resp => {
                    return resp.json().then(json => json.data);
                });
            });
        },
        upsertSession(session) {
            return __awaiter(this, void 0, void 0, function* () {
                const sessionId = session.id;
                return yield fetch(AppCapability.USER_DATA, {
                    data: session,
                }, {
                    capabilityExtension: `/${sessionId}`,
                });
            });
        },
        deleteSession(sessionId) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield http
                    .delete(getCoraServerUrl(), AppCapability.USER_DATA, `/${sessionId}`)
                    .then(resp => resp.json());
            });
        },
        trends(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return (yield fetch(CorpusCapability.TRENDS, params));
            });
        },
        exportTrends(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield http
                    .post(getCoraServerUrl(), CorpusCapability.EXPORT_TRENDS, {
                    body: JSON.stringify(params),
                }, {
                    ignoreErrorHandler: true,
                })
                    .then((resp) => __awaiter(this, void 0, void 0, function* () {
                    if (resp.status !== 200) {
                        console.error("error downloading trends: ", resp);
                        const errorMessage = yield resp.json();
                        return new Error(errorMessage.detail);
                    }
                    return resp.text();
                }))
                    .catch((e) => __awaiter(this, void 0, void 0, function* () {
                    console.error("error downloading trends: ", e);
                    if (e.body) {
                        const errorMessage = yield e.json();
                        return errorMessage.detail ? new Error(errorMessage.detail) : e;
                    }
                    return e;
                }));
            });
        },
        exportEvidence(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield http
                    .post(getCoraServerUrl(), CorpusCapability.EXPORT_EVIDENCE, {
                    body: JSON.stringify(params.exportParams),
                }, {
                    ignoreErrorHandler: true,
                })
                    .then((response) => __awaiter(this, void 0, void 0, function* () {
                    var _a, _b, _c;
                    const writeStream = createWriteStream(`${params.filename}.csv`);
                    const writer = writeStream.getWriter();
                    if (response.status !== 200) {
                        const errorMessage = yield response.json();
                        writer.releaseLock();
                        return new Error(errorMessage.detail);
                    }
                    else if ((_a = response.body) === null || _a === void 0 ? void 0 : _a.pipeTo) {
                        writer.releaseLock();
                        return (_b = response.body) === null || _b === void 0 ? void 0 : _b.pipeTo(writeStream);
                    }
                    const reader = (_c = response.body) === null || _c === void 0 ? void 0 : _c.getReader();
                    new ReadableStream({
                        start(controller) {
                            function pump() {
                                return reader === null || reader === void 0 ? void 0 : reader.read().then(({ done, value }) => {
                                    if (done) {
                                        writer.close();
                                        controller.close();
                                        return;
                                    }
                                    writer.write(value).then(pump);
                                });
                            }
                            return pump();
                        },
                        cancel(reason) {
                            console.warn("read stream canceled: ", reason);
                            return reason;
                        },
                    });
                }))
                    .catch((e) => __awaiter(this, void 0, void 0, function* () {
                    console.error("error downloading evidence: ", e);
                    if (e.body) {
                        const errorMessage = yield e.json();
                        return errorMessage.detail ? new Error(errorMessage.detail) : e;
                    }
                    return e;
                }));
            });
        },
        supported_granularities(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield fetch(CorpusCapability.TRENDS_GRANULARITIES, params).then(resp => resp.granularities);
            });
        },
        supported_group_by_fields(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield fetch(CorpusCapability.TRENDS_GROUP_BY_FIELDS, params).then(resp => resp.supported_group_by_fields);
            });
        },
        send_feedback(params) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield fetch(CorpusCapability.FEEDBACK, params, {
                    noCache: true,
                }).then(resp => resp.feedback_ids);
            });
        },
        suggest_answer_with_summaries(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const response = yield fetch(CorpusCapability.SUGGEST_ANSWER_WITH_SUMMARIES, params);
                return response;
            });
        },
    };
}
export const API = createApi();
