import { atanDeg, dist, TO_RAD } from "./geometry";
export function Polar(r = 0, ang = 0, orgX = 0, orgY = 0) {
    return [r, ang, orgX, orgY];
}
Polar.r = (polar) => polar[0];
Polar.setR = (polar, r) => {
    const [_, ang, x, y] = polar;
    return Polar(r, ang, x, y);
};
Polar.ang = (polar) => polar[1];
Polar.setAng = (polar, ang) => {
    const [r, _, x, y] = polar;
    return Polar(r, ang, x, y);
};
Polar.toXY = (r, ang, orgX = 0, orgY = 0) => {
    const rads = ang * TO_RAD;
    return [orgX + r * Math.cos(rads), orgY + r * Math.sin(rads)];
};
Polar.from = (x, y, orgX = 0, orgY = 0) => {
    return Polar(dist(x - orgX, y - orgY, 0, 0), atanDeg(y - orgY, x - orgX));
};
Polar.org = (polar) => [polar[2], polar[3]];
Polar.setOrg = (polar, x = 0, y = 0) => {
    const [r, ang] = polar;
    return Polar(r, ang, x, y);
};
Polar.trans = (polar, x = 0, y = 0) => {
    const [orgX, orgY] = Polar.org(polar);
    return Polar.setOrg(polar, orgX + x, orgY + y);
};
Polar.mul = (polar, f = 1) => Polar.setR(polar, Polar.r(polar) * f);
Polar.isPolar = (v) => {
    return Array.isArray(v) && v.length === 4 && typeof v[0] === "number";
};
