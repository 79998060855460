import { hasXY } from "./geometry";
export const X = ((v, o) => hasXY(v) ? v.x : Array.isArray(v) ? v[0] : v);
X.xy = (v, o) => [v, o];
X.obj = (v, o) => ({ x: v, y: o });
export const Y = ((v, o) => hasXY(v) ? v.y : Array.isArray(v) ? v[1] : o);
Y.xy = (v, o) => [o, v];
Y.obj = (v, o) => ({ x: o, y: v });
X.o = Y;
Y.o = X;
export const Axis = { X, Y };
