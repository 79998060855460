import React from "react";
import { usePopup } from "./Popup";
import { Beacon, useBeacon } from "../../utils/Beacon";
const beacon = Beacon({ initial: false });
export function useGlobalPopup() {
    const [Popup, showPopup] = usePopup();
    const config = useBeacon(beacon)[0];
    React.useEffect(() => {
        if (config) {
            const { left, top, content, autoHide, hideOnWheel, onVisibilityChanged } = config;
            showPopup({
                left,
                top,
                autoHide,
                content,
                hideOnWheel,
                onVisibilityChanged,
            });
        }
        else {
            showPopup(false);
        }
    }, [config]);
    return Popup;
}
export function showPopUp(config) {
    beacon.value(config);
}
export function useShowingPopup() {
    return !!useBeacon(beacon)[0];
}
