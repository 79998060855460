var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useReactFlow, useStore, useUpdateNodeInternals } from "reactflow";
import { ControlButton } from "./ControlButton";
import { NODE_TYPES } from "./TheoNode";
import { AlignH, AlignV } from "../shared/components/Icons";
import React from "react";
import { graphAnimator } from "./graphAnimator";
import { identity } from "../utils/util";
import { Axis } from "../utils/drawing/Axis";
import { theoGraphLayout } from "./theoGraphLayout";
import { updateGlobalSettings } from "./GlobalSettings";
export function LayoutGraphButton({ horizontal, state, dUpdater, }) {
    const flow = useReactFlow();
    const { minZoom, maxZoom, width, height } = useStore(identity);
    const updateNodeInternals = useUpdateNodeInternals();
    return (_jsx(ControlButton, Object.assign({ action: () => __awaiter(this, void 0, void 0, function* () {
            updateGlobalSettings({
                layoutAxis: horizontal ? "H" : "V",
            });
            const nodes = yield theoGraphLayout({
                nodes: flow.getNodes(),
                edges: flow.getEdges(),
            }, NODE_TYPES, horizontal ? Axis.X : Axis.Y);
            graphAnimator(state, dUpdater, {
                nodes,
                edges: state.edges,
            }, {
                panTo: { flow, minZoom, maxZoom, width, height },
                moveDurationMs: 1000,
            }, () => updateNodeInternals(flow.getNodes().map(n => n.id)));
        }) }, { children: horizontal ? _jsx(AlignH, {}) : _jsx(AlignV, {}) })));
}
