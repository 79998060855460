import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import TextArea from "antd/es/input/TextArea";
import { Input } from "antd";
import { GenerateGraphButton } from "./GenerateGraphButton";
import { EMPTY_STATE, TheoState, useTheoState } from "./TheoState";
import { SessionPicker } from "./SessionPicker";
import { CtrlButton } from "./CtrlButton";
import { FastBackwardOutlined, StepBackwardOutlined } from "@ant-design/icons";
import { Eject } from "../shared/components/Icons";
export function TheoPrompt() {
    const { scenario, target, checkpoint } = useTheoState();
    return (_jsxs("div", Object.assign({ className: "w-full h-full pb-4 flex-1 flex flex-col items-stretch space-y-2" }, { children: [_jsx(SessionPicker, {}), _jsx("div", Object.assign({ className: "text-xl font-bold" }, { children: "scenario" })), _jsx(TextArea, { placeholder: "enter scenario...", className: "flex-1", value: scenario, onChange: e => TheoState({ scenario: e.target.value }), autoSize: true }), _jsx("div", Object.assign({ className: "text-xl font-bold" }, { children: "impact on" })), _jsx("div", Object.assign({ className: "flex items-center space-x-4" }, { children: _jsx(Input, { className: "bg-transparent", placeholder: "target...", value: target, onChange: e => TheoState({ target: e.target.value }) }) })), _jsxs("div", Object.assign({ className: "flex flex-row items-center space-x-2 pt-2" }, { children: [_jsx("div", { className: "flex-1" }), _jsx(CtrlButton, Object.assign({ disabled: !checkpoint && !scenario && !target, onClick: () => TheoState(EMPTY_STATE), tooltip: "New session" }, { children: _jsx(Eject, {}) })), _jsx(CtrlButton, Object.assign({ disabled: !checkpoint, onClick: () => TheoState(Object.assign(Object.assign({}, TheoState(EMPTY_STATE)), { scenario, target })), tooltip: "Back to start" }, { children: _jsx(FastBackwardOutlined, {}) })), _jsx(CtrlButton, Object.assign({ disabled: !checkpoint, onClick: () => history.go(-1), tooltip: "One step backwards" }, { children: _jsx(StepBackwardOutlined, {}) })), _jsx(GenerateGraphButton, { next_step_only: true }), _jsx(GenerateGraphButton, { next_step_only: false }), _jsx("div", { className: "flex-1" })] }))] })));
}
